<mat-paginator [length]="person.reviews?.length"
               [pageSize]="tablePageSize"
               [pageSizeOptions]="[5]"
               (page)="pageEvent($event)">
</mat-paginator>
<table>
  <thead>
    <tr>
      <th>
        <span>Notes</span>
        <button mat-icon-button class="sm-icon-btn"
                color="primary" [disabled]="!person.person_id"
                (click)="reviewDialog('edit', person)">
          <mat-icon>add_circle</mat-icon>
        </button>
      </th>
      <th><span>Prepared By</span></th>
      <th><span>Sent</span></th>
      <th>
        <mat-icon title="Note Actions" class="sm-icon-btn">assignment</mat-icon>
      </th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let review of person.reviews | slice:tableRecordStart:tableRecordEnd">
      <td scope="row" style="cursor: pointer;"
          (click)="reviewDialog('view', person, review)">
        <span>{{review.title}}</span>
      </td>
      <td><span>{{review.prepared_by}}</span></td>
      <td><span>{{review.date_sent | date: 'shortDate'}}</span></td>
      <td>
        <button mat-icon-button class="sm-icon-btn"
                [matMenuTriggerFor]="noteActionMenu">
          <mat-icon>more_vert</mat-icon>
        </button>
      </td>
      <mat-menu class="compact-menu" #noteActionMenu="matMenu">
        <button mat-menu-item (click)="promptNoteDelete(review)">
          <mat-icon color="warn">delete</mat-icon>
          <span color="warn">Delete Note</span>
        </button>
        <button mat-menu-item (click)="reviewDialog('edit', person, review)">
          <mat-icon>edit</mat-icon>
          <span>Edit Note</span>
        </button>
      </mat-menu>
    </tr>
  </tbody>
</table>