import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { BehaviorSubject } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';

import { ListService } from '@modules/list-management/list.service';
import { DialogService } from '@modules/dialogs/dialog.service';
import { PeopleService } from '@modules/person-profiles/people.service';

@Component({
  selector: 'app-person-view',
  templateUrl: './person-view.component.html',
  styleUrls: ['./person-view.component.scss']
})
export class PersonViewComponent implements OnInit {
  @Input('person') person$: BehaviorSubject<any> = new BehaviorSubject<any>({});
  @Output('person-updated') personUpdated: EventEmitter<any> = new EventEmitter<any>();
  
  addressForm: FormGroup;
  emailForm: FormGroup;
  phoneForm: FormGroup;
  listForm: FormGroup;
  
  // DROPDOWN SETS ***************************************************************
  phoneLabels: string[] = ["Home", "Mobile", "Work", "Fax"];
  emailLabels: string[] = ["Home", "Work", "Other"];
  addressLabels: string[] = ["Home", "Work", "Other"];
  
  // LIST VARIABLES ***************************************************************
  selectedListMaster: any = {};
  listSearchText: string = "";
  listDropdown: BehaviorSubject<any[]> = new BehaviorSubject([]);
  
  constructor(private listSvc: ListService,
              private dialogSvc: DialogService,
              protected peopleSvc: PeopleService,
              private fb: FormBuilder) {}
  
  ngOnInit() {
    this.phoneForm = this.fb.group({
      phone_id: [],
      phone_number: [''],
      label: [''],
      primary_phone: [false],
      contact_link: [],
      _slingupdate: [false]
    });
    this.emailForm = this.fb.group({
      email_address_id: [],
      email: [''],
      label: [''],
      primary_email: [false],
      contact_link: [],
      _slingupdate: [false]
    });
    this.addressForm = this.fb.group({
      label: [''],
      primary_address: [false],
      address_id: [],
      address: [''],
      address2: [''],
      city: [''],
      state: [''],
      zip:[''],
      contact_link: [],
      _slingupdate: [false]
    });
    this.listForm = this.fb.group({
      list: ['']
    });
    this.listSvc.lists.subscribe(lsts => this.listDropdown.next(lsts));
    this.person$.subscribe((person: any) => {
      console.log("PERSON VIEW PERSON: ", person);
    });
  }
  
  getPersonName = (person: any) => {
    let result = "";
    
    if (!!person.first_name || !!person.last_name) {
      if (person.first_name) result = person.first_name;
      if (person.last_name) result += " " + person.last_name;
    }
    return result.trim();
  }
  
  // LIST FUNCTIONS *****************************************************************************
  createList = (list: any) => {
    this.listSvc.createList(list).subscribe((lists: any[]) => {
      this.listSvc.lists.next(lists);
      this.listForm.reset();
    })
  }
  deleteList = (list: any, person: any) => {
    let title: string = "Confirm List Delete Action",
        msg: string = `Are you sure you want to permanently delete the list titled '${list.list}' and all the list connections to people?`;
    
    this.dialogSvc.confirmDialog(title, msg).pipe(
      filter((confirm: boolean) => confirm),
      switchMap(() => this.listSvc.deleteList(list.list_id))
    ).subscribe((lists: any[]) => {
      this.listSvc.lists.next(lists);
      person.lists = person.lists.filter((lst: any) => lst.list_id ! === list.list_id);
      this.person$.next(person);
    });
  }
  
  addPersonToSelectedList = (ev: any, person: any) => {
    this.listSearchText = "";
    if (person.person_id) {
      if (ev.option.value.list_id) {
        this.listSvc.addPersonToList(person.person_id, ev.option.value.list_id).subscribe((response: any) => {
          person.lists.items.push(response.newList);
          this.personUpdated.emit(person);
    /*      this.peopleSvc.personListUpdate$.next({
            action: "add",
            person: person,
            list: ev.option.value
          }); */
        });
      } else if (ev.option.value.list_id === 0) {
        let newList = ev.option.value;
        delete newList.list_id;
        this.listSvc.createListAndAddPerson(ev.option.value, person.person_id).subscribe((response: any) => {
          person.lists.items.push(response.newList);
          this.listSvc.lists.next(response.lists);
          this.personUpdated.emit(person);
        });
      }
    }
  }
  removePersonFromList = (personListJoinId: number, list: any, person: any) => {
    let title: string = "Confirm List Removal",
        msg: string = `Are you sure you want to remove ${this.getPersonName(person)} from the ${list.list} list?`;
    
    this.dialogSvc.confirmDialog(title, msg).pipe(
      filter((confirm: boolean) => confirm),
      switchMap(() => this.listSvc.removePersonFromList(personListJoinId))
    ).subscribe((x: any) => {
      person.lists.items = person.lists.items.filter((listLink: any) => listLink.person_list_join_id !== list.person_list_join_id);
  /*    this.peopleSvc.personListUpdate$.next({
        action: "remove",
        person: person,
        list: list
      }); */
      this.personUpdated.emit(person);
    });
  }
  addPersonToList = (list: any, person: any) => {
    if (person.person_id && list.list_id) {
      this.listSvc.addPersonToList(person.person_id, list.list_id).subscribe((newList: any) => {
        person.lists.push(newList);
        this.person$.next(person);
      });
    }
  }
  filterLists = () => {
    this.listDropdown.next(this.listSvc.lists.getValue().filter(lst => {
      return (this.listSearchText.length?lst.list.toLowerCase().indexOf(this.listSearchText.toLowerCase()) > -1:true);
    }));
  }
  displayNull = () => {
    return "";
  }
  
  addPhoneNumber = (phoneNumber: any, person: any) => {
    if (!phoneNumber.contact_link) phoneNumber.contact_link = person.person_id;
    if (phoneNumber.phone_id) {
      this.peopleSvc.updatePhoneNumber(phoneNumber).subscribe((response: any[]) => {
        person.phones = response;
        this.personUpdated.emit(person);
      });
    } else {
      this.peopleSvc.addPhoneNumber(phoneNumber).subscribe((response: any[]) => {
        person.phones = response;
        this.personUpdated.emit(person);
        this.phoneForm.reset({contact_link: person.person_id});
      });
    }
  }
}