import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BehaviorSubject, of } from 'rxjs';
import { map, debounceTime, startWith, switchMap, filter, tap } from 'rxjs/operators';

import { ConfigService } from '@modules/utility-components/config.service';

export interface PhoneNumber {
  phone_id: number;
  phone_number: string;
  label: string;
  primary_phone: number;
  contact_link: number;
  date_created: string;
  user_created: string;
}
export interface Address {
  address_id: number;
  address: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
  contact_link: number;
  date_created: string;
  user_created: string;
}
export interface PeopleFilter {
  lists: any[];
  states: string[];
  pageIndex: number;
  pageSize: number;
  orderBy: string;
}

@Injectable({
  providedIn: 'root'
})
export class PeopleService {
  url: string;

  constructor(private http: HttpClient,
              private configSvc: ConfigService
              ) {
    this.configSvc.settings.pipe(
      filter((settings) => settings.apiUrl)
    ).subscribe((settings: any) => this.url = settings.apiUrl);
  }

  getPersonName = (person: any) => {
    return person.first_name + " " + person.last_name;
  }

  search = (term: string) => {
    return this.http.get(this.url + "people/search", {params: {term: term}});
  }
  searchAll = (searchRequest: string) => {
    return this.http.get<any[]>(this.url + "search/" + encodeURIComponent(searchRequest));
  }
  searchNameAndNotes = (searchRequest: string) => {
    return this.http.get<any[]>(this.url + "search-notes/" + encodeURIComponent(searchRequest));
  }

  removeEmptyFieldRecords = (personObj) => {
    if (typeof personObj.email_addresses !== "undefined") {
      console.log("working on this one");
      personObj.email_addresses.forEach((em, idx) => {if (!em.email && !em.email_address_id) personObj.email_addresses.splice(idx, 1)});
    }
    if (typeof personObj.phones !== "undefined") {
      personObj.phones.forEach((ph, idx) => {if (!ph.phone_number && !ph.phone_id) personObj.phones.splice(idx, 1)});
    }
    return personObj
  }

  // PERSON FUNCTIONS ******************************************************************************
  getPerson = (id) => {
    return this.http.get(this.url + "people/" + id);
  }
  getAllActivists = () => {
    return this.http.get(this.url + "activists");
    // res: {result: activists[]}
  }
  getPeople = (filter: PeopleFilter) => {
    return this.http.post(this.url + "people/filter", filter);
  }
  getPersonSimpleRecord = (personId) => {
    return this.http.get(this.url + "person-simple-record/" + personId)
  }
  createPerson = (person: any) => {
    return this.http.post(this.url + "people", this.removeEmptyFieldRecords(person));
  }
  updatePerson = (person: any) => {
    return this.http.put(this.url + "people", this.removeEmptyFieldRecords(person));
  }
  toggleVerificationStatus = (id, verificationObj) => {
    return this.http.put(this.url + "verification/person/" + id, {verificationObj: verificationObj});
  }
  deletePerson = (personObj) => {
    return this.http.delete(this.url + "person/" + personObj.person_id);
  }
  saveHtmlNotes = (htmlNotes: string, id: number) => {
    return this.http.put<any>(this.url + "html-notes/" + id, {htmlNotes: htmlNotes});
  }
  
  // RELATIONSHIP FUNCTIONS ***********************************************************************
  createRelationship = (id1: number, id2: number) => {
    return this.http.post<any>(this.url + "people/relationship", {person1_link: id1, person2_link: id2});
  }
  deleteRelationship = (id: number) => {
    return this.http.delete(this.url + "people/relationship/" + id);
  }
  
  // ADDRESS FUNCTIONS ****************************************************************************
  addAddress = (newAddressObj) => {
    // newAddressObj = {address_id: integer (PK), address: string, address2: string, city: string, state: string, zip: string, contact_id: integer}
    return this.http.post(this.url + "address", {newAddressObj: newAddressObj});
  }
  updateAddress = (addressId: number, addressObj: any) => {
    return this.http.put(this.url + "address/" + addressId, {addressObj: addressObj})
  }
  deleteAddress = (addressId: number, personId: number) => {
    return this.http.delete(this.url + `address/${addressId}/${personId}`);
  }
  getFullAddress = (person: any) => {
    return person.address + (person.address2?", " + person.address2:"") + (person.city?", " + person.city:"") + (person.state?", " + person.state:"") + (person.zip?" " + person.zip:"")
  }
  
  // PHONE NUMBER FUNCTIONS ***********************************************************************
  addPhoneNumber = (phoneNumber: PhoneNumber) => {
    // newPhoneObj = {phone_number: string, label: string, contact_link: integer}
    return this.http.post(this.url + "phone-numbers", phoneNumber);
  }
  updatePhoneNumber = (phoneNumber: PhoneNumber) => {
    return this.http.put<PhoneNumber[]>(this.url + 'phone-numbers', phoneNumber)
  }
  deletePhoneNumber = (id: number, personId: number) => {
    return this.http.delete(this.url + `phone-numbers`, {params: {id: id, personId: personId}});
  }
  
  // EMAIL FUNCTIONS ******************************************************************************
  addEmailAddress = (newEmailObj) => {
    // newEmailObj = {email: string (PK), label: string, contact_link: integer}
    return this.http.post(this.url + "email-address", {newEmailObj: newEmailObj});
  }
  deleteEmailAddress = (emailAddressId, personId) => {
    return this.http.delete(this.url + `email-address/${emailAddressId}/${personId}`);
  }
  unsubscribe = (personId: number) => {
    return this.http.get(this.url + "unsubscribe/" + personId);
  }

  // ACTION FUNCTIONS *****************************************************************************
  createAction = (newActionObj: any) => {
    return this.http.post(this.url + "action", {newActionObj: newActionObj});
  }
  updateAction = (updateActionObj: any) => {
    return this.http.put(this.url + `action/${updateActionObj.action_id}`, {updateActionObj: updateActionObj});
  }
  updateActionStatus = (updateActionObj: any) => {
    return this.http.put(this.url + `action-status/${updateActionObj.action_id}`, {updateActionObj: updateActionObj});
  }
  
  // NOTE FUNCTIONS *******************************************************************************
  createNote = (noteObj) => {
    return this.http.post(this.url + "review", {noteObj: noteObj});
  }
  deleteNote = (noteId, personId) => {
    return this.http.delete(this.url + `review/${noteId}/${personId}`);
  }
  updateNote = (noteObj) => {
    return this.http.put(this.url + "review", {noteObj: noteObj});
  }
  
  // CONTACT LOG FUNCTIONS ************************************************************************
  createContactLog = (contactLog: any, recipients: number[]) => {
    return this.http.post(this.url + "contact-log", {contactLog: contactLog, recipients: recipients});
  }
  deleteContactLogEntry = (id: number, filter: any) => {
    return this.http.post(this.url + "contact-logs/delete", filter, {params: {id: id}});
  }
  updateContactLogEntry = (contactLogEntryObj) => {
    return this.http.put(this.url + "contact-log", {contactLogEntryObj: contactLogEntryObj});
  }
  
  // SEARCH FUNCTION ******************************************************************************
  searchControl = (control: any) => {
    return control.valueChanges.pipe(
      startWith(''),
      debounceTime(300),
      switchMap(value => {
        if (value && typeof value === "string") {
          return this.searchAll(value.trim());
        } else {
          return of({result: []});
        }
      }));
  } 
}