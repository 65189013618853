<div fxFlexFill fxLayout="column" *ngIf="person$ | async as person" class="overflow-auto">
  <h2 class="text-center" style="margin-bottom: 0;">{{peopleSvc.getPersonName(person)}}</h2>
  <div fxFlex class="contact-info-frame" fxLayout="column" fxLayoutGap="6px">
    <div *ngIf="person.preferred_name" fxLayout="row">
      <div class="buffer">
        <mat-icon class="contact-icon" title="Preferred Name">badge</mat-icon>
      </div>
      <div fxFlex>
        <span>{{person.preferred_name}}</span>
      </div>
    </div>
    <div fxLayout="row">
      <div class="buffer">
        <button class="md-icon-btn" mat-icon-button (click)="showNewEmail = !showNewEmail;">
          <mat-icon class="contact-icon" title="Emails">email</mat-icon>
          <mat-icon class="contact-add-icon">add_circle</mat-icon>
        </button>
      </div>
      <div fxFlex class="overflow-auto">
        <div *ngFor="let eaddress of person?.email_addresses">
          <a [href]="'mailto:' + eaddress.email"><span>{{eaddress.email}}</span></a>
          <span *ngIf="eaddress.label || eaddress.primary_email">&nbsp;&nbsp;(</span>
          <span *ngIf="eaddress.label">{{eaddress.label}}</span>
          <span *ngIf="eaddress.label && eaddress.primary_email"> - </span>
          <span *ngIf="eaddress.primary_email">Primary</span>
          <span *ngIf="eaddress.label || eaddress.primary_email">)</span>
        </div>
        <form *ngIf="showNewEmail" [formGroup]="emailForm" fxLayout="row" fxLayoutGap="5px">
          <mat-form-field>
            <mat-label>Email Address</mat-label>
            <input matInput type="email" formControlName="email" name="email">
          </mat-form-field>
          <mat-form-field style="width: 50px;">
            <mat-label>Label</mat-label>
            <input matInput [matAutocomplete]="emailLabel" type="text"
                   formControlName="label" name="label">
            <mat-autocomplete #emailLabel>
              <mat-option *ngFor="let label of emailLabels" [value]="label">
                <span>{{label}}</span>
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
          <mat-checkbox formControlName="primary_email" color="primary">Primary</mat-checkbox>
          <button mat-icon-button color="warn" title="Cancel new entry" class="icon-btn-16"
                  (click)="emailForm.reset(); showNewEmail = false;">
            <mat-icon>cancel</mat-icon>
          </button>
          <button mat-icon-button color="primary" title="Save new entry"
                  [disabled]="emailForm.pristine" class="icon-btn-16"
                  (click)="addEmailAddress(emailForm.value)">
            <mat-icon>save</mat-icon>
          </button>
        </form>
      </div>
    </div>
    <div fxLayout="row">
      <div class="buffer">
        <button class="md-icon-btn" mat-icon-button
                (click)="showNewPhone = !showNewPhone">
          <mat-icon class="contact-icon" title="Phones">phone</mat-icon>
          <mat-icon class="contact-add-icon">add_circle</mat-icon>
        </button>
      </div>
      <div>
        <div *ngFor="let phone of person.phones">
          <a [href]="'tel:' + phone.phone_number">{{phone.phone_number | mask: '(000) 000-0000'}}</a>
          <span *ngIf="phone.label || phone.primary_phone">&nbsp;&nbsp;(</span>
          <span *ngIf="phone.label">{{phone.label}}</span>
          <span *ngIf="phone.label && phone.primary_phone"> - </span>
          <span *ngIf="phone.primary_phone">Primary</span>
          <span *ngIf="phone.label || phone.primary_phone">)</span>
        </div>
        <div fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="start center"
               *ngIf="showNewPhone" [formGroup]="phoneForm">
          <mat-form-field fxFlex>
            <mat-label>Phone Number</mat-label>
            <input matInput mask="(000) 000-0000" type="text" formControlName="phone_number">
          </mat-form-field>
          <mat-form-field style="width: 90px;">
            <mat-label>Label</mat-label>
            <input matInput [matAutocomplete]="phoneLabel" type="text" formControlName="label">
            <mat-autocomplete #phoneLabel="matAutocomplete">
              <mat-option *ngFor="let label of phoneLabels" [value]="label">
                <span>{{label}}</span>
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
          <mat-checkbox formControlName="primary_phone" color="primary">
            <span>Primary</span>
          </mat-checkbox>
          <button mat-icon-button color="warn" title="Cancel new entry"
                  class="md-icon-btn" (click)="phoneForm.reset(); showNewPhone = false;">
            <mat-icon>cancel</mat-icon>
          </button>
          <button title="Save new entry" class="md-icon-btn" [disabled]="phoneForm.pristine"
                  mat-icon-button color="primary" (click)="addPhoneNumber(phoneForm.value, person)">
            <mat-icon>save</mat-icon>
          </button>
        </div>
      </div>
    </div>
    <div fxLayout="row">
      <div class="buffer">
        <button class="md-icon-btn" mat-icon-button
                (click)="showNewAddress = !showNewAddress;">
          <mat-icon class="contact-icon" title="Addresses">place</mat-icon>
          <mat-icon class="contact-add-icon">add_circle</mat-icon>
        </button>
      </div>
      <div>
        <div *ngFor="let address of person.addresses">
          <div *ngIf="address.label" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
            <span>
              <span class="header">{{address.label}}</span>
              <span class="header" *ngIf="address.primary_address">&nbsp;(Primary)</span>
            </span>
            <a target="_blank" class="map-icon"
               [href]="'https://www.google.com/maps/place/' + peopleSvc.getFullAddress(address).replace(' ', '+')">
              <mat-icon>map</mat-icon>
            </a>
          </div>
          <div *ngIf="address.address">{{address.address}}</div>
          <div *ngIf="address.address">{{address.address2}}</div>
          <div *ngIf="address.city || address.state || address.zip">
            <span>{{address.city}}</span>
            <span>{{(address.city && address.state)?", ":""}}</span>
            <span>{{address.state}}</span>
            <span>{{((address.city + address.state).length?" ":"") + address.zip}}</span>
          </div>
          <form class="row" *ngIf="showNewAddress" [formGroup]="addressForm">
            <div>
              <mat-form-field>
                <mat-label>Label</mat-label>
                <input matInput [matAutocomplete]="addressLabel"
                       type="text" formControlName="label">
                <mat-autocomplete #addressLabel>
                  <mat-option *ngFor="let label of addressLabels" [value]="label">
                    <span>{{label}}</span>
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
              <button mat-icon-button color="warn" title="Cancel new entry"
                      style="width: 20px; height: 20px;" 
                      (click)="addressForm.reset(); showNewAddress = false;">
                <mat-icon style="font-size: 16px;">cancel</mat-icon></button>
              <button mat-icon-button color="primary" [disabled]="addressForm.pristine"
                      title="Save new entry" style="width: 20px; height: 20px;"
                      (click)="addAddress(addressForm.value)">
                <mat-icon style="font-size: 16px;">save</mat-icon>
              </button>
            </div>
            <div>
              <mat-form-field appearance="legacy" class="w-100">
                <mat-label>Address</mat-label>
                <input matInput type="text" formControlName="address" name="address">
              </mat-form-field>
            </div>
            <div>
              <mat-form-field style="width: 100%;">
                <mat-label>Address 2</mat-label>
                <input matInput type="text" formControlName="address2" name="address2">
              </mat-form-field>
            </div>
            <div fxLayout="row" fxLayoutGap="5px">
              <mat-form-field>
                <mat-label>City</mat-label>
                <input matInput type="text" formControlName="city" name="city">
              </mat-form-field>
              <mat-form-field style="width: 40px;">
                <mat-label>State</mat-label>
                <input matInput type="text" formControlName="state" name="state">
              </mat-form-field>
              <mat-form-field style="width: 60px;">
                <mat-label>Zip</mat-label>
                <input matInput type="text" formControlName="zip" name="zip">
              </mat-form-field>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div fxLayout="row">
      <div class="buffer">
        <button class="md-icon-btn" mat-icon-button
                (click)="showNewListForm = !showNewListForm">
          <mat-icon class="contact-icon" title="Lists">label</mat-icon>
          <mat-icon class="contact-add-icon">add_circle</mat-icon>
        </button>
      </div>
      <div fxFlex>
        <div *ngIf="showNewListForm" fxLayout="row" fxLayoutAlign="start center">
          <mat-form-field fxFlex>
            <mat-label>Select list to add {{peopleSvc.getPersonName(person)}}</mat-label>
            <input matInput type="text" [matAutocomplete]="autoList"
                   [(ngModel)]="listSearchText" name="list" #listField="ngModel"
                   (ngModelChange)="filterLists()">
            <button matSuffix mat-icon-button><mat-icon>close</mat-icon></button>
          </mat-form-field>
          <button mat-icon-button color="warn" title="Close New List"
                  (click)="showNewListForm = false">
            <mat-icon (click)="listSearchText = ''">cancel</mat-icon>
          </button>
          <mat-autocomplete #autoList [displayWith]="displayNull"
                            (optionSelected)="addPersonToSelectedList($event, person)">
            <mat-option *ngIf="listSearchText" [value]="{list_id: 0, list: listSearchText}">
              <span>Create New List '{{listSearchText}}' and add {{peopleSvc.getPersonName(person)}}</span>
            </mat-option>
            <mat-option *ngFor="let list of listDropdown | async" [value]="list">
              <span>{{list.list}}</span>
            </mat-option>
          </mat-autocomplete>
        </div>
        <p class="pointer" *ngIf="!person.lists?.length && !showNewListForm"
           style="color: gray; margin-top: 10px;" (click)="showNewListForm = true;">
          Click here to add {{peopleSvc.getPersonName(person)}} to a list.</p>
        <mat-chip-listbox>
          <mat-chip *ngFor="let list of person.lists.items" [removable]="true" 
                    (removed)="removePersonFromList(list.person_list_join_id, list, person)">
            <span class="ellipsis">{{list.list}}</span>
            <button matChipRemove><mat-icon>cancel</mat-icon></button>
          </mat-chip>
        </mat-chip-listbox>
      </div>
    </div>
    <div fxLayout="row">
      <div class="buffer">
        <button class="md-icon-btn" mat-icon-button>
          <mat-icon class="contact-icon" title="Notes">assignment</mat-icon>
          <mat-icon class="contact-add-icon">add_circle</mat-icon>
        </button>
      </div>
      <div class="overflow-auto">
        <p>{{person.notes}}</p>
      </div>
    </div>
  </div>
</div>