import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

import { FilesService } from '../files.service';
import { Person } from '@modules/person-profiles/models/person.model';

import { DuplicateListComponent } from '../duplicate-list/duplicate-list.component';
import { ImportDuplicateDisplayComponent } from '../import-duplicate-display/import-duplicate-display.component';

@Component({
  selector: 'app-deduplication-desktop',
  templateUrl: './deduplication-desktop.component.html',
  styleUrls: ['./deduplication-desktop.component.scss']
})
export class DeduplicationDesktopComponent implements OnInit {
  @ViewChild('importDuplicateDisplay') importDuplicateDisplay: ImportDuplicateDisplayComponent;
  @ViewChild('duplicateList') duplicateList: DuplicateListComponent;
  @Input('selected-file') selectedFile: any;
  @Input('selected-sheet') selectedSheet: any;
  @Input('temp-table-data') tempTableData$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  @Input('import-sequence') importSequence$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  @Input('duplicate-rows') duplicateRows$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  @Output('update-temp-table-row') updateTempTableRow: EventEmitter<any> = new EventEmitter<any>();
  selectedDuplicateRow$: BehaviorSubject<any> = new BehaviorSubject<any>({_duplicates: {firstLast: [], last: []}});
  firstLastDuplicates$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  lastDuplicates$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  selectedPerson$: BehaviorSubject<Person> = new BehaviorSubject<Person>(new Person());

  constructor(private fileSvc: FilesService) { }

  ngOnInit(): void {
    
  }
  updateDuplicateRow = (updatedRow: any) => {
    this.selectedDuplicateRow$.next(updatedRow);
    this.updateTempTableRow.emit(updatedRow);
  }
  scrollEvent = (ev: any) => {
    let index: number, duplicateRowsLength: number, duplicateRows: any[];

    this.duplicateRows$.subscribe(dupRows => {
      duplicateRows = dupRows;
      duplicateRowsLength = duplicateRows.length;
    });
    if (typeof this.duplicateList.selectedDuplicateIndex === "undefined") {
      index = 0;
    } else if (this.duplicateList.selectedDuplicateIndex === 0 && ev.wheelDelta > 0) {
      index = duplicateRowsLength - 1;
    } else if (this.duplicateList.selectedDuplicateIndex === duplicateRowsLength - 1 && ev.wheelDelta < 0) {
      index = 0;
    } else {
      index = this.duplicateList.selectedDuplicateIndex + (ev.wheelDelta>0?-1:1);
    }
    this.selectedDuplicateRow$.next(duplicateRows[index]);
    this.duplicateList.selectedDuplicateIndex = index;
  }
}