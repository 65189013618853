import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';

import { mergeMap } from 'rxjs/operators';

import { ListService } from '@modules/list-management/list.service';
import { UtilityService } from '@modules/utility-components/utility.service';
import { PeopleService } from '@modules/person-profiles/people.service';
import { DialogService } from '@modules/dialogs/dialog.service';

@Component({
  selector: 'app-list-people-control',
  templateUrl: './list-people-control.component.html',
  styleUrls: ['./list-people-control.component.scss']
})
export class ListPeopleControlComponent implements OnInit {
  selectedList: any;
  selectedPerson: any;
  people: any[] = [];
  peopleSearchResults: any[] = [];
  newPersonForm: FormGroup;

  constructor(public listSvc: ListService,
              public utilitySvc: UtilityService,
              public peopleSvc: PeopleService,
              private fb: FormBuilder,
              private dialogSvc: DialogService
              ) { }

  ngOnInit(): void {
    this.newPersonForm = this.fb.group({
      peopleSearch: ['']
    });
    this.peopleSvc.searchControl(this.newPersonForm.controls["peopleSearch"]).subscribe(resp => this.peopleSearchResults = resp.result[0]);
  }
  
  getListPeople = (ev) => {
    this.listSvc.getListPeople(ev.value.list_id).subscribe(ppl => {
      this.people = ppl[0];
    });
  }
  selectPerson = (ev) => {
    if (this.selectedList.list_id) {
      this.listSvc.addPersonToList(ev.option.value.person_id, this.selectedList.list_id).subscribe(x => {
        this.newPersonForm.reset();
        this.people.push(ev.option.value);
      });
    }
  }
  removePersonFromList = (personList) => {
    if (this.selectedList.list_id) {
      this.dialogSvc.confirmDialog("Confirm Removal from List", `Are you sure you want to remove ${this.utilitySvc.getPersonName(personList)} from the list '${this.selectedList.list}'?`)
      .pipe(mergeMap((confirm: boolean) => {if (confirm) return this.listSvc.removePersonFromList(personList.person_list_join_id)}))
      .subscribe((httpResponse: any) => this.people = this.people.filter(pers => pers.person_list_join_id !== personList.person_list_join_id));
    }
  }

}