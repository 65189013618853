import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { filter } from 'rxjs/operators';

import { ConfigService } from '@modules/utility-components/config.service';

@Injectable({
  providedIn: 'root'
})
export class SignupService {
  url: string;

  constructor(private http: HttpClient,
              private configSvc: ConfigService) {
    this.configSvc.settings.pipe(
      filter((settings) => settings.apiUrl)
    ).subscribe((settings: any) => this.url = settings.apiUrl);
  }
  
  // SIGN UP FUNCTIONS *****************************************************************************
  submitNewSignup = (personObj) => {
    return this.http.post(this.url + "new-signup", personObj);
  }
  
}
