<div id="total">
  <span class="bold">Total {{itemName}}: </span>
  <span>{{totalRecords | number: '1.0'}}</span>
</div>
<div id="size">
  <span class="bold">{{itemName}} Per Page: </span>
  <span [matMenuTriggerFor]="pageSizeMenu" class="pointer">{{pageSize}}</span>
  <mat-menu #pageSizeMenu="matMenu">
    <button mat-menu-item *ngFor="let pageSizeOption of pageSizeOptions"
            (click)="changePageSize.emit(pageSizeOption)">
      <span>{{pageSizeOption}}</span>
    </button>
  </mat-menu>
</div>
<div id="items">
  <span>Displaying Item</span>
  <span *ngIf="(pageIndex * pageSize + 1) !== totalRecords">s</span>
  <span> {{pageIndex * pageSize + 1}}</span>
  <span *ngIf="(pageIndex * pageSize + 1) !== totalRecords"> - {{(pageIndex + 1)===totalPages?totalRecords:(pageIndex + 1) * pageSize}}</span>
</div>
<div id="buttons">
  <button mat-icon-button (click)="pageIncrement.emit(-1)" class="sm-icon-btn"
          [disabled]="pageIndex === 0">
    <mat-icon>chevron_left</mat-icon>
  </button>
  <div>
    <span class="small-hide">Page&nbsp;</span>
    <span class="pointer" [matMenuTriggerFor]="pageMenu">{{pageIndex + 1}}&nbsp;</span>
    <span>of {{totalPages}}</span>
  </div>
  <button mat-icon-button (click)="pageIncrement.emit(1)" class="sm-icon-btn"
          [disabled]="pageIndex >= (totalPages - 1)">
    <mat-icon>chevron_right</mat-icon>
  </button>
  <mat-menu #pageMenu="matMenu">
    <button mat-menu-item *ngFor="let page of pages(totalPages)">
      <span>{{page}}</span>
    </button>
  </mat-menu>
</div>