import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-versatile-table',
  templateUrl: './versatile-table.component.html',
  styleUrls: ['./versatile-table.component.scss']
})
export class VersatileTableComponent implements OnInit {
  @Input('columns') columns: string[] = [];
  @Input('tableData') tableData: any[] = [];
  @Input('paginator-location') pagLoc: string = 'top';
  pageSize: number = 25;
  pageSizeOptions: number[] = [5, 10, 25, 50, 100];

  constructor() { }

  ngOnInit(): void {
    
  }

}