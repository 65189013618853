import { NgModule, APP_INITIALIZER } from '@angular/core';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { Observable } from 'rxjs';

import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSidenavModule } from '@angular/material/sidenav';

import { ClipboardModule } from '@angular/cdk/clipboard';
import { DragDropModule } from '@angular/cdk/drag-drop';

import { NgxMaskModule } from 'ngx-mask';
import { AngularEditorModule } from '@kolkov/angular-editor';

import { AppRoutingModule } from './app-routing.module';
import { ActionManagerModule } from '@modules/action-manager/action-manager.module';
import { ActivistNotesModule } from '@modules/activist-notes/activist-notes.module';
import { AuthModule } from '@modules/auth/auth.module';
import { ChromeModule } from '@modules/chrome/chrome.module';
import { ContactLogsModule } from '@modules/contact-logs/contact-logs.module';
import { CorrespondenceManagerModule } from '@modules/correspondence-manager/correspondence-manager.module';
import { DialogsModule } from '@modules/dialogs/dialogs.module';
import { DonorManagementModule } from '@modules/donor-management/donor-management.module';
import { EmailEditorModule } from '@modules/email-editor/email-editor.module';
import { FileImporterModule } from '@modules/file-importer/file-importer.module';
import { ListManagementModule } from '@modules/list-management/list-management.module';
import { PersonProfilesModule } from '@modules/person-profiles/person-profiles.module';

import { AppComponent } from './app.component';
import { AuthCookieInterceptor } from '@interceptors/auth-cookie.interceptor';
import { UnauthorizedInterceptor } from '@interceptors/unauthorized.interceptor';

import { PipesModule } from '@pipes/pipes.module';
import { RemoveHostDirective } from './directives/remove-host.directive';
import { ConfigService } from '@modules/utility-components/config.service';

export function initializeApp(configSvc: ConfigService): () => Observable<any> {
  return configSvc.load();
}

@NgModule({
  declarations: [
    AppComponent,
    RemoveHostDirective,
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    MatIconModule,
    MatButtonModule,
    MatSnackBarModule,
    MatSidenavModule,
    ClipboardModule,
    NgxMaskModule.forRoot(),
    AngularEditorModule,
    DragDropModule,
    AppRoutingModule,
    ActionManagerModule,
    ActivistNotesModule,
    AuthModule,
    ChromeModule,
    ContactLogsModule,
    CorrespondenceManagerModule,
    DialogsModule,
    DonorManagementModule,
    EmailEditorModule,
    FileImporterModule,
    ListManagementModule,
    PersonProfilesModule,
    PipesModule
  ],
  providers: [
    {provide: APP_INITIALIZER, useFactory: initializeApp, deps: [ConfigService], multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: AuthCookieInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: UnauthorizedInterceptor, multi: true},
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
