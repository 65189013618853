import { Component, OnInit, Input } from '@angular/core';

import { BehaviorSubject } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

import { PeopleService } from '@modules/person-profiles/people.service';
import { DialogService } from '@modules/dialogs/dialog.service';
import { Person } from '@modules/person-profiles/models/person.model';

@Component({
  selector: 'app-individual-notes-table',
  templateUrl: './individual-notes-table.component.html',
  styleUrls: ['./individual-notes-table.component.scss']
})
export class IndividualNotesTableComponent implements OnInit {
  @Input('person') person: any = new Person();
  tablePageSize: number = 5;
  tablePageIndex: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  tableRecordStart: number = 0;
  tableRecordEnd: number;

  constructor(private dialogSvc: DialogService,
              private peopleSvc: PeopleService) { }

  ngOnInit(): void {
    this.tablePageIndex.subscribe(page => {
      this.tableRecordStart = page * this.tablePageSize;
      this.tableRecordEnd = this.tableRecordStart + this.tablePageSize;
    });
  }
  
  reviewDialog = (viewMode, person?, review?) => {
    this.dialogSvc.reviewDialog(viewMode, person, review).subscribe(x => {
      if (typeof x.review_id !== "undefined") {
        let index = this.person.reviews.findIndex(rvw => rvw.review_id === x.review_id);
        if (index > -1) {
          this.person.reviews[index] = x;
        } else {
          this.person.reviews.unshift(x);
        }
      }
    });
  }
  promptNoteDelete = (note) => {
    this.dialogSvc.confirmDialog("Confirm Note Delete", `Are you sure you want to delete the note titled '${note.title}'?`)
    .pipe(mergeMap((resp) => {if (resp) return this.peopleSvc.deleteNote(note.review_id, this.person.person_id)}))
    .subscribe((pers: any) => this.person.reviews.splice(this.person.reviews.findIndex(rvw => rvw.review_id === note.review_id), 1));
  }
  pageEvent = (event) => {
    this.tablePageIndex.next(event.pageIndex);
  }
}