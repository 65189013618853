import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { ConfigService } from '@modules/utility-components/config.service';

import { BehaviorSubject } from 'rxjs';
import { filter, tap, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ListService {
  url: string;
  lists: BehaviorSubject<any[]> = new BehaviorSubject([]);

  constructor(private http: HttpClient,
              private configSvc: ConfigService) {
    this.configSvc.settings.pipe(
      filter((settings) => settings.apiUrl),
      tap((settings) => this.url = settings.apiUrl),
      switchMap(() => this.getAllLists())
    ).subscribe((lsts: any[]) => this.lists.next(lsts[0]));
  }
  
  getAllLists = () => {
    return this.http.get(this.url + "lists");
  }
  createList = (listObj) => {
    return this.http.post(this.url + "list", {listObj: listObj});
  }
  deleteList = (listId) => {
    return this.http.delete(this.url + "list/" + listId);
  }
  
  getListPeople = (listId) => {
    return this.http.get(this.url + "list-people/" + listId);
  }
  addPersonToList = (personId, listId) => {
    return this.http.post(this.url + "add-person-to-list", {personId: personId, listId: listId});
  }
  removePersonFromList = (personListJoinId) => {
    return this.http.delete(this.url + "person-list/" + personListJoinId);
  }
  createListAndAddPerson = (listObj, personId) => {
    return this.http.post(this.url + "create-list-add-person", {listObj: listObj, personId: personId});
  }
  
  downloadCallList = () => {
    return this.http.get(this.url + "export-call-list", {responseType: 'blob'});
  }
  downloadEmailList = () => {
    return this.http.get(this.url + "export-email-list", {responseType: 'blob'});
  }
  downloadFilterList = (filter: any) => {
    return this.http.post(this.url + "export-filter-list", filter, {responseType: 'blob'});
  }
  downloadDonorList = (params?: any) => {
    return this.http.get(this.url + "export-donor-list", {responseType: 'blob', params: params});
  }
}