import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';

import { AuthService } from '@modules/auth/auth.service';

@Component({
  selector: 'app-user-profile-dialog',
  templateUrl: './user-profile-dialog.component.html',
  styleUrls: ['./user-profile-dialog.component.scss']
})
export class UserProfileDialogComponent implements OnInit {

  constructor(public authSvc: AuthService,
              public router: Router,
              public dialogRef: MatDialogRef<UserProfileDialogComponent>,
              private _snackBar: MatSnackBar
              ) { }

  ngOnInit(): void {
    
  }
  
  logout = () => {
    this.authSvc.logout();
    this.router.navigate(['login']);
    this.dialogRef.close();
    this._snackBar.open(`You have successfully logged out!`, 'X', {horizontalPosition: 'center', verticalPosition: 'top', duration: 2000})
  }
  navToLogin = () => {
    this.router.navigate(['login']);
    this.dialogRef.close();
  }
  navToUserProfile = () => {
    this.router.navigate(['/user-profile']);
    this.dialogRef.close();
  }

}