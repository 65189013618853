import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthService } from '@modules/auth/auth.service';

@Injectable()
export class UnauthorizedInterceptor implements HttpInterceptor {

  constructor(private router: Router,
              private authSvc: AuthService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        let errorMsg = '';
        if (error.error instanceof ErrorEvent) {
          console.log('this is client side error');
          errorMsg = `Error: ${error.error.message}`;
        } else {
          console.log('this is server side error:', error);
          errorMsg = `Error Code: ${error.status},  Message: ${error.message}`;
          if (error.status === 401) {
            console.log("Unauthorized User");
            this.authSvc.error.next({
              type: error.error.type,
              msg: error.error.msg
            })
            this.router.navigate(['/login'], {state: {destination: "/users"}});
          }
        }
        console.log(errorMsg);
        return throwError(errorMsg);
      })
    );
  }
}