<h1 mat-dialog-title>Contact Log Entry</h1>
<div mat-dialog-content>
  <contact-log-form *ngIf="viewFormat === 'edit' || viewFormat === 'create'"></contact-log-form>
  <div *ngIf="viewFormat === 'view'" class="row">
    <div>
      <h3><b>Title: </b>{{contactLogEntry.title}}</h3>
      <p><b>When: </b>{{contactLogEntry.contact_datetime | date: 'short'}}</p>
      <p><b>Contact Type: </b>{{contactLogEntry.contact_type}}</p>
      <p><b>Contacted By: </b>{{contactLogEntry.contacted_by}}</p>
      <p><b>Status: </b>{{contactLogEntry.status}}</p>
      <p><b>Date Completed: </b>{{contactLogEntry.date_completed}}</p>
      <p><b>Notes: </b>{{contactLogEntry.contact_notes}}</p>
    </div>
    <div align="end">
      <button mat-flat-button color="primary" [mat-dialog-close]="contactLogEntry.contact_log_id">
        <span>Edit</span>
      </button>
      <button mat-flat-button mat-dialog-close color="warn">
        <span>Close</span>
      </button>
    </div>
  </div>
</div>