import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';

import { BehaviorSubject, of } from 'rxjs';
import { filter, startWith, debounceTime, switchMap } from 'rxjs/operators';
import * as moment from 'moment';

import { PeopleService } from '@modules/person-profiles/people.service';
import { ContactLogsService } from '../contact-logs.service';
import { AuthService } from '@modules/auth/auth.service';

@Component({
  selector: 'app-contact-log-form',
  templateUrl: './contact-log-form.component.html',
  styleUrls: ['./contact-log-form.component.scss']
})
export class ContactLogFormComponent implements OnInit {
  @Input('person') person$: BehaviorSubject<any> = new BehaviorSubject<any>({});
  @Input('filter') filter$: BehaviorSubject<any> = new BehaviorSubject<any>({});
  @Output('updated-contact-log') updatedContactLog: EventEmitter<any[]> = new EventEmitter<any[]>();
  @Output('close-form') closeForm: EventEmitter<boolean> = new EventEmitter<boolean>();
  contactLogForm: FormGroup = this.fb.group({
      contact_log_id: [],
      title: [],
      contact_datetime: [moment().startOf('day')],
      contact_notes: [],
      contact_type: [],
      contacted_by: [],
      status: [],
      date_completed: [],
      prepared_by: [this.authSvc.getCurrentUserName()],
      contact_link: [],
    });
  recipients: any[] = [];
  recipientControl: FormControl = new FormControl();
  recipientSearchResults: any[] = [];
  typeOptions: string[] = [
    "In-person",
    "Phone call",
    "Text",
    "Email",
    "Form Letter",
    "HW Note",
    "Twitter Message",
    "Gettr Message",
    "Facebook Message"
  ];
  titleOptions: string[] = [
    "Initial Contact",
    "Follow-up",
    "Ask",
    "Update",
    "Donor Update Email",
    "Renewal Meeting",
    "Event"
  ];
  statusOptions: string[] = [
    "Pending", "Completed"
  ];
  
  constructor(private fb: FormBuilder,
              private peopleSvc: PeopleService,
              private contactLogSvc: ContactLogsService,
              private authSvc: AuthService) {}
  
  ngOnInit() {
    this.recipientControl.valueChanges.pipe(
      startWith(''),
      debounceTime(300),
      switchMap((value: any) => {
        if (value && typeof value === "string") {
          return this.peopleSvc.search(value);
        } else {
          return of([]);
        }
      })
    ).subscribe((response: any[]) => {
      this.recipientSearchResults = response;
    });
    this.person$.subscribe((person: any) => {
      let filter = this.filter$.getValue();
      
      if (person.person_id) {
        this.recipients = [person];
        filter.id = person.person_id;
      } else {
        this.recipients = [];
        filter.id = null;
      }
      
      this.filter$.next(filter);
    });
  }
  
  createContactLog = (contactLog: any, recipients: number[], filter: any) => {
    if (contactLog.contact_log_id) {
      this.contactLogSvc.update(contactLog, filter).subscribe((response: any) => {
        this.updatedContactLog.emit(response);
        this.contactLogForm.reset({contact_datetime: moment()});
      })
    } else {
      this.contactLogSvc.createContactLog(contactLog, recipients, filter).subscribe((response: any) => {
      this.updatedContactLog.emit(response);
      this.contactLogForm.reset({contact_datetime: moment()});
    });
    }
  }
  changePage = (pageIncrement: number, filter: any) => {
    filter.pageIndex = filter.pageIndex + pageIncrement;
    this.filter$.next(filter);
  }
  resetForm = () => {
    this.contactLogForm.reset({contact_datetime: moment().startOf('day'), prepared_by: this.authSvc.getCurrentUserName()});
  }
  
  removeRecipient = (index: any) => {
    this.recipients = this.recipients.filter((recipient: any, idx: number) => index !== idx);
  }
  displayNull = () => {
    return "";
  }
}