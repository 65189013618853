<div class="container-fluid h-100 overflow-auto">
  <div class="row h-100 overflow-auto">
    <div class="col h-100 d-flex flex-column overflow-auto">
      <form class="h-100 w-100 overflow-auto d-flex flex-column flex-shrink-1"
            [formGroup]="emailForm">
        <div class="row w-100">
          <div class="col-12 col-lg-6">
            <mat-form-field appearance="legacy"
                            class="w-100">
              <mat-label>From:</mat-label>
              <mat-select formControlName="sender" name="sender">
                <mat-option *ngFor="let opt of fromOptions"
                            [value]="opt.name + ' <' + opt.email + '>'">
                  <span>'{{opt.name}}' &#60;{{opt.email}}&#62;</span>
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-12 col-lg-6">
            <mat-form-field appearance="legacy"
                            class="w-100">
              <mat-label>Reply To:</mat-label>
              <mat-select formControlName="replyTo" name="replyTo">
                <mat-option *ngFor="let opt of replyToOptions"
                            [value]="opt.name + ' <' + opt.email + '>'">
                  <span>'{{opt.name}}' &#60;{{opt.email}}&#62;</span>
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-12">
            <mat-form-field class="w-100">
              <mat-label>To:</mat-label>
              <mat-chip-list #recipientControl formControlName="to">
                <mat-chip *ngFor="let recipient of recipients; let index = index;"
                          (removed)="removeRecipient(index)">
                  <mat-icon *ngIf="recipient.type === 'list'">groups</mat-icon>
                  <span>{{displayResult(recipient)}}</span>
                  <button matChipRemove>
                    <mat-icon>cancel</mat-icon>
                  </button>
                </mat-chip>
              </mat-chip-list>
              <input class="w-100" matInput autocomplete="off"
                     #recipientInput name="to" type="text"
                     [matChipInputFor]="recipientControl"
                     [matAutocomplete]="search"
                     [ngModel]="recipientInput$ | async"
                     (ngModelChange)="recipientInput$.next($event)"
                     [ngModelOptions]="{standalone: true}"
                     (keyup)="recipientKeyup($event)">
              <button matSuffix mat-icon-button 
                      (click)="recipientInput$.next(''); recInput.nativeElement.value = '';">
                <mat-icon style="font-size: 16px;">close</mat-icon>
              </button>
              <mat-autocomplete autoActiveFirstOption #search="matAutocomplete"
                                (optionSelected)="addRecipientFromInput($event)">
                <mat-option *ngFor="let result of searchResults$ | async" [value]="result">
                  <mat-icon>{{result.type === "person"?"person":"groups"}}</mat-icon>
                  <span>{{displayResult(result)}}</span>
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>
          <div class="col-12">
            <mat-form-field appearance="legacy" class="w-100">
              <mat-label>Subject:</mat-label>
              <input matInput type="text" formControlName="subject" name="subject" autocomplete="off">
            </mat-form-field>
          </div>
        </div>
        <div class="row" style="margin-bottom: 10px;">
          <div class="col-12">
            <button mat-flat-button color="primary"
                    title="Open Saved Emails" [disabled]="true"
                    (click)="openEmailTemplateDialog()">
              <mat-icon>drafts</mat-icon>
            </button>
            <button mat-flat-button color="primary"
                    title="Save Email" [disabled]="emailForm.pristine"
                    (click)="saveEmail(emailForm.value)">
              <mat-icon>save</mat-icon>
            </button>
            <button mat-flat-button color="primary"
                    title="Save Email As" [disabled]="emailForm.pristine"
                    (click)="saveEmailAs(emailForm.value)">
              <mat-icon>save_as</mat-icon>
            </button>
            <mat-button-toggle-group [(ngModel)]="organization"
                                     [ngModelOptions]="{standalone: true}">
              <mat-button-toggle value="Center">Center for Renewing America</mat-button-toggle>
              <mat-button-toggle checked value="Citizens">Citizens for Renewing America</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="flex-shrink-1 overflow-auto">
          <button mat-flat-button color="primary" class="upload-btn" (click)="fileUpload.click()">Insert Image</button>
          <input type="file" style="display: none" (change)="fileSelected($event)" #fileUpload>
          <div [innerHtml]="topSet + header + bottomSet | safeHtml"></div>
          <angular-editor #editor
                          [placeholder]="'Enter email text here...'"
                          formControlName="html_content"
                          [config]="editorConfig"></angular-editor>
          <div [innerHtml]="topSet + footer + bottomSet | safeHtml"></div>
        </div>
        <div class="action-buttons">
          <button mat-flat-button color="primary"
                  [disabled]="recipients.length === 0 || !emailForm.controls['from'].value"
                  (click)="sendEmail(emailForm.value)">
            <span>Send</span>
          </button>
          <button mat-flat-button disabled color="primary">
            <span>Schedule Send</span>
          </button>
        </div>
      </form>
    </div>
  </div>
</div>