<div class="container-fluid" style="width: 300px;">
  <h1>Payments</h1>
  <form [formGroup]="paymentForm">
    <div class="row">
      <div class="col-12">
        <mat-form-field appearance="legacy" class="w-100">
          <mat-label>Payment Recipient</mat-label>
          <mat-select formControlName="payee" name="payee">
            <mat-option *ngFor="let recip of donationSvc.donationRecipientOptions" [value]="recip">{{recip}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-12">
        <mat-form-field appearance="legacy" class="w-100">
          <mat-label>Amount</mat-label>
          <input matInput type="text" name="payment_amount" formControlName="payment_amount">
          <span matPrefix>$&nbsp;</span>
        </mat-form-field>
      </div>
      <div class="col-12">
        <mat-form-field appearance="legacy" class="w-100">
          <mat-label>Payment Date</mat-label>
          <input matInput type="text" name="payment_date" formControlName="payment_date">
          <mat-datepicker-toggle matSuffix [for]="paymentDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #paymentDatePicker></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="col-12">
        <button mat-flat-button mat-dialog-close color="warn">Cancel</button>
        <button mat-flat-button color="primary" (click)="savePayment(paymentForm.value)">Save</button>
      </div>
    </div>
  </form>
</div>