<h1 mat-dialog-title>Note</h1>
<div mat-dialog-content>
  <ng-container *ngIf="viewMode === 'view'">
    <p><b>Title: </b>{{review.title}}</p>
    <p><b>Prepared By: </b>{{review.prepared_by}}</p>
    <p><b>Date Submitted: </b>{{review.date_sent | date: 'short'}}</p>
    <p>{{review.content}}</p>
    <div style="margin-top: 20px;"><button mat-flat-button mat-dialog-close color="warn">Close</button></div>
  </ng-container>
  <form *ngIf="viewMode === 'edit'" [formGroup]="reviewForm">
    <div>
      <mat-form-field style="width: 100%;">
        <mat-label>Title</mat-label>
        <input matInput type="text" formControlName="title">
      </mat-form-field>
    </div>
    <div>
      <mat-form-field style="width: 100%;">
        <mat-label>Prepared By</mat-label>
        <input matInput type="text" formControlName="prepared_by">
      </mat-form-field>
    </div>
    <div>
      <mat-form-field style="width: 100%;">
        <mat-label>Note</mat-label>
        <textarea matInput type="text" formControlName="content"
                  style="height: 200px;"></textarea>
      </mat-form-field>
    </div>
    <div id="button-row">
      <button mat-flat-button mat-dialog-close color="warn">
        <span>Cancel</span>
      </button>
      <button mat-flat-button [disabled]="reviewForm.pristine" color="primary"
              (click)="saveReview(reviewForm.value)">
        <span>Save</span>
      </button>
    </div>
  </form>
</div>