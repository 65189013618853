import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-person-overview-table',
  templateUrl: './person-overview-table.component.html',
  styleUrls: ['./person-overview-table.component.scss']
})
export class PersonOverviewTableComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
