import { Component, Input, Output, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { BehaviorSubject, combineLatest } from 'rxjs';
import { filter, switchMap, pairwise, withLatestFrom } from 'rxjs/operators';

import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { DialogService } from '@modules/dialogs/dialog.service';
import { PeopleService } from '@modules/person-profiles/people.service';
import { ContactLogsService } from '../contact-logs.service';

import { ContactLogDialogComponent } from '../contact-log-dialog/contact-log-dialog.component';
import { ContactLogFormComponent } from '../contact-log-form/contact-log-form.component';

@Component({
  selector: 'app-contact-log-table',
  templateUrl: './contact-log-table.component.html',
  styleUrls: ['./contact-log-table.component.scss']
})
export class ContactLogTableComponent implements OnInit {
  @Input('person') person$: BehaviorSubject<any> = new BehaviorSubject<any>({});
  @Output('person-updated') personUpdated: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('contactLogForm') contactLogForm: ContactLogFormComponent;
  items$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  filter$: BehaviorSubject<any> = new BehaviorSubject<any>({
    pageSize: 5,
    pageIndex: 0
  });
  pageSizeOptions: number[] = [5,10,15,20];
  totalRecords$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  totalPages: number = 0;
  showForm: boolean = false;
  recipients: any[] = [];
  
  constructor(private dialogSvc: DialogService,
              private peopleSvc: PeopleService,
              private logSvc: ContactLogsService,
              private fb: FormBuilder,
              public dialog: MatDialog) {}
  
  ngOnInit() {
    this.person$.pipe(
      withLatestFrom(this.filter$)
    ).subscribe(([person, filter]: [any, any]) => {      
      filter.id = person.person_id;
      this.filter$.next(filter);
    });
    this.filter$.pipe(
      filter((filter: any) => filter.id),
      switchMap((filter: any) => this.logSvc.getContactLogs(filter))
    ).subscribe((response: any) => {
      this.items$.next(response.items);
      this.totalRecords$.next(response.count);
    });
    combineLatest(this.filter$, this.totalRecords$).subscribe(([filter, totalRecs]) => {
      this.totalPages = Math.ceil(totalRecs / filter.pageSize);
    });
  }
  
  contactLogDialog = (viewMode: string, person: any, contactLog?: any) => {
    this.dialogSvc.contactLogDialog(viewMode, person, contactLog)
    .subscribe((response: any) => {
      if (response) this.edit(contactLog, person);
    });
  }
  edit = (contactLogItem: any, person: any) => {
    this.showForm = true;
    this.contactLogForm.contactLogForm.patchValue(contactLogItem);
  }
  delete = (logEntryObj: any, itemFilter: any) => {
    let title: string = "Confirm Contact Log Entry Delete",
        question: string = `Are you sure you want to delete the contact log entry titled '${logEntryObj.title}'?`;
    
    this.dialogSvc.confirmDialog(title, question).pipe(
      filter((response: boolean) => response),
      switchMap((response: boolean) => this.peopleSvc.deleteContactLogEntry(logEntryObj.contact_log_id, itemFilter))
    ).subscribe((response: any) => {
      this.items$.next(response.items);
      this.totalRecords$.next(response.count);
    });
  }
  removeContactFromContactLog = (person: any, joinId: number, logId: number, logFilter: any) => {
    let title = "Confirm Removal of Person From Contact Log",
        question = `Are you sure that you want to remove ${this.peopleSvc.getPersonName(person)} from the contact log entry?`;
    
    this.dialogSvc.confirmDialog(title, question).pipe(
      filter((response: boolean) => response),
      switchMap(() => this.logSvc.removeContactFromContactLog(joinId, logId, logFilter))
    ).subscribe((response: any) => {
      this.totalRecords$.next(response.count);
      this.items$.next(response.items);
    });
  }
  
  updatePageSize = (pageSize: number, filter: any) => {
    filter.pageSize = pageSize;
    this.filter$.next(filter);
  }
  pageIncrement = (filter: any, pageIncrement: number) => {
    filter.pageIndex = filter.pageIndex + pageIncrement;
    this.filter$.next(filter);
  }
}