import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { FlexLayoutModule } from '@angular/flex-layout';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatDialogModule } from '@angular/material/dialog';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSelectModule } from '@angular/material/select';
import { MatMenuModule } from '@angular/material/menu';

import { NgxMaskModule } from 'ngx-mask';

import { DonationDialogComponent } from './donation-dialog/donation-dialog.component';
import { DonationsComponent } from './donations/donations.component';
import { DonationsTableComponent } from './donations-table/donations-table.component';
import { DonorFrameComponent } from './donor-frame/donor-frame.component';
import { DonorPaymentTableComponent } from './donor-payment-table/donor-payment-table.component';
import { DonorsComponent } from './donors/donors.component';
import { MasterDonorTableComponent } from './master-donor-table/master-donor-table.component';
import { PaymentDialogComponent } from './payment-dialog/payment-dialog.component';

@NgModule({
  declarations: [
    DonationDialogComponent,
    DonationsComponent,
    DonationsTableComponent,
    DonorFrameComponent,
    DonorPaymentTableComponent,
    DonorsComponent,
    MasterDonorTableComponent,
    PaymentDialogComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    MatFormFieldModule,
    MatButtonModule,
    MatInputModule,
    MatIconModule,
    MatDatepickerModule,
    MatMomentDateModule,
    MatPaginatorModule,
    MatDialogModule,
    MatAutocompleteModule,
    MatSelectModule,
    MatMenuModule,
    NgxMaskModule,
  ],
  exports: [
    DonationDialogComponent,
    DonorFrameComponent,
    DonationsTableComponent,
  ]
})
export class DonorManagementModule { }