import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { FormControl, FormGroup, FormBuilder, FormArray } from '@angular/forms';

import { BehaviorSubject, of } from 'rxjs';
import { mergeMap, filter, map } from 'rxjs/operators';

import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { AngularEditorConfig, AngularEditorComponent } from '@kolkov/angular-editor';
import * as moment from "moment";

import { PeopleService } from '@modules/person-profiles/people.service';
import { AuthService } from '@modules/auth/auth.service';
import { DialogService } from '@modules/dialogs/dialog.service';
import { DonationsService } from '@modules/donor-management/donations.service';
import { UtilityService } from '@modules/utility-components/utility.service';
import { ListService } from '@modules/list-management/list.service';
import { FilterService } from '@modules/filter/filter.service';
import { ConfigService } from '@modules/utility-components/config.service';

import { DonationDialogComponent } from '@modules/donor-management/donation-dialog/donation-dialog.component';
import { ContactLogDialogComponent } from '@modules/contact-logs/contact-log-dialog/contact-log-dialog.component';

@Component({
  selector: 'app-person',
  templateUrl: './person.component.html',
  styleUrls: ['./person.component.scss']
})
export class PersonComponent implements OnInit {
  @Input('person') person$: BehaviorSubject<any> = new BehaviorSubject<any>({});
  @Output() changePerson = new EventEmitter<any>();
  callLink: string = 'www.slingofdavid.com/call-sign-form?recruiter=';
  
  // NGIF VARIABLES ***************************************************************
  showList: boolean = true;
  showNewAddress: boolean = false;
  showNewPhone: boolean = false;
  showNewEmail: boolean = false;
  showReports: boolean = false;
  displayMode: string = "view";
  showNewListForm: boolean = false;
  showNewRelationship: boolean = false;
  
  // VIEW PROPERTIES *************************************************************
  viewWidth: number = window.innerWidth;
  activePanes: string[] = ["General", "Notes", "Donations", "Contact Log"];
  activePane: string = this.activePanes[0];
  
  // FORM GROUP VARIABLES ********************************************************
  personForm: FormGroup;
  addressForm: FormGroup;
  emailForm: FormGroup;
  phoneForm: FormGroup;
  listForm: FormGroup;
  
  // DROPDOWN SETS ***************************************************************
  phoneLabels: string[] = ["Home", "Mobile", "Work", "Fax"];
  emailLabels: string[] = ["Home", "Work", "Other"];
  addressLabels: string[] = ["Home", "Work", "Other"];
  prefixOptions: string[] = ["Mr.", "Mrs.", "Ms.", "Miss", "Dr."];
  suffixOptions: string[] = ["Jr.", "Sr.", "PhD"];
  
  // LIST VARIABLES ***************************************************************
  selectedListMaster: any = {};
  listSearchText: string = "";
  listDropdown: BehaviorSubject<any[]> = new BehaviorSubject([]);
  
  // RELATIONSHIP VARIABLES *******************************************************
  relationshipSearch: FormControl = new FormControl();
  peopleSearchResults: any[] = [];
  
  // NOTES VARIABLES **************************************************************
  htmlNotes: FormControl = new FormControl();
  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '100%',
    minHeight: '500px',
    maxHeight: '75vh',
    width: 'auto',
    minWidth: '0',
    translate: 'no',
    sanitize: false,
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter text here...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
      {class: 'arial', name: 'Arial'},
      {class: 'times-new-roman', name: 'Times New Roman'},
      {class: 'calibri', name: 'Calibri'},
      {class: 'comic-sans-ms', name: 'Comic Sans MS'}
    ],
    customClasses: [],
    uploadUrl: 'https://slingofdavid.com/upload-email-image',
    // upload: this.onFileSelected,
    uploadWithCredentials: false,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      [],
      ["imageUpload"]
    ]
  };
  editNotes: boolean = false;
  
  constructor(public peopleSvc: PeopleService,
              private route: ActivatedRoute,
              private router: Router,
              public dialog: MatDialog,
              private fb: FormBuilder,
              public authSvc: AuthService,
              private _snackBar: MatSnackBar,
              private dialogSvc: DialogService,
              private donationSvc: DonationsService,
              public utilitySvc: UtilityService,
              public listSvc: ListService,
              private filterSvc: FilterService,
              private configSvc: ConfigService
              ) { }

  ngOnInit(): void {
    this.personForm = this.fb.group({
      person_id: [],
      prefix: [],
      first_name: [],
      middle_name: [],
      last_name: [],
      suffix: [],
      preferred_name: [],
      person_type: [],
      spouse: [],
      notes: [],
      addresses: this.fb.array([this.addresses]),
      phones: this.fb.array([this.phones]),
      email_addresses: this.fb.array([this.emailAddresses]),
    });
    this.phoneForm = this.fb.group({
      phone_id: [],
      phone_number: [''],
      label: [''],
      primary_phone: [false],
      contact_link: [],
      _slingupdate: [false]
    });
    this.emailForm = this.fb.group({
      email_address_id: [],
      email: [''],
      label: [''],
      primary_email: [false],
      contact_link: [],
      _slingupdate: [false]
    });
    this.addressForm = this.fb.group({
      label: [''],
      primary_address: [false],
      address_id: [],
      address: [''],
      address2: [''],
      city: [''],
      state: [''],
      zip:[''],
      contact_link: [],
      _slingupdate: [false]
    });
    this.listForm = this.fb.group({
      list: ['']
    });
    this.route.paramMap.subscribe(params => {
      if (params.get('id') === "new") {
        this.displayMode = "edit";
        this.personForm.reset();
      } else {
        this.displayMode = "view";
      }
    });
    this.listSvc.lists.subscribe(lsts => this.listDropdown.next(lsts));
    if (this.viewWidth < 768) this.activePane = "contact";
    this.person$.subscribe((person: any) => {
      this.personForm.reset();
      if (person.person_id) this.personForm.patchValue(person);
      this.htmlNotes.setValue(person.htmlNotes);
    });
    this.peopleSvc.searchControl(this.relationshipSearch).subscribe((response: any) => {
      this.peopleSearchResults = response;
    });
  }
  
  // FORM GROUPS ********************************************************************************
  get addresses(): FormGroup {
    return this.fb.group({
      label: [''],
      primary_address: [false],
      address_id: [],
      address: [''],
      address2: [''],
      city: [''],
      state: [''],
      zip:[''],
      contact_link: [],
      _slingupdate: [false]
    })
  }
  get phones(): FormGroup {
    return this.fb.group({
      phone_id: [],
      phone_number: [''],
      label: [''],
      primary_phone: [false],
      contact_link: [],
      _slingupdate: [false]
    });
  }
  get emailAddresses(): FormGroup {
    return this.fb.group({
      email_address_id: [],
      email: [''],
      label: [''],
      primary_email: [false],
      contact_link: [],
      _slingupdate: [false]
    });
  }
  
  // VIEW FUNCTIONS *****************************************************************************
  toggleDisplayMode = (mode: string, action: any, person: any) => {
    if (mode === "view") {
      let phoneArr = this.personForm.get('phones') as FormArray;
      phoneArr.reset();
      if (person.phones) {
        for (let i = phoneArr.length; i < person.phones.length; i++) {
          phoneArr.push(this.phones);
        }
      }
      let addressArr = this.personForm.get('addresses') as FormArray;
      addressArr.reset;
      if (person.addresses) {
        for (let i = addressArr.length; i < person.addresses.length; i++) {
          addressArr.push(this.addresses);
        }
      }
      let emailAddressArr = this.personForm.get('email_addresses') as FormArray;
      emailAddressArr.reset;
      if (person.email_addresses) {
        for (let i = emailAddressArr.length; i < person.email_addresses.length; i++) {
          emailAddressArr.push(this.emailAddresses);
        }
      }
      this.personForm.patchValue(person);
      this.displayMode = "edit";
    } else if (mode === "edit") {
      if (action === "save") {
        if (person.person_id) {
          let personObj = this.personForm.value;
          this.set_updateslingValues();
          this.peopleSvc.updatePerson(this.personForm.value)
          .subscribe((x: any) => {
            if (!person.person_id) {
              this.router.navigateByUrl("/activists/" + x.updatedPerson.person_id);
            } else {
              this.person$.next(x.updatedPerson);
              this.changePerson.emit(x.updatedPerson);
            }
            this.displayMode = "view";
          });
        } else {
          this.peopleSvc.createPerson(this.personForm.value).subscribe((response: any) => {
            this.router.navigateByUrl("/activists/" + response.person.person_id);
          });
        }
      } else {
        this.personForm.patchValue(person);
        this.displayMode = "view";
      }
    }
  }
  
  // PERSON FUCNTIONS ***************************************************************************
  getPersonName = (personObj) => {
    let result = "";
    
    if (personObj) {
      if (personObj.first_name) result = personObj.first_name;
      if (personObj.last_name) result += " " + personObj.last_name;
    }
    return result.trim();
  }
  deletePerson = (personObj) => {
    this.dialogSvc.confirmDialog("Confirm Delete", `Are you sure you want to delete '${this.peopleSvc.getPersonName(personObj)}'?`)
    .pipe(mergeMap((resp) => {if (resp) return this.peopleSvc.deletePerson(personObj)}))
    .subscribe((x: any) => {
      this.router.navigate(['/activists/new']);
    });
  }
  
  // RELATIONSHIP FUNCTIONS *********************************************************************
  selectPerson = (event: any, person: any) => {
    this.peopleSvc.createRelationship(event.option.value.person_id, person.person_id).subscribe((response: any) => {
      person.people.push(response);
    });
  }
  deleteRelationship = (id: number, person: any) => {
    this.peopleSvc.deleteRelationship(id).subscribe((response: any) => {
      person.people = person.people.filter(ppl => ppl.person_person_join_id != id);
      this.person$.next(person);
    })
  }
  
  // VERIFICATION FUNCTIONS *********************************************************************
  returnAuthUser = () => {
    if (this.authSvc.getCurrentUserName()) return this.authSvc.getCurrentUserName().toUpperCase();
    return "";
  }
  toggleVerificationStatus = (id: number, person: any) => {
    let verificationDate = new Date(),
        status = person.verified === 1?0:1;
        
    this.peopleSvc.toggleVerificationStatus(id, {
      verified: status,
      date_verified: moment(verificationDate).format("YYYY-MM-DD HH:mm:ss"),
      user_verified: this.authSvc.getCurrentUserName()
    })
    .subscribe((x: any) => {
      let msg = status === 1?`${this.authSvc.getCurrentUserName()} successfully VERIFIED ${this.peopleSvc.getPersonName(person)} at ${moment(verificationDate).format('H:mm a')} on ${moment(verificationDate).format('dddd, MMMM Do, YYYY')}`:`${this.authSvc.getCurrentUserName()} successfully UNVERIFIED ${this.peopleSvc.getPersonName(person)} at ${moment(verificationDate).format('H:mm a')} on ${moment(verificationDate).format('dddd, MMMM Do, YYYY')}`;
      this.person$.next(x.person);
      this._snackBar.open(msg, 'X', {horizontalPosition: 'center', verticalPosition: 'top', duration: 5000})
    });
  }
  getVerificationTitle = (person: any) => {
    let settings = this.configSvc.settings.getValue();
    
    if (person.person_id) {
      if (person.verified === 1) {
        return `${this.peopleSvc.getPersonName(person)} VERIFIED on ${person.date_verified} by ${person.user_verified}`;
      } else {
        return `Click here to VERIFY ${this.peopleSvc.getPersonName(person)} as authorized ${settings.applicationTitle} User ${this.returnAuthUser()}`;
      }
    } else {
      return "";
    }
  }
  
  // ADDRESS FUNCTIONS **************************************************************************
  // addressObj = {address: string, address2: string, city: string, state: string, zip: string, contact_id: integer}
  addAddress = (newAddressObj) => {
    let person = this.person$.getValue();
    
    if (person.person_id > 0) {
      newAddressObj.contact_link = person.person_id;
      this.peopleSvc.addAddress(newAddressObj).subscribe(x => {
        this.person$.next(x);
        this.addressForm.reset();
        this.showNewAddress = false;
      });
    }
  }
  deleteAddress = (addressIndex, personId) => {
    let addressArr = this.personForm.get('addresses') as FormArray;
    let currAddress = this.personForm.get('addresses')['controls'][addressIndex].value;
    
    if (addressArr.at(addressIndex).dirty || currAddress.address_id) {
      this.dialogSvc.confirmDialog("Confirm Delete Address", "Are you sure you want to delete this address?")
      .pipe(
        filter((resp: boolean) => resp),
        map(x => {if (addressArr.length > 1) {addressArr.removeAt(addressIndex)} else addressArr.at(addressIndex).reset()}),
        filter(x => currAddress.address_id),
        mergeMap((x) => this.peopleSvc.deleteAddress(currAddress.address_id, personId)))
      .subscribe((pers) => {
        this.person$.next(pers);
        this.personForm.patchValue(pers);
       });
    } else {
      if (addressArr.length > 1) addressArr.removeAt(addressIndex);
    }
  }
  addAddressForm = () => {
    (this.personForm.get('addresses') as FormArray).push(this.addresses);
  }
  setPrimaryAddress = (addressIndex) => {
    (this.personForm.get('addresses') as FormArray).controls.forEach((ad, idx) => {
      if (idx !== addressIndex && ad.value.primary_address) {
        ad.patchValue({primary_address: false});
        ad.markAsDirty();
      }
    });
  }
  
  // PHONE NUMBER FUNCTIONS *********************************************************************
  // newPhoneObj = {phone_number: string, label: string, contact_link: integer}
  addPhoneNumber = (newPhoneObj) => {
    let person = this.person$.getValue();
    
    if (person.person_id > 0) {
      newPhoneObj.contact_link = person.person_id;
      this.peopleSvc.addPhoneNumber(newPhoneObj).subscribe(x => {
        this.person$.next(x);
        this.phoneForm.reset();
        this.showNewPhone = false;
      });
    }
  }
  deletePhone = (personId, phoneIndex) => {
    let phoneArr = this.personForm.get('phones') as FormArray;
    let currPhone = phoneArr['controls'][phoneIndex].value;
    
    if (phoneArr.at(phoneIndex).dirty || currPhone.phone_id) {
      this.dialogSvc.confirmDialog("Confirm Delete Phone Number", "Are you sure you want to delete this phone number?")
      .pipe(
        filter((resp: boolean) => resp),
        map(x => {if (phoneArr.length > 1) {phoneArr.removeAt(phoneIndex)} else phoneArr.at(phoneIndex).reset()}),
        filter(x => currPhone.phone_id),
        mergeMap((x) => this.peopleSvc.deletePhoneNumber(currPhone.phone_id, personId)))
      .subscribe((pers) => {
        this.person$.next(pers);
        this.personForm.patchValue(pers);
       });
    } else {
      if (phoneArr.length > 1) phoneArr.removeAt(phoneIndex);
    }
  }
  addPhoneForm = () => {
    (this.personForm.get('phones') as FormArray).push(this.phones);
  }
  setPrimaryPhone = (phoneIndex) => {
    (this.personForm.get('phones') as FormArray).controls.forEach((ph, idx) => {
      if (idx !== phoneIndex && ph.value.primary_phone) {
        ph.patchValue({primary_phone: false});
        ph.markAsDirty();
      }
    });
  }
  
  // EMAIL ADDRESS FUNCTIONS ********************************************************************
  // emailAddressObj = {}
  addEmailAddress = (newEmailObj) => {
    let person = this.person$.getValue();
    
    if (person.person_id > 0) {
      newEmailObj.contact_link = person.person_id;
      this.peopleSvc.addEmailAddress(newEmailObj).subscribe(x => {
        this.person$.next(x);
        this.emailForm.reset();
        this.showNewEmail = false;
      });
    }
  }
  deleteEmailAddress = (emailAddressIndex, personId) => {
    let emailAddressArr = this.personForm.get('email_addresses') as FormArray;
    let currEmailAddress = this.personForm.get('email_addresses')['controls'][emailAddressIndex].value;
    
    if (emailAddressArr.at(emailAddressIndex).dirty || currEmailAddress.email_address_id) {
      this.dialogSvc.confirmDialog("Confirm Delete Email Address", "Are you sure you want to delete this email address?")
      .pipe(
        filter((resp: boolean) => resp),
        map(x => {if (emailAddressArr.length > 1) {emailAddressArr.removeAt(emailAddressIndex)} else emailAddressArr.at(emailAddressIndex).reset()}),
        filter(x => currEmailAddress.email_address_id),
        mergeMap((x) => this.peopleSvc.deleteEmailAddress(currEmailAddress.email_address_id, personId)))
      .subscribe((pers) => {
        this.person$.next(pers);
        this.personForm.patchValue(pers);
       });
    } else {
      if (emailAddressArr.length > 1) emailAddressArr.removeAt(emailAddressIndex);
    }
  }
  addEmailAddressForm = () => {
    (this.personForm.get('email_addresses') as FormArray).push(this.emailAddresses);
  }
  setPrimaryEmailAddress = (emailAddressIndex) => {
    (this.personForm.get('email_addresses') as FormArray).controls.forEach((em, idx) => {
      if (idx !== emailAddressIndex && em.value.primary_email) {
        em.patchValue({primary_email: false});
        em.markAsDirty();
      }
    });
  }
  toggleEmailMute = (person: any) => {
    let updatedEmailMuteStatus: number = person.mute_email === 0?1:0,
        updateObj: any = {
          person_id: person.person_id,
          mute_email: updatedEmailMuteStatus
        };
    
    this.peopleSvc.updatePerson(updateObj).subscribe((response: any) => {
      console.log(response);
      person.mute_email = updatedEmailMuteStatus;
    });
  }
  
  // PERSON FORM FUNCTIONS **********************************************************************
  set_updateslingValues = () => {
    let phoneArr = this.personForm.get('phones') as FormArray;
    let emailAddressArr = this.personForm.get('email_addresses') as FormArray;
    let addressArr = this.personForm.get('addresses') as FormArray;
    
    phoneArr['controls'].forEach((el, idx) => phoneArr.at(idx).get('_slingupdate').setValue(!el.pristine));
    emailAddressArr['controls'].forEach((el, idx) => emailAddressArr.at(idx).get('_slingupdate').setValue(!el.pristine));
    addressArr['controls'].forEach((el, idx) => {
      addressArr.at(idx).get('_slingupdate').setValue(!el.pristine)
    });
  }
  
  // NOTE FUNCTIONS *****************************************************************************
  reviewDialog = (viewMode, person?, review?) => {
    this.dialogSvc.reviewDialog(viewMode, person, review)
    .subscribe(x => {
      if (typeof x.review_id !== "undefined") {
        let person = this.person$.getValue(),
            index = person.reviews.findIndex(rvw => rvw.review_id === x.review_id);
        
        if (index > -1) {
          person.reviews[index] = x;
        } else {
          person.reviews.push(x);
        }
        this.person$.next(person);
      }
    });
  }
  promptNoteDelete = (note) => {
    let person = this.person$.getValue();
    
    this.dialogSvc.confirmDialog("Confirm Note Delete", `Are you sure you want to delete the note titled '${note.title}'?`)
    .pipe(mergeMap((resp) => {if (resp) return this.peopleSvc.deleteNote(note.review_id, person.person_id)}))
    .subscribe((pers: any) => {
      person.reviews.splice(person.reviews.findIndex(rvw => rvw.review_id === note.review_id), 1);
      this.person$.next(person);
    });
  }
  
  saveHtmlNotes = (html: string, person: any) => {
    this.peopleSvc.saveHtmlNotes(html, person.person_id).subscribe((response: any) => {
      person.htmlNotes = response.htmlNotes;
      this.person$.next(person);
      this.editNotes = false;
    })
  }
  
  // DONATION FUNCTIONS *************************************************************************
  donationDialog = (viewMode, person, donation?) => {    
    this.dialogSvc.donationDialog(viewMode, person, donation)
    .subscribe(x => {
      if (typeof x.donation_id !== "undefined") {
        let index = person.donations.findIndex(don => don.donation_id === x.donation_id);
        if (index > -1) {
          person.donations[index] = x;
        } else {
          person.donations.push(x);
        }
        person.donations.sort((a,b) => ((new Date(a.date) > new Date(b.date))?-1:1));
        this.person$.next(person);
      }
    });
  }
  deleteDonation = (donation) => {
    let person = this.person$.getValue();
    
    this.dialogSvc.confirmDialog("Confirm Donation Delete", `Are you sure you want to delete the ${donation.amount} donation?`)
    .pipe(mergeMap((resp) => {if (resp) return this.donationSvc.deleteDonation(donation.donation_id, person.person_id)}))
    .subscribe((pers: any) => {
      person.donations = pers.donations;
      person.donations.sort((a,b) => ((new Date(a.date) > new Date(b.date))?-1:1));
      this.person$.next(person);
    });
  }
  sumPledges = (person: any) => {
    return person.donations.reduce((sum: number, donation: any) => sum + donation.amount, 0);
  }
  sumPayments = (person: any) => {
    return person.donations.filter(don => don.amount_paid > 0).reduce((sum: number, payment: any) => sum + payment.amount, 0);
  }
  getPayments = (person: any) => {
    if (typeof person !== "undefined") {
      if (typeof person.donations !== "undefined") {
        return person.donations.filter(don => don.paid_amount > 0);
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  }
  
  // CONTACT LOG FUNCTIONS **********************************************************************
  contactLogDialog = (viewMode, person, contactLog?) => {
    this.dialogSvc.contactLogDialog(viewMode, person, contactLog)
    .subscribe(x => {
      let index = person.contact_log.findIndex(clog => clog.contact_log_id === x.contact_log_id);
      if (index > -1) {
        person.contact_log[index] = x;
      } else {
        person.contact_log.push(x);
      }
      this.person$.next(person);
    });
  }
  promptContactLogDelete = (logEntryObj: any, person: any) => {
    this.dialogSvc.confirmDialog("Confirm Contact Log Entry Delete", `Are you sure you want to delete the contact log entry titled '${logEntryObj.title}'?`)
    .pipe(mergeMap((resp) => {if (resp) return this.peopleSvc.deleteContactLogEntry(logEntryObj.contact_log_id, person.person_id)}))
    .subscribe((pers: any) => {if (typeof pers.updatedPerson !== "undefined") this.person$.next(pers.updatedPerson)});
  }
  editContactLog = (contactLogEntryObj: any, person: any) => {
    let dialogRef = this.dialog.open(ContactLogDialogComponent, {data: {viewMode: 'edit', contactLog: contactLogEntryObj, personId: person.person_id}})
    
    dialogRef.afterClosed().subscribe(pers => {if (typeof pers.updatedPerson !== "undefined") this.person$.next(pers.updatedPerson)});
  }
  
  // LIST FUNCTIONS *****************************************************************************
  createList = (listObj) => {
    this.listSvc.createList(listObj).subscribe((resp: any[]) => {
      this.listSvc.lists = resp[0];
      this.listForm.reset();
    })
  }
  deleteList = (list: any, person: any) => {
    this.dialogSvc.confirmDialog("Confirm List Delete Action", `Are you sure you want to permanently delete the list titled '${list.list}' and all the list connections to people?`)
    .pipe(mergeMap((resp: boolean) => {if (resp) return this.listSvc.deleteList(list.list_id)}))
    .subscribe((x: any) => {
      this.listSvc.lists.next(this.listSvc.lists.getValue().filter(lst => lst.list_id !== list.listId));
      person.lists.splice(person.lists.findIndex(lst => lst.list_id == list.listId), 1);
      this.person$.next(person);
    });
  }
  
  addPersonToSelectedList = (ev: any, person: any) => {
    this.listSearchText = "";
    if (person.person_id) {
      if (ev.option.value.list_id) {
        this.listSvc.addPersonToList(person.person_id, ev.option.value.list_id).subscribe((httpResponse: any) => {
          person.lists.push(httpResponse.newList);
          this.filterSvc.personListUpdate$.next({
            action: "add",
            person: person,
            list: ev.option.value
          });
        });
      } else if (ev.option.value.list_id === 0) {
        let newList = ev.option.value;
        delete newList.list_id;
        this.listSvc.createListAndAddPerson(ev.option.value, person.person_id).subscribe((response: any) => {
          person.lists.push(response.newList);
          this.listSvc.lists.next(response.lists);
          this.person$.next(person);
        });
      }
    }
  }
  createListAndAddPerson = () => {
    
  }
  removePersonFromList = (personListJoinId: number, list: any, person: any) => {
    this.listSvc.removePersonFromList(personListJoinId).subscribe((x: any) => {
      person.lists.splice(person.lists.findIndex(lst => lst.person_list_join_id === personListJoinId), 1);
      this.filterSvc.personListUpdate$.next({
        action: "remove",
        person: person,
        list: list
      });
      this.person$.next(person);
    });
  }
  addPersonToList = (list: any, person: any) => {
    if (person.person_id && list.list_id) {
      this.listSvc.addPersonToList(person.person_id, list.list_id).subscribe(x => {
        person.lists.push(list);
        this.person$.next(person);
      });
    }
  }
  filterLists = () => {
    this.listDropdown.next(this.listSvc.lists.getValue().filter(lst => {
      return (this.listSearchText.length?lst.list.toLowerCase().indexOf(this.listSearchText.toLowerCase()) > -1:true);
    }));
  }
      
  getFullAddress = (person) => {
    return person.address + (person.address2?", " + person.address2:"") + (person.city?", " + person.city:"") + (person.state?", " + person.state:"") + (person.zip?" " + person.zip:"")
  }
  copyLink = (link) => {
    
  }
  
  displayNull = () => {
    return null;
  }
 
}