import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';

import { AuthService } from '@modules/auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class IsAdministratorGuard implements CanActivate {
  currentUser: any;
  
  constructor(private authSvc: AuthService,
              private router: Router) {
    this.authSvc.currentUser$.subscribe(currUser => this.currentUser = currUser)
  }
  
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    
    if (this.currentUser.user_group === "Administrator") return true;
    return this.router.createUrlTree(['/']);
  }
  
}