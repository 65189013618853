<div class="container" style="width: 540px;">
  <div mat-dialog-title>Edit Row Data</div>
  <form class="row" [formGroup]="cellDataForm">
    <div class="col-4" *ngFor="let key of rowKeys" style="padding: 0 5px;">
      <mat-form-field appearance="legacy" [ngSwitch]="true">
        <mat-label>{{key}}</mat-label>
        <textarea *ngSwitchCase="(data[key] || '').length > 50" [name]="key" matInput [formControlName]="key"></textarea>
        <input matInput *ngSwitchDefault type="text" [name]="key" matInput [formControlName]="key">
      </mat-form-field>
    </div>
  </form>
  <div mat-dialog-actions>
    <button mat-flat-button color="warn" mat-dialog-close>Cancel</button>
    <button mat-flat-button color="primary" (click)="saveChanges(cellDataForm.value)">Save</button>
  </div>
</div>