<mat-paginator [length]="person.actions?.length" [pageSize]="tablePageSize"
               [pageSizeOptions]="[5]" (page)="pageEvent($event)"></mat-paginator>
<table class="table table-hover table-sm" style="margin-bottom: 50px;">
  <thead>
    <tr>
      <th scope="col">
        <span>Action Queue</span>
        <button mat-icon-button color="primary" class="small-icon-btn margin-left-10"
                (click)="actionDialog('edit', person)" [disabled]="!person.person_id">
          <mat-icon>add_circle</mat-icon>
        </button>
      </th>
      <th scope="col" class="text-center">Action Lead</th>
      <th scope="col" class="text-center">Status</th>
      <th scope="col" class="text-center">Date Completed</th>
      <th scope="col" class="text-center"><mat-icon>assignment</mat-icon></th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let action of person.actions">
      <td scope="row" class="pointer" (click)="actionDialog('view', person, action)">
        <span class="ellipsis">{{action.name}}</span>
      </td>
      <td class="text-center"><span>{{action.action_lead}}</span></td>
      <td class="pointer text-center" mat-button [matMenuTriggerFor]="statusMenu">
        <span>{{action.status}}</span>
      </td>
      <mat-menu class="compact-menu" #statusMenu="matMenu">
        <button mat-menu-item
                *ngFor="let option of peopleSvc.actionStatusOptions"
                (click)="updateActionStatus(action.action_id, {status: option})">
          <span>{{option}}</span>
        </button>
      </mat-menu>
      <td class="text-center"><span>{{action.date_completed | date:'shortDate'}}</span></td>
      <td class="text-center">
        <button mat-icon-button class="small-icon-btn" [matMenuTriggerFor]="actionMenu">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu class="compact-menu" #actionMenu="matMenu">
          <button mat-menu-item (click)="actionDialog('edit', person, action)"><span>Edit</span></button>
          <button mat-menu-item><span>Delete Action</span></button>
        </mat-menu>
      </td>
    </tr>
  </tbody>
</table>