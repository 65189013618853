import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { FlexLayoutModule } from '@angular/flex-layout';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatDividerModule } from '@angular/material/divider';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatSelectModule } from '@angular/material/select';

import { NgxMaskModule } from 'ngx-mask';
import { AngularEditorModule } from '@kolkov/angular-editor';

import { ActivistNotesModule } from '@modules/activist-notes/activist-notes.module';
import { ChromeModule } from '@modules/chrome/chrome.module';
import { ContactLogsModule } from '@modules/contact-logs/contact-logs.module';
import { CorrespondenceManagerModule } from '@modules/correspondence-manager/correspondence-manager.module';
import { DonorManagementModule } from '@modules/donor-management/donor-management.module';
import { UtilityComponentsModule } from '@modules/utility-components/utility-components.module';
import { PipesModule } from '@pipes/pipes.module';

import { ActivistsComponent } from './activists/activists.component';
import { PersonComponent } from './person/person.component';
import { PersonFilterListComponent } from './person-filter-list/person-filter-list.component';
import { PersonFormComponent } from './person-form/person-form.component';
import { PersonOverviewTableComponent } from './person-overview-table/person-overview-table.component';
import { RelationshipMapComponent } from './relationship-map/relationship-map.component';
import { PersonViewComponent } from './person-view/person-view.component';
import { SignupFormComponent } from './signup-form/signup-form.component';

@NgModule({
  declarations: [
    ActivistsComponent,
    PersonComponent,
    PersonFilterListComponent,
    PersonFormComponent,
    PersonOverviewTableComponent,
    RelationshipMapComponent,
    PersonViewComponent,
    SignupFormComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    FlexLayoutModule,
    MatFormFieldModule,
    MatButtonModule,
    MatInputModule,
    MatIconModule,
    MatCheckboxModule,
    MatMenuModule,
    MatPaginatorModule,
    MatDividerModule,
    MatAutocompleteModule,
    MatChipsModule,
    MatDatepickerModule,
    MatMomentDateModule,
    MatSelectModule,
    NgxMaskModule,
    AngularEditorModule,
    ActivistNotesModule,
    ChromeModule,
    ContactLogsModule,
    CorrespondenceManagerModule,
    DonorManagementModule,
    PipesModule,
    UtilityComponentsModule,
  ],
  exports: [
    ActivistsComponent,
    PersonFormComponent
  ]
})
export class PersonProfilesModule { }