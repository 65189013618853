import { Component, OnInit } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

import { EmailService } from '../email.service';

@Component({
  selector: 'app-open-email-dialog',
  templateUrl: './open-email-dialog.component.html',
  styleUrls: ['./open-email-dialog.component.scss']
})
export class OpenEmailDialogComponent implements OnInit {
  viewMode: string = "my";
  emailTemplates = {
    my: new BehaviorSubject<any[]>([]),
    team: new BehaviorSubject<any[]>([]),
    search: new BehaviorSubject<any[]>([])
  }

  constructor(public emailSvc: EmailService) { }

  ngOnInit(): void {
    this.emailSvc.getRecentEmailTemplates().subscribe((result: any) => {
      this.emailTemplates.my.next(result.my);
      this.emailTemplates.team.next(result.team);
    })
  }

}