import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { PeopleService } from '@modules/person-profiles/people.service';
import { DatetimefxService } from '@modules/utility-components/datetimefx.service';

@Component({
  selector: 'app-contact-log-dialog',
  templateUrl: './contact-log-dialog.component.html',
  styleUrls: ['./contact-log-dialog.component.scss']
})
export class ContactLogDialogComponent implements OnInit {
  // contactLogObj = {contact_log_id: integer, title: string, contact_datetime: datetime, contact_notes: string, contact_link: integer, date_created: datetime, user_created: string}
  // entryData = {viewFormat: string = "edit" | "view", contactLogEntry: object, personId: number}
  contactLogForm: FormGroup;
  typeOptions: string[] = [
    "In-person",
    "Phone call",
    "Text",
    "Email",
    "Form Letter",
    "HW Note",
    "Twitter Message",
    "Gettr Message",
    "Facebook Message"
  ];
  titleOptions: string[] = [
    "Initial Contact",
    "Follow-up",
    "Ask",
    "Update",
    "Donor Update Email",
    "Renewal Meeting",
    "Event"
  ];
  statusOptions: string[] = [
    "Pending", "Completed"
  ];
  viewFormat: string;
  contactLogEntry: any;

  constructor(@Inject(MAT_DIALOG_DATA) public entryData: any,
              private fb: FormBuilder,
              private peopleSvc: PeopleService,
              public dialogRef: MatDialogRef<ContactLogDialogComponent>,
              private dtf: DatetimefxService
              ) { }

  ngOnInit(): void {
    console.log("Entry Data: ", this.entryData);
    this.viewFormat = this.entryData.viewMode || "create";
    this.contactLogForm = this.fb.group({
      contact_log_id: [],
      title: [''],
      contact_datetime: [new Date()],
      contact_notes: [''],
      contact_type: [''],
      contacted_by: [''],
      status: [],
      date_completed: [],
      prepared_by: [''],
      contact_link: [this.entryData.person]
    });
    console.log("View Format: ", this.viewFormat);
    if (this.viewFormat === "edit") {
      if (typeof this.entryData.contactLog !== "undefined") {
        if (this.entryData.contactLog !== null) this.contactLogForm.patchValue(this.entryData.contactLog);
      }
    } else if (this.viewFormat === "view" && typeof this.entryData.contactLog !== "undefined") {
      this.contactLogEntry = this.entryData.contactLog;
    }
  }
}