import { Component, OnInit } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

import { Person } from '@modules/person-profiles/models/person.model';
import { PeopleService } from '@modules/person-profiles/people.service';
import { ListService } from '@modules/list-management/list.service';

@Component({
  selector: 'app-donors',
  templateUrl: './donors.component.html',
  styleUrls: ['./donors.component.scss']
})
export class DonorsComponent implements OnInit {
  donors: BehaviorSubject<Person[]> = new BehaviorSubject<Person[]>([]);
  columns: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([
    {label: "First Name", value: "first_name"},
    {label: "Middle Name", value: "middle_name"},
    {label: "Last Name", value: "last_name"},
    {label: "Suffix", value: "suffix"},
  ]);
  column_list: any[] = [
    {label: "Person ID", value: "person_id"},
    {label: "Prefix", value: "prefix"},
    {label: "First Name", value: "first_name"},
    {label: "Middle Name", value: "middle_name"},
    {label: "Last Name", value: "last_name"},
    {label: "Suffix", value: "suffix"},
    {label: "Preferred Name", value: "preferred_name"},
    {label: "Notes", value: "notes"},
  ]

  constructor(private listSvc: ListService) { }

  ngOnInit(): void {
    
  }

}