<form class="h-100 overflow-auto" [formGroup]="personForm">
  <div class="container h-100 overflow-auto d-flex flex-column">
    <div class="row">
      <div class="col-12 text-center" style="background-color: #003764">
        <img src="assets/config/images/citizens.png" width="200px">
        <h1 style="color: white">Signup Form</h1>
      </div>
    </div>
    <div class="row d-xl-flex flex-xl-row gx-4 name-fields w-100" style="max-width: 100%; margin-top: 25px;">
      <div class="col-auto"><mat-icon style="color: gray; margin-top: 10px;">portrait</mat-icon></div>
      <div class="col">
        <div class="row">
          <div class="col-xs-12 col-xl flex-xl-grow-1">
            <mat-form-field appearance="legacy" style="width: 100%;">
              <mat-label>First Name</mat-label>
              <input autocomplete="off" matInput type="text" formControlName="first_name">
            </mat-form-field>
          </div>
          <div class="col-xs-12 col-xl flex-xl-grow-1">
            <mat-form-field appearance="legacy" style="width: 100%;">
              <mat-label>Last Name</mat-label>
              <input autocomplete="off" matInput type="text" formControlName="last_name">
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-auto"><mat-icon class="pointer" title="Emails" style="color: gray;">email</mat-icon></div>
      <div class="col" formArrayName="email_addresses" style="padding-right: 30px;">
        <div class="w-100" *ngFor="let emailAdd of personForm.get('email_addresses')['controls']; let emailAddressIndex = index; let lastEmail = last;">
          <div class="w-100" [formGroupName]="emailAddressIndex">
            <div class="w-100 d-flex flex-row" style="margin-top: -15px;">
              <mat-form-field appearance="legacy" class="flex-grow-1" style="margin-right: 5px;">
                <mat-label>Email Address</mat-label>
                <input matInput type="email" formControlName="email" name="email">
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <div class="row">
          <div class="col-auto"><mat-icon class="pointer" title="Phones" style="color: gray;">phone</mat-icon></div>
          <div class="col" formArrayName="phones">
            <div class="w-100" *ngFor="let phone of personForm.get('phones')['controls']; let phoneIndex = index; let lastPhone = last;">
              <div class="w-100 d-flex flex-row" [formGroupName]="phoneIndex" style="margin-top: -15px;">
                <mat-form-field appearance="legacy" class="flex-grow-1" style="margin-right: 5px;">
                  <mat-label>Phone Number</mat-label>
                  <input matInput mask="(000) 000-0000" type="text" formControlName="phone_number">
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="row">
          <div class="col-auto"><mat-icon class="pointer" title="Addresses" style="color: gray;">place</mat-icon></div>
          <div class="col" formArrayName="addresses">
            <div *ngFor="let address of personForm.get('addresses')['controls']; let addressIndex = index; let lastAddress = last;">
              <div [formGroupName]="addressIndex">
                <div class="d-flex flex-row w-100" style="margin-top: -15px;">
                  <mat-form-field appearance="legacy" style="width: 200px;">
                    <mat-label>Zip</mat-label>
                    <input matInput type="text" formControlName="zip" name="zip">
                  </mat-form-field>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="d-flex justify-content-center">
        <button mat-flat-button color="primary" [disabled]="!addressArray.controls[0].get('zip').value"
                (click)="savePerson(personForm.value)">
          <span>Save</span>
        </button>
      </div>
    </div>
    <div class="row flex-grow-1">
      <div class="col-12 d-flex justify-content-center">
        <p>{{message}}</p>
      </div>
    </div>
    <div class="row">
      <table class="w-100">
          <tbody>
          <tr>
            <td style="background:#003764 none no-repeat center/cover;background-color:#003764;background-image:none;background-repeat:no-repeat;background-position:center;background-size:cover;border-top:0;border-bottom:0;padding-top:0px;padding-bottom:0px" valign="top" align="center">
              <table style="border-collapse:collapse;max-width:450px!important" width="100%" cellspacing="0" cellpadding="0" border="0" align="center">
                <tbody>
                  <tr>
                    <td width="24" valign="middle" align="center" style="padding-top: 20px;"><a rel="noreferrer nofollow noopener" href="https://www.facebook.com/amrenewcenter" target="_blank"><img alt="Facebook" style="display:block;border:0;height:auto;outline:none;text-decoration:none" src="assets/email/facebook.png" width="24" height="24"></a><br></td>
                    <td width="24" valign="middle" align="center" style="padding-top: 20px;"><a rel="noreferrer nofollow noopener" href="http://www.twitter.com/" target="_blank"><img alt="Twitter" style="display:block;border:0;height:auto;outline:none;text-decoration:none" src="assets/email/twitter.png" width="24" height="24"></a><br></td>
                    <td width="24" valign="middle" align="center" style="padding-top: 20px;"><a rel="noreferrer nofollow noopener" href="https://americarenewing.com/" target="_blank" ><img alt="Website" style="display:block;border:0;height:auto;outline:none;text-decoration:none" src="assets/email/link.png" width="24" height="24"></a><br></td>
                  </tr>
                  <tr>
                    <td style="padding-top:9px" valign="top" colspan="4">
                      <table style="max-width:100%;min-width:100%;border-collapse:collapse;" width="100%" cellspacing="0" cellpadding="0" border="0" align="left">
                        <tbody><tr>
                          <td style="padding-top:0;padding-right:18px;padding-bottom:9px;padding-left:18px;word-break:break-word;color:white;font-family:Helvetica;font-size:12px;line-height:150%;text-align:center" valign="top"><div><i>Copyright © 2022 Citizens for Renewing America, All rights reserved.</i>
                            <br></div><div>
                            <br></div><div><b>Our mailing address is:</b>
                            <br></div><div>300 Independence Ave. SE
                            <br></div><div>Washington,&nbsp;DC&nbsp;20003
                            <br></div><div> &nbsp;<br></div>
                          </td>
                        </tr></tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</form>