import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BehaviorSubject } from 'rxjs';
import { shareReplay, tap } from 'rxjs/operators';

import * as moment from "moment";

import { ConfigService } from '@modules/utility-components/config.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  url: string;
  currentUser$: BehaviorSubject<any> = new BehaviorSubject({});
  users$: BehaviorSubject<any[]> = new BehaviorSubject([]);
  error: BehaviorSubject<any> = new BehaviorSubject({type: "none", msg: ""});

  constructor(private http: HttpClient,
              private configSvc: ConfigService) {
    if (this.isLoggedIn) this.currentUser$.next(JSON.parse(localStorage.getItem('currentUser')));
    this.configSvc.settings.subscribe((settings: any) => this.url = settings.apiUrl);
  }
  
  getCurrentUserName = () => {
    let currUser: any = this.currentUser$.getValue();
    if (currUser) return currUser.first_name + " " + currUser.last_name;
  }
  
  login = (loginObj) => {
    return this.http.post(this.url + "login", {userLogin: loginObj}).pipe(
      tap(res => this.setSession),
      shareReplay()
    );
  }
  register = (regObj) => {
    return this.http.post(this.url + "register", {newUser: regObj});
  }
  setSession = (authResult) => {
    const expiresAt = moment().add(authResult.expiresIn,'second');
    console.log("Token: ", authResult);

    localStorage.setItem('id_token', authResult.idToken);
    localStorage.setItem("expires_at", JSON.stringify(expiresAt.valueOf()));
    this.setUser(authResult.user);
  }
  logout() {
    localStorage.removeItem("id_token");
    localStorage.removeItem("expires_at");
    localStorage.removeItem('currentUser');
  }
  
  public isLoggedIn() {
    return moment().isBefore(this.getExpiration());
  }
  isLoggedOut() {
    return !this.isLoggedIn();
  }
  getExpiration() {
    const expiration = localStorage.getItem("expires_at");
    const expiresAt = JSON.parse(expiration);
    return moment(expiresAt);
  }
  
  getUsers = () => {
    return this.http.get(this.url + "users");
  }
  setUser = (user) => {
    localStorage.setItem('currentUser', JSON.stringify(user));
  }
  
  activateUser = (userId, status) => {
    return this.http.put(this.url + "activate-user/" + userId, {status: status});
  }
  deleteUser = (userId) => {
    return this.http.delete(this.url + "user/" + userId);
  }
  saveUser = (userId, userObj) => {
    return this.http.put(this.url + 'user/' + userId, userObj);
  }
  changePassword = (userId, passwordObj) => {
    return this.http.put(this.url + 'password/' + userId, passwordObj)
  }
  
}