import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { filter } from 'rxjs/operators';

import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root'
})
export class RegionService {
  url: string;
  regions = [
    {
      name: "South Central",
      type: "region",
      regional_director: "George Smith",
      metrics: [
        {name: "Total Activists", value: "", goal: ""},
        {name: "Activists Recruited This Week", value: "", goal: ""},
      ],
      states: [
        {
          name: "Missouri"
        },
        {
          name: "Arkansas"
        },
        {
          name: "Louisiana"
        },
        {
          name: "Kansas"
        },
        {
          name: "Oklahoma"
        },
        {
          name: "Texas"
        }
      ],
      activist_goal: "1,000",
      current_activists: "0"
    },
    {
      name: "South",
      type: "region",
      regional_director: "George Smith",
      metrics: [
        {name: "Total Activists", value: "", goal: ""},
        {name: "Activists Recruited This Week", value: "", goal: ""},
      ],
      states: [
        {
          name: "Florida"
        },
        {
          name: "Georgia"
        },
        {
          name: "South Carolina"
        },
        {
          name: "Alabama"
        },
        {
          name: "Mississippi"
        }
      ],
      activist_goal: "1,000",
      current_activists: "0"
    },
    {
      name: "Southeast",
      type: "region",
      regional_director: "George Smith",
      metrics: [
        {name: "Total Activists", value: "", goal: ""},
        {name: "Activists Recruited This Week", value: "", goal: ""},
      ],
      states: [
        {
          name: "North Carolina"
        },
        {
          name: "Virginia"
        },
        {
          name: "Maryland"
        },
        {
          name: "West Virginia"
        },
        {
          name: "Kentucky"
        },
        {
          name: "Tennessee"
        },
        {
          name: "District of Columbia"
        }
      ],
      activist_goal: "1,000",
      current_activists: "0"
    },
    {
      name: "Great Lakes",
      type: "region",
      regional_director: "George Smith",
      metrics: [
        {name: "Total Activists", value: "", goal: ""},
        {name: "Activists Recruited This Week", value: "", goal: ""},
      ],
      states: [
        {
          name: "Wisconsin"
        },
        {
          name: "Michigan"
        },
        {
          name: "Illinois"
        },
        {
          name: "Indiana"
        },
        {
          name: "Ohio"
        }
      ],
      goals: [
        {name: ""}
      ],
      activist_goal: "1,000",
      current_activists: "0"
    },
    {
      name: "Northeast",
      type: "region",
      regional_director: "George Smith",
      metrics: [
        {name: "Total Activists", value: "", goal: ""},
        {name: "Activists Recruited This Week", value: "", goal: ""},
      ],
      states: [
        {
          name: "Pennsylvania"
        },
        {
          name: "New Hampsire"
        },
        {
          name: "Maine"
        },
        {
          name: "Connecticutt"
        },
        {
          name: "Rhode Island"
        },
        {
          name: "Vermont"
        },
        {
          name: "New York"
        },
        {
          name: "Massachusettes"
        },
        {
          name: "New Jersey"
        },
        {
          name: "Delaware"
        }
      ],
      activist_goal: "1,000",
      current_activists: "0"
    },
    {
      name: "Midwest",
      type: "region",
      regional_director: "George Smith",
      metrics: [
        {name: "Total Activists", value: "", goal: ""},
        {name: "Activists Recruited This Week", value: "", goal: ""},
      ],
      states: [
        {
          name: "Minnesota"
        },
        {
          name: "Iowa"
        },
        {
          name: "Nebraska"
        },
        {
          name: "North Dakota"
        },
        {
          name: "South Dakota"
        }
      ],
      activist_goal: "1,000",
      current_activists: "0"
    },
    {
      name: "Mountain",
      type: "region",
      regional_director: "George Smith",
      metrics: [
        {name: "Total Activists", value: "", goal: ""},
        {name: "Activists Recruited This Week", value: "", goal: ""},
      ],
      states: [
        {
          name: "Arizona"
        },
        {
          name: "Nevada"
        },
        {
          name: "Colorado"
        },
        {
          name: "New Mexico"
        },
        {
          name: "Montana"
        },
        {
          name: "Wyoming"
        },
        {
          name: "Idaho"
        },
        {
          name: "Utah"
        },
      ],
      activist_goal: "1,000",
      current_activists: "0"
    },
    {
      name: "West",
      type: "region",
      regional_director: "George Smith",
      metrics: [
        {name: "Total Activists", value: "", goal: ""},
        {name: "Activists Recruited This Week", value: "", goal: ""},
      ],
      states: [
        {
          name: "Alaska"
        },
        {
          name: "Washington"
        },
        {
          name: "Oregon"
        },
        {
          name: "California"
        },
        {
          name: "Hawaii"
        }
      ],
      activist_goal: "1,000",
      current_activists: "0"
    }
  ];
  states = [
    {
        "name": "Alabama",
        "abbreviation": "AL"
    },
    {
        "name": "Alaska",
        "abbreviation": "AK"
    },
    {
        "name": "Arizona",
        "abbreviation": "AZ"
    },
    {
        "name": "Arkansas",
        "abbreviation": "AR"
    },
    {
        "name": "California",
        "abbreviation": "CA"
    },
    {
        "name": "Colorado",
        "abbreviation": "CO"
    },
    {
        "name": "Connecticut",
        "abbreviation": "CT"
    },
    {
        "name": "Delaware",
        "abbreviation": "DE"
    },
    {
        "name": "District Of Columbia",
        "abbreviation": "DC"
    },
    {
        "name": "Florida",
        "abbreviation": "FL"
    },
    {
        "name": "Georgia",
        "abbreviation": "GA"
    },
    {
        "name": "Hawaii",
        "abbreviation": "HI"
    },
    {
        "name": "Idaho",
        "abbreviation": "ID"
    },
    {
        "name": "Illinois",
        "abbreviation": "IL"
    },
    {
        "name": "Indiana",
        "abbreviation": "IN"
    },
    {
        "name": "Iowa",
        "abbreviation": "IA"
    },
    {
        "name": "Kansas",
        "abbreviation": "KS"
    },
    {
        "name": "Kentucky",
        "abbreviation": "KY"
    },
    {
        "name": "Louisiana",
        "abbreviation": "LA"
    },
    {
        "name": "Maine",
        "abbreviation": "ME"
    },
    {
        "name": "Maryland",
        "abbreviation": "MD"
    },
    {
        "name": "Massachusetts",
        "abbreviation": "MA"
    },
    {
        "name": "Michigan",
        "abbreviation": "MI"
    },
    {
        "name": "Minnesota",
        "abbreviation": "MN"
    },
    {
        "name": "Mississippi",
        "abbreviation": "MS"
    },
    {
        "name": "Missouri",
        "abbreviation": "MO"
    },
    {
        "name": "Montana",
        "abbreviation": "MT"
    },
    {
        "name": "Nebraska",
        "abbreviation": "NE"
    },
    {
        "name": "Nevada",
        "abbreviation": "NV"
    },
    {
        "name": "New Hampshire",
        "abbreviation": "NH"
    },
    {
        "name": "New Jersey",
        "abbreviation": "NJ"
    },
    {
        "name": "New Mexico",
        "abbreviation": "NM"
    },
    {
        "name": "New York",
        "abbreviation": "NY"
    },
    {
        "name": "North Carolina",
        "abbreviation": "NC"
    },
    {
        "name": "North Dakota",
        "abbreviation": "ND"
    },
    {
        "name": "Ohio",
        "abbreviation": "OH"
    },
    {
        "name": "Oklahoma",
        "abbreviation": "OK"
    },
    {
        "name": "Oregon",
        "abbreviation": "OR"
    },
    {
        "name": "Pennsylvania",
        "abbreviation": "PA"
    },
    {
        "name": "Puerto Rico",
        "abbreviation": "PR"
    },
    {
        "name": "Rhode Island",
        "abbreviation": "RI"
    },
    {
        "name": "South Carolina",
        "abbreviation": "SC"
    },
    {
        "name": "South Dakota",
        "abbreviation": "SD"
    },
    {
        "name": "Tennessee",
        "abbreviation": "TN"
    },
    {
        "name": "Texas",
        "abbreviation": "TX"
    },
    {
        "name": "Utah",
        "abbreviation": "UT"
    },
    {
        "name": "Vermont",
        "abbreviation": "VT"
    },
    {
        "name": "Virginia",
        "abbreviation": "VA"
    },
    {
        "name": "Washington",
        "abbreviation": "WA"
    },
    {
        "name": "West Virginia",
        "abbreviation": "WV"
    },
    {
        "name": "Wisconsin",
        "abbreviation": "WI"
    },
    {
        "name": "Wyoming",
        "abbreviation": "WY"
    }
  ];
  US = {
      name: "United States",
      type: "national",
      regional_director: "George Smith",
      metrics: [
        {name: "Total Activists", value: "", goal: ""},
        {name: "Activists Recruited This Week", value: "", goal: ""},
      ],
      states: this.states
  };

  constructor(private http: HttpClient,
              private configSvc: ConfigService) {
    this.configSvc.settings.pipe(
      filter((settings) => settings.apiUrl)
    ).subscribe((settings: any) => this.url = settings.apiUrl);
  }
  
  getPeopleByStates = (states: string[]) => {
    return this.http.post(this.url + "people-by-state", {states: states});
  }
}