<div style="width: 90%; min-width: 600px; max-width: 800px;">
  <h1 mat-dialog-title>{{action.action_id?"Action":"New Action"}}</h1>
  <div mat-dialog-content>
    <form *ngIf="viewMode === 'edit'" [formGroup]="actionForm">
      <div class="row">
        <div class="col-12">
          <mat-form-field appearance="legacy" class="w-100">
            <mat-label>Action</mat-label>
            <input matInput type="text" formControlName="name" name="name">
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-lg-4">
          <mat-form-field appearance="legacy" class="w-100">
            <mat-label>Action Lead</mat-label>
            <input matInput name="action_lead" formControlName="action_lead">
          </mat-form-field>
        </div>
        <div class="col-12 col-lg-4">
          <mat-form-field appearance="legacy" class="w-100">
            <mat-label>Status</mat-label>
            <input matInput name="status" formControlName="status" [matAutocomplete]="statusAuto">
          </mat-form-field>
          <mat-autocomplete #statusAuto>
            <mat-option *ngFor="let opt of statusOptions" [value]="opt">
              <span>{{opt}}</span>
            </mat-option>
          </mat-autocomplete>
        </div>
        <div class="col-12 col-lg-4">
          <mat-form-field appearance="legacy" class="w-100">
            <mat-label>Date Completed</mat-label>
            <input matInput name="date_completed" formControlName="date_completed" [matDatepicker]="datepicker">
            <mat-datepicker-toggle matSuffix [for]="datepicker"></mat-datepicker-toggle>
            <mat-datepicker #datepicker></mat-datepicker>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <mat-form-field appearance="legacy" class="w-100">
            <mat-label>Notes</mat-label>
            <textarea matInput formControlName="notes" name="notes"></textarea>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <button mat-flat-button mat-dialog-close color="warn">
            <span>Cancel</span>
          </button>
          <button mat-flat-button color="primary" (click)="createAction(actionForm.value)">
            <span>Save</span>
          </button>
        </div>
      </div>
    </form>
    <ng-container *ngIf="viewMode === 'view'">
      <div class="row">
        <div class="col-12">
          <p><span class="fe-bold">Action: </span>{{action.name}}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <p><span class="fw-bold">Status: </span>{{action.status}}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <p><span class="fw-bold">Action Lead: </span>{{action.action_lead}}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <span class="fw-bold">Notes:</span>
          <p>{{action.notes}}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <button mat-flat-button mat-dialog-close color="warn">
            <span>Close</span>
          </button>
        </div>
      </div>
    </ng-container>
  </div>
</div>