import { Injectable } from '@angular/core';
import {MatDialog } from '@angular/material/dialog';

import { mergeMap } from 'rxjs/operators';

import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { DonationDialogComponent } from '@modules/donor-management/donation-dialog/donation-dialog.component';
import { ReviewDialogComponent } from '@modules/activist-notes/review-dialog/review-dialog.component';
import { ContactLogDialogComponent } from '@modules/contact-logs/contact-log-dialog/contact-log-dialog.component';
import { ActionDialogComponent } from '@modules/action-manager/action-dialog/action-dialog.component';
import { CorrespondenceDialogComponent } from '@modules/correspondence-manager/correspondence-dialog/correspondence-dialog.component';
import { PaymentDialogComponent } from '@modules/donor-management/payment-dialog/payment-dialog.component';
import { TemporaryTableDialogComponent } from '@modules/file-importer/temporary-table-dialog/temporary-table-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  constructor(public dialog: MatDialog) { }
  
  confirmDialog = (title, message) => {
    let dialogRef = this.dialog.open(ConfirmDialogComponent, {data: {title: title, message: message}});
    
    return dialogRef.afterClosed();
  }
  
  donationDialog = (viewMode, person?, donation?) => {
    let dialogDataObj = {person: null, viewMode: viewMode, donation: null}
    if (typeof donation !== "undefined") dialogDataObj.donation = donation;
    if (typeof person !== "undefined") dialogDataObj.person = person;
    
    let dialogRef = this.dialog.open(DonationDialogComponent, {data: dialogDataObj});
    return dialogRef.afterClosed();
    // return Observable<Person>
  }
  paymentDialog = (viewMode, person?, payment?) => {
    let dialogDataObj = {person: null, viewMode: viewMode, payment: null}
    if (typeof payment !== "undefined") dialogDataObj.payment = payment;
    if (typeof person !== "undefined") dialogDataObj.person = person;
    
    let dialogRef = this.dialog.open(PaymentDialogComponent, {data: dialogDataObj});
    return dialogRef.afterClosed();
    // return Observable<Person>
  }
  reviewDialog = (viewMode, person?, review?) => {
    let dialogDataObj = {person: null, viewMode: viewMode, review: null};
    if (typeof review !== "undefined") dialogDataObj.review = review;
    if (typeof person !== "undefined") dialogDataObj.person = person;
    
    let dialogRef = this.dialog.open(ReviewDialogComponent, {data: dialogDataObj, width: '600px'});
    return dialogRef.afterClosed();
  }
  contactLogDialog = (viewMode, person?, contactLog?) => {
    let dialogDataObj = {person: null, viewMode: viewMode, contactLog: null};
    if (typeof contactLog !== "undefined") dialogDataObj.contactLog = contactLog;
    if (typeof person !== "undefined") dialogDataObj.person = person;
    
    let dialogRef = this.dialog.open(ContactLogDialogComponent, {data: dialogDataObj});
    return dialogRef.afterClosed();
  }
  actionDialog = (viewMode, person?, action?) => {
    let dialogDataObj = {person: null, viewMode: viewMode, action: null};
    if (typeof action !== "undefined") dialogDataObj.action = action;
    if (typeof person !== "undefined") dialogDataObj.person = person;
    
    let dialogRef = this.dialog.open(ActionDialogComponent, {data: dialogDataObj});
    return dialogRef.afterClosed();
  }
  correspondenceDialog = () => {
    let dialogRef = this.dialog.open(CorrespondenceDialogComponent, {});
    return dialogRef.afterClosed();
  }
  temporaryTableDialog = (dataObj) => {
    let dialogRef = this.dialog.open(TemporaryTableDialogComponent, {data: {recordObj: dataObj}});
    return dialogRef.afterClosed();
  }
}