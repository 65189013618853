import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { filter } from 'rxjs/operators';

import { ConfigService } from '@modules/utility-components/config.service';

import { Payment } from './models/payment.model';

@Injectable({
  providedIn: 'root'
})
export class DonationsService {
  url: string;
  
  donationRecipientOptions: string[] = [];
  defaultRecipient: string = "";

  constructor(private http: HttpClient,
              private configSvc: ConfigService) {
    this.configSvc.settings.subscribe((settings: any) => this.url = settings.apiUrl);
    this.configSvc.settings.pipe(
      filter((settings) => settings.apiUrl)
    ).subscribe((settings: any) => {
      this.url = settings.apiUrl;
      this.donationRecipientOptions = settings.donations.recipientOptions;
      this.defaultRecipient = settings.donations.defaultRecipient;
    });
  }
  
  getDonors = (filter) => {
    return this.http.post<any[]>(this.url + "donations/donors", filter);
  }
  
  getAllDonations = () => {
    return this.http.get(this.url + "get-all-donations");
  }
  saveDonation = (donationObj) => {
    if (donationObj.donation_id) {
      return this.http.put(this.url + "donation/" + donationObj.donation_id, {donationObj: donationObj});
    } else {
      return this.http.post(this.url + "donation", {donationObj: donationObj});
    }
  }
  deleteDonation = (donationId, personId) => {
    return this.http.delete(this.url + "donation/" + donationId + "/" + personId);
  }
  
  downloadReport = (filter: any) => {
    return this.http.post(this.url + "donations/report", filter, {responseType: 'blob'});
  }
  
  getPayment = (paymentId: number) => {
    return this.http.get(this.url + "payment/" + paymentId);
  }
  getAllPayments = () => {
    return this.http.get(this.url + "payments");
  }
  createPayment = (paymentObj: Payment) => {
    return this.http.post(this.url + "payment", {paymentObj: paymentObj});
  }
  updatePayment = (paymentObj: Payment) => {
    return this.http.put(this.url + "payment", {paymentObj: paymentObj});
  }
  deletePayment = (paymentId: number) => {
    return this.http.delete(this.url + "payment/" + paymentId);
  }
}