import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

import { AuthService } from '@modules/auth/auth.service';
import { ConfigService } from '@modules/utility-components/config.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  registrationForm: FormGroup;
  forgotForm: FormGroup;
  mode: string = "login";
  user_error: string;
  password_error: string;
  login_error: string = "";
  showPassword: boolean = false;

  constructor(private fb: FormBuilder,
              private router: Router,
              private route: ActivatedRoute,
              public authSvc: AuthService,
              private configSvc: ConfigService
              ) { }

  ngOnInit(): void {
    this.loginForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required]
    });
    this.registrationForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      first_name: ['', Validators.required],
      last_name: ['', Validators.required],
      password: ['', Validators.required],
      confirmPassword: ['', Validators.required]
    });
    this.forgotForm = this.fb.group({
      email: ['', Validators.required]
    });
    this.loginForm.valueChanges.subscribe(x => this.authSvc.error.next({}));
    this.registrationForm.valueChanges.subscribe(x => this.authSvc.error.next({}));
  }
  
  login = (loginObj) => {
    if (loginObj.email && loginObj.password) {
      this.authSvc.login(loginObj)
      .subscribe((x: any) => {
        const { redirect } = window.history.state;
        this.authSvc.setSession(x);
        this.authSvc.currentUser$.next(x.user);
        this.router.navigateByUrl(redirect || '/');
      }, (err) => {
        console.log("current nav: ", this.router.getCurrentNavigation().extras.state)
        console.log(window.history.state.error);
        this.login_error = err.error.msg;
      });
    }
  }
  register = (regObj) => {
    this.user_error = "";
    this.password_error = "";
    if (regObj.email && regObj.password && regObj.confirmPassword) {
      if (regObj.password === regObj.confirmPassword) {
        this.authSvc.register(regObj)
        .subscribe(x => {
          console.log("User Registered!");
          this.mode = "login";
          this.loginForm.patchValue({email: regObj.email});
        }, (err) => {
          if (err.error.type === "username") this.user_error = err.error.msg;
          if (err.error.type === "password") this.password_error = err.error.msg;
        });
      } else {
        this.password_error = "Passwords do not match. Please retry confirming your password.";
      }
    }
  }
}