<div class="container-fluid overflow-auto h-100" style="padding: 5px 15px;">
  <div class="row h-100 overflow-auto">
    <div class="col-12 h-100 overflow-auto d-flex flex-column">
      <div class="row flex-grow-1 flex-shrink-1 overflow-auto">
        <div class="overflow-auto"
             [ngClass]="{'d-none': showXsList, 'col-xs-12 col-md h-100 d-flex flex-column': !showXsList}">
          <div class="row d-flex flex-column">
            <div class="d-none col-md-12 d-md-flex flex-md-row" style="margin-bottom: 10px;">              
              <app-search-bar style="margin-right: 20px;"></app-search-bar>
              <button mat-raised-button color="primary" (click)="newPerson()">New Activist or Donor</button>
              <div class="flex-grow-1"></div>
            </div>
          </div>
          <div class="row flex-grow-1 flex-shrink-1 overflow-auto">
            <div class="col-12 overflow-auto h-100">
              <app-person [person]="selActivist$" (personChange)="selActivist$.next($event)"></app-person>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>