import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, FormBuilder, FormGroup, Validators } from '@angular/forms';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { PeopleService } from '@modules/person-profiles/people.service';

@Component({
  selector: 'app-review-dialog',
  templateUrl: './review-dialog.component.html',
  styleUrls: ['./review-dialog.component.scss']
})
export class ReviewDialogComponent implements OnInit {
  viewMode: string = 'view';
  reviewForm: FormGroup;
  review = {};

  constructor(@Inject(MAT_DIALOG_DATA) public entryData: any,
              public dialogRef: MatDialogRef<ReviewDialogComponent>,
              private fb: FormBuilder,
              private peopleSvc: PeopleService,
              ) { }
  
  ngOnInit(): void {
    if (typeof this.entryData.viewMode !== "undefined") this.viewMode = this.entryData.viewMode;
    this.reviewForm = this.fb.group({
      review_id: [],
      title: [''],
      content: [''],
      prepared_by: [''],
      person_link: []
    });
    if (typeof this.entryData.review !== "undefined" && this.entryData.review) {
      this.review = this.entryData.review;
      this.reviewForm.patchValue(this.entryData.review);
    }
  }
  
  saveReview = (reviewObj) => {
    if (typeof reviewObj.person_link === "undefined" || !reviewObj.person_link) reviewObj.person_link = this.entryData.person.person_id;
    if (typeof reviewObj.review_id === "undefined" || !reviewObj.review_id) {
      this.peopleSvc.createNote(reviewObj).subscribe((x: any) => this.dialogRef.close(x.newReview));
    } else {
      this.peopleSvc.updateNote(reviewObj).subscribe((x: any) => this.dialogRef.close(x.updatedReview));
    }
  }
}