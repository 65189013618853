<div fxFlexFill fxLayout="column" fxLayoutAlign="start center" class="frame overflow-auto">
  <div fxLayout="column" fxLayoutGap="10px">
    <div>
      <img src="assets/config/logo.png" style="width: 200px;">
    </div>
    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
      <div class="mode-btn" (click)="mode = 'login';"
           [ngClass]="{'selected': mode === 'login'}">
        <span>Login</span>
      </div>
      <div class="mode-btn" (click)="mode = 'register';"
           [ngClass]="{'selected': mode === 'register'}">
        <span>Register</span>
      </div>
    </div>
    <div>
      <form *ngIf="mode === 'login'" [formGroup]="loginForm">
        <div>
          <mat-form-field>
            <mat-label>E-mail Address</mat-label>
            <input matInput type="text" formControlName="email" name="email">
          </mat-form-field>
          <div *ngIf="(authSvc.error | async).type === 'username'"
               class="error">{{(authSvc.error | async).msg}}</div>
        </div>
        <div>
          <mat-form-field>
            <mat-label>Password</mat-label>
            <input matInput [type]="showPassword?'text':'password'"
                   formControlName="password" name="password">
            <button [title]="'Click to ' + (showPassword?'hide':'show') + ' your password'"
                    matSuffix mat-icon-button (click)="showPassword=!showPassword">
              <mat-icon>{{showPassword?"visibility":"visibility_off"}}</mat-icon>
            </button>
          </mat-form-field>
          <div *ngIf="(authSvc.error | async).type === 'password'" class="error">
            <span>{{(authSvc.error | async).msg}}</span>
          </div>
        </div>
        <div>
          <button mat-flat-button color="primary" [disabled]="loginForm.pristine"
                  (click)="login(loginForm.value)">
            <span>Login</span>
          </button>
        </div>
      </form>
      <form *ngIf="mode === 'register'" [formGroup]="registrationForm">
        <div>
          <mat-form-field>
            <mat-label>First Name</mat-label>
            <input matInput type="text" name="first_name" formControlName="first_name">
          </mat-form-field>
        </div>
        <div>
          <mat-form-field>
            <mat-label>Last Name</mat-label>
            <input matInput type="text" formControlName="last_name" name="last_name">
          </mat-form-field>
        </div>
        <div>
          <mat-form-field>
            <mat-label>E-mail Address</mat-label>
            <input matInput type="text" formControlName="email" name="email">
          </mat-form-field>
          <div *ngIf="(authSvc.error | async).type === 'usernameRegistration'" class="error">
            <span>{{(authSvc.error | async).msg}}</span>
          </div>
        </div>
        <div class="error">{{user_error}}</div>
        <div>
          <mat-form-field>
            <mat-label>Password</mat-label>
            <input matInput [type]="showPassword?'text':'password'"
                   formControlName="password" name="password">
            <button [title]="'Click to ' + (showPassword?'hide':'show') + ' your password'"
                    matSuffix mat-icon-button (click)="showPassword=!showPassword">
              <mat-icon>{{showPassword?"visibility":"visibility_off"}}</mat-icon>
            </button>
          </mat-form-field>
        </div>
        <div>
          <mat-form-field>
            <mat-label>Confirm Password</mat-label>
            <input matInput [type]="showPassword?'text':'password'"
                   formControlName="confirmPassword" name="confirmPassword">
          </mat-form-field>
          <div *ngIf="(authSvc.error | async).type === 'passwordMatch'"
               class="error">
            <span>{{(authSvc.error | async).msg}}</span>
          </div>
        </div>
        <div class="error">{{password_error}}</div>
        <div>
          <button mat-flat-button color="primary"
                  [disabled]="registrationForm.pristine || !registrationForm.valid"
                  (click)="register(registrationForm.value)">
            <span>Register</span>
          </button>
        </div>
      </form>
      <form *ngIf="mode === 'forgot'" [formGroup]="forgotForm">
        <div>Enter your e-mail address below to your request a password reset.</div>
        <div>
          <mat-form-field>
            <mat-label>E-mail</mat-label>
            <input matInput type="text" formControlName="email" name="forgotPasswordEmail">
          </mat-form-field>
        </div>
        <div>
          <button mat-flat-button color="primary"
                  [disabled]="forgotForm.pristine || !forgotForm.valid"
                  (click)="forgot(forgotForm.value)">
            <span>Submit</span>
          </button>
        </div>
      </form>
    </div>
  </div>
</div>