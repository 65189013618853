<form class="input-group form-inline my-2 my-lg-0 float-right search-bar-form"
      [formGroup]="searchForm">
  <div class="input-group-prepend" [matMenuTriggerFor]="searchOptionsMenu" style="cursor: pointer">
    <span class="input-group-text"><mat-icon>settings</mat-icon></span>
  </div>
  <input class="form-control mr-sm-2" type="search"
         [placeholder]="'Search ' + searchFields.label"
         formControlName="searchRequest" [matAutocomplete]="auto">
  <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete"
                    (optionSelected)="selectItem($event)"
                    [displayWith]="displayFn">
    <!-- <mat-option [value]="newItemForm.get('name').value">Create new {{newItemSelectedClass.name}} '{{newItemForm.get("name").value}}'</mat-option> -->
    <mat-option *ngFor="let option of searchResults | async"
                [value]="option">
      <span>{{option.first_name + " " + option.last_name}}</span>
    </mat-option>
  </mat-autocomplete>
  <mat-menu #searchOptionsMenu>
    <button *ngFor="let opt of searchFieldOptions"
            mat-menu-item (click)="searchFields = opt">
      <span>{{opt.label}}</span>
    </button>
  </mat-menu>
</form>