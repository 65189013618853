import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatChipsModule } from '@angular/material/chips';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonToggleModule } from '@angular/material/button-toggle';

import { AngularEditorModule } from '@kolkov/angular-editor';
import { PipesModule } from '@pipes/pipes.module';
import { EmailUnsubscribeResolver } from '@modules/email-editor/email-unsubscribe.resolver';

import { EmailEditorComponent } from './email-editor/email-editor.component';
import { EmailPreviewDialogComponent } from './email-preview-dialog/email-preview-dialog.component';
import { OpenEmailDialogComponent } from './open-email-dialog/open-email-dialog.component';
import { SaveEmailTemplateDialogComponent } from './save-email-template-dialog/save-email-template-dialog.component';
import { UserEmailPreferencesComponent } from './user-email-preferences/user-email-preferences.component';

@NgModule({
  declarations: [
    EmailEditorComponent,
    EmailPreviewDialogComponent,
    OpenEmailDialogComponent,
    SaveEmailTemplateDialogComponent,
    UserEmailPreferencesComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatInputModule,
    MatFormFieldModule,
    MatChipsModule,
    MatAutocompleteModule,
    MatIconModule,
    MatSelectModule,
    MatDialogModule,
    AngularEditorModule,
    PipesModule,
    MatButtonToggleModule,
  ],
  providers: [
    EmailUnsubscribeResolver,
  ]
})
export class EmailEditorModule { }