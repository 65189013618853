<div fxLayout="row">
  <h1 mat-dialog-title>Donation</h1>
  <div fxFlex></div>
  <button mat-icon-button *ngIf="viewMode === 'view'"
          (click)="viewMode='edit'" style="margin-top: -7px;">
    <mat-icon>edit</mat-icon>
  </button>
</div>
<div mat-dialog-content>
  <div *ngIf="viewMode === 'view'">
    <div><span><b>Recipient: </b>{{donation.recipient}}</span></div>
    <div><span><b>Donor: </b>{{utilitySvc.getPersonName(donation)}}</span></div>
    <div fxLayout="row">
      <div><span><b>Amount Pledged: </b></span></div>
      <div style="text-align: right;"><span>{{donation.amount_pledged | currency}}</span></div>
    </div>
    <div fxLayout="row">
      <div><span><b>Amount Paid: </b></span></div>
      <div style="text-align: right;"><span>{{donation.amount_paid | currency}}</span></div>
    </div>
    <div fxLayout="row">
      <div><span><b>Date: </b></span></div>
      <div style="text-align: right"><span>{{donation.date_paid | date: 'shortDate'}}</span></div>
    </div>
    <hr/>
  </div>
  <form *ngIf="viewMode === 'edit'" [formGroup]="donationForm">
    <div>
      <mat-form-field class="w-100">
        <mat-label>Donor</mat-label>
        <input matInput type="text" formControlName="contact_link"
               name="contact_link" [matAutocomplete]="donor">
        <mat-autocomplete #donor="matAutocomplete" [displayWith]="autoDisplay">
          <mat-option *ngFor="let result of searchResults" [value]="result">
            <span>{{utilitySvc.getPersonName(result)}}</span>
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>
    <div>
      <mat-form-field class="w-100">
        <mat-label>Recipient</mat-label>
        <mat-select formControlName="recipient" name="recipient">
          <mat-option *ngFor="let recip of donationSvc.donationRecipientOptions" [value]="recip">
            <span>{{recip}}</span>
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div fxLayout="row">
      <mat-form-field style="width: 33%;">
        <mat-label>Amount Pledged</mat-label>
        <span matPrefix>$ &nbsp;</span>
        <input matInput type="text" formControlName="amount_pledged" class="text-end">
      </mat-form-field>
      <mat-form-field style="width: 33%;">
        <mat-label>Amount Paid</mat-label>
        <span matPrefix>$ &nbsp;</span>
        <input matInput type="text" formControlName="amount_paid" class="text-end">
      </mat-form-field>
      <mat-form-field style="width: 33%;">
        <mat-label>Date</mat-label>
        <input matInput [matDatepicker]="donationDate" formControlName="date">
        <mat-datepicker-toggle matSuffix [for]="donationDate"></mat-datepicker-toggle>
        <mat-datepicker #donationDate></mat-datepicker>
      </mat-form-field>
    </div>
    <div>
      <mat-form-field class="w-100">
        <mat-label>Notes</mat-label>
        <textarea matInput formControlName="notes" rows="5"
                  name="notes" autocomplete="off"></textarea>
      </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="5px">
      <button mat-flat-button mat-dialog-close color="warn">
        <span>Cancel</span>
      </button>
      <button mat-flat-button color="primary" (click)="saveDonation(donationForm.value)">
        <span>Save</span>
      </button>
    </div>
  </form>
</div>