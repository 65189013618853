import { Component, OnInit, Input } from '@angular/core';

import { mergeMap } from 'rxjs/operators';

import { DialogService } from '@modules/dialogs/dialog.service';
import { DonationsService } from '../donations.service';
import { DonationDialogComponent } from '../donation-dialog/donation-dialog.component';
import { Donation } from '../models/donation.model';

@Component({
  selector: 'app-donations-table',
  templateUrl: './donations-table.component.html',
  styleUrls: ['./donations-table.component.scss']
})
export class DonationsTableComponent implements OnInit {
  @Input('person') person: any;
  tablePageSize: number = 5;
  tablePageIndex: number = 0;
  tablePageRowStart: number = this.tablePageIndex * this.tablePageSize;
  tablePageRowEnd: number = this.tablePageRowStart + this.tablePageSize;

  constructor(private dialogSvc: DialogService,
              private donationSvc: DonationsService) { }

  ngOnInit(): void {
    
  }
  
  donationDialog = (viewMode: string, person: any, donation?: Donation) => {
    donation = donation || new Donation();
    this.dialogSvc.donationDialog(viewMode, person, donation)
    .subscribe((x: Donation) => {
      if (typeof x.donation_id !== "undefined") {
        let index = this.person.donations.findIndex((don: Donation) => don.donation_id === x.donation_id);
        if (index > -1) {
          this.person.donations[index] = x;
        } else {
          this.person.donations.unshift(x);
        }
      }
    });
  }
  deleteDonation = (donation) => {
    this.dialogSvc.confirmDialog("Confirm Donation Delete", `Are you sure you want to delete the ${donation.amount} donation?`)
    .pipe(mergeMap((resp) => {if (resp) return this.donationSvc.deleteDonation(donation.donation_id, this.person.person_id)}))
    .subscribe((pers: any) => this.person.donations = pers.donations);
  }
  sumArrayValues = (arr: any[], prop: string) => {
    if (typeof arr !== "undefined") return arr.reduce((a,b) => { return a + b[prop]}, 0);
    return "";
  }
  pageEvent = (event) => {
    console.log(event);
    this.tablePageIndex = event.pageIndex;
    this.tablePageRowStart = this.tablePageIndex * this.tablePageSize;
    this.tablePageRowEnd = this.tablePageRowStart + this.tablePageSize;
  }
}