<ng-container *ngIf="filter$ | async as filter">
  <div id="filter-button-row">
    <button mat-icon-button color="primary" title="Filter By List"
            [matMenuTriggerFor]="listMenu">
      <mat-icon>label</mat-icon>
    </button>
    <mat-menu #listMenu="matMenu">
      <button mat-menu-item (click)="showAll(filter)">
        <mat-icon [style.visibility]="filter.lists.length === 0?'visible':'hidden'">done</mat-icon>
        <span>All Entries</span>
      </button>
      <button mat-menu-item (click)="toggleList(list, filter)"
              *ngFor="let list of listSvc.lists | async">
        <mat-icon [style.visibility]="filterContainsList(list, filter)?'visible':'hidden'">done</mat-icon>
        <span>{{list.list}}</span>
      </button>
    </mat-menu>
    <button mat-icon-button color="primary" title="Filter By State"
            [matMenuTriggerFor]="stateMenu">
      <mat-icon>public</mat-icon>
    </button>
    <mat-menu #stateMenu="matMenu">
      <button mat-menu-item *ngFor="let state of regionSvc.states"
              (click)="toggleState(state.abbreviation, filter)">
        <mat-icon [style.visibility]="filter.states.indexOf(state.abbreviation) > -1?'visible':'hidden'">done</mat-icon>
        <span>{{state.name}}</span>
      </button>
    </mat-menu>
    <button mat-icon-button color="warn" title="Clear Filter Criteria"
            [disabled]="filter.lists.length === 0 && filter.states.length === 0"
            (click)="clearFilter(filter)">
      <mat-icon>clear</mat-icon>
    </button>
    <button mat-icon-button title="Download List Files" color="primary"
            [matMenuTriggerFor]="downloadMenu">
      <mat-icon>download</mat-icon>
    </button>
    <mat-menu #downloadMenu="matMenu">
      <button *ngIf="filter.lists.length > 0" mat-menu-item
              (click)="downloadFilterList(filter)">
        <span>Download Active Filter List</span>
      </button>
      <button mat-menu-item (click)="downloadDonorList({})">
        <span>Download All Donor List</span>
      </button>
      <button mat-menu-item (click)="downloadDonorList({donorsOnly: true})">
        <span>Download Contributing Donor List</span>
      </button>
      <button mat-menu-item (click)="downloadDonorList({year: 2021})">
        <span>Download 2021 All Donor List</span>
      </button>
      <button mat-menu-item (click)="downloadDonorList({donorsOnly: true, year: 2021})">
        <span>Download 2021 Contributing Donor List</span>
      </button>
      <button mat-menu-item (click)="downloadDonorList({year: 2022})">
        <span>Download 2022 All Donor List</span>
      </button>
      <button mat-menu-item (click)="downloadDonorList({donorsOnly: true, year: 2022})">
        <span>Download 2022 Contributing Donor List</span>
      </button>
      <mat-divider></mat-divider>
      <button *ngFor="let list of listSvc.lists | async" mat-menu-item
              (click)="downloadFilterList(filter)">
        <span>Download {{list.list}}</span>
      </button>
    </mat-menu>
  </div>
  <div id="filter-conditions" *ngIf="filter.lists.length > 0 || filter.states.length > 0">
    <div>
      <span style="font-weight: bold; text-decoration: underline;">Filter Conditions</span>
    </div>
    <div>
      <span *ngIf="filter.lists.length > 0">
        <span style="font-weight: bold;">Lists: </span>
        <span>{{displayLists(filter)}}</span>
      </span>
    </div>
    <div>
      <span *ngIf="filter.states.length">
        <span style="font-weight: bold;">States:&nbsp;</span>
        <span>{{stateFilterDisplay(filter.states)}}</span>
      </span>
    </div>
  </div>
  <app-paginator item-name="Donors" [page-index]="filter.pageIndex"
                 [page-size]="filter.pageSize" [total-records]="totalRecords"
                 (page-increment)="changePage(filter, $event)"
                 (change-page-size)="changePageSize(filter, $event)">
  </app-paginator>
  <ul id="person-list">
    <li *ngFor="let person of displayList$ | async"
        (click)="router.navigateByUrl('/activists/' + person.person_id)"
        [class]="person.person_type">
      <span>{{getPersonName(person)}}</span>
    </li>
  </ul>
</ng-container>