import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';
import { Router } from '@angular/router';

import { SignupService } from '@modules/person-profiles/signup.service';

@Component({
  selector: 'app-signup-form',
  templateUrl: './signup-form.component.html',
  styleUrls: ['./signup-form.component.scss']
})
export class SignupFormComponent {
  personForm: FormGroup;
  prefixOptions: string[] = ["Mr.", "Mrs.", "Ms.", "Miss", "Dr."];
  suffixOptions: string[] = ["Jr.", "Sr.", "PhD"];
  addressLabels: string[] = ["Home", "Work", "Other"];
  phoneLabels: string[] = ["Home", "Mobile", "Work", "Other"];
  emailLabels: string[] = ["Home", "Work", "Other"];
  message: string = "";

  constructor(private fb: FormBuilder,
              private signupSvc: SignupService,
              private router: Router) { }

  ngOnInit(): void {
    this.personForm = this.fb.group({
      prefix: [''],
      first_name: [''],
      middle_name: [''],
      last_name: [''],
      suffix: [''],
      preferred_name: [''],
      notes: [''],
      addresses: this.fb.array([this.addresses]),
      phones: this.fb.array([this.phones]),
      email_addresses: this.fb.array([this.emailAddresses]),
    });
    console.log(this.addressArray.controls[0].get('zip'));
  }
  
  get addresses(): FormGroup {
    return this.fb.group({
      label: [''],
      primary_address: [false],
      address_id: [],
      address: [''],
      address2: [''],
      city: [''],
      state: [''],
      zip:[''],
      contact_link: [],
      _slingupdate: [false]
    })
  }
  get phones(): FormGroup {
    return this.fb.group({
      phone_id: [],
      phone_number: [''],
      label: ['Mobile'],
      primary_phone: [true],
      contact_link: [],
      _slingupdate: [false]
    });
  }
  get emailAddresses(): FormGroup {
    return this.fb.group({
      email_address_id: [],
      email: [''],
      label: [''],
      primary_email: [true],
      contact_link: [],
      _slingupdate: [false]
    });
  }
  
  get addressArray() {
    return this.personForm.get('addresses') as FormArray;
  }
  
  savePerson = (personObj: any) => {
    this.signupSvc.submitNewSignup(personObj).subscribe((response: any) => {
      console.log("Response: ", response);
      this.personForm.reset();
      this.message = `${personObj.first_name} ${personObj.last_name} has been added to the system.`;
      window.location.href = "https://americarenewing.com/";
    });
  }
}