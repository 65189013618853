<div [formGroup]="contactLogForm" *ngIf="person$ | async as person">
  <div class="width-100">
    <mat-form-field class="width-100">
      <mat-label>Title</mat-label>
      <input matInput formControlName="title" name="title"
              [matAutocomplete]="titleAuto" autocomplete="off">
    </mat-form-field>
    <mat-autocomplete #titleAuto="matAutocomplete">
      <mat-option *ngFor="let opt of titleOptions" [value]="opt">
        <span>{{opt}}</span>
      </mat-option>
    </mat-autocomplete>
  </div>
  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
    <mat-form-field>
      <mat-label>Add Recipient</mat-label>
      <input matInput type="text" [matAutocomplete]="recipientAuto"
             [formControl]="recipientControl">
      <button mat-icon-button matSuffix (click)="recipientControl.reset()">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
    <mat-autocomplete #recipientAuto="matAutocomplete" [displayWith]="displayNull"
                      (optionSelected)="recipients.push($event.option.value)">
      <mat-option *ngFor="let searchResult of recipientSearchResults" [value]="searchResult">
        <span>{{searchResult.first_name + ' ' + searchResult.last_name}}</span>
      </mat-option>
    </mat-autocomplete>
    <div fxLayout="row wrap" fxLayoutAlign="start center"
         fxLayoutGap="2px" style="padding-bottom: 15px;">
      <div *ngFor="let recipient of recipients; let index = index" class="tag"
           fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="3px">
        <span>{{recipient.first_name + " " + recipient.last_name}}</span>
        <button mat-icon-button class="sm-icon-btn" (click)="removeRecipient(index)"
                [disabled]="person.person_id === recipient.person_id">
          <mat-icon>cancel</mat-icon>
        </button>
      </div>
    </div>
  </div>
  <div fxLayout="row wrap" fxLayoutGap="10px">
    <mat-form-field>
      <mat-label>Contact Date</mat-label>
      <input matInput formControlName="contact_datetime" name="contact_datetime"
             autocomplete="off" [matDatepicker]="contactDatepicker">
      <mat-datepicker-toggle matIconSuffix [for]="contactDatepicker"></mat-datepicker-toggle>
      <mat-datepicker #contactDatepicker></mat-datepicker>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Contact Type</mat-label>
      <input matInput formControlName="contact_type"
             name="contact_type" autocomplete="off" [matAutocomplete]="typeAuto">
    </mat-form-field>
    <mat-autocomplete #typeAuto="matAutocomplete">
      <mat-option *ngFor="let opt of typeOptions" [value]="opt">
        <span>{{opt}}</span>
      </mat-option>
    </mat-autocomplete>
    <mat-form-field>
      <mat-label>Contacted By</mat-label>
      <input matInput formControlName="contacted_by"
             name="contacted_by" autocomplete="off">
    </mat-form-field>
    <mat-form-field>
      <mat-label>Status</mat-label>
      <input matInput formControlName="status" name="status"
              [matAutocomplete]="titleAuto" autocomplete="off">
    </mat-form-field>
    <mat-autocomplete #statusAuto="matAutocomplete">
      <mat-option *ngFor="let opt of statusOptions" [value]="opt">
        <span>{{opt}}</span>
      </mat-option>
    </mat-autocomplete>
    <mat-form-field>
      <mat-label>Prepared By</mat-label>
      <input matInput formControlName="prepared_by"
             name="prepared_by" autocomplete="off">
    </mat-form-field>
  </div>
  <div>
    <mat-form-field style="width: 100%;">
      <mat-label>Contact Notes</mat-label>
      <textarea matInput formControlName="contact_notes" rows="5"
                name="contact_notes" autocomplete="off"></textarea>
    </mat-form-field>
  </div>
  <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="end" *ngIf="filter$ | async as filter">
    <button mat-flat-button color="warn" (click)="resetForm()">
      <span>Clear Form</span>
    </button>
    <button mat-flat-button color="warn" (click)="resetForm(); closeForm.emit(true);">
      <span>Close Form</span>
    </button>
    <button mat-flat-button [disabled]="contactLogForm.pristine || recipients.length === 0"
            color="primary" (click)="createContactLog(contactLogForm.value, recipients, filter)">
      <span>Save</span>
    </button>
  </div>
</div>