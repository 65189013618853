import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDialogModule } from '@angular/material/dialog';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';

import { CorrespondenceDialogComponent } from './correspondence-dialog/correspondence-dialog.component';
import { CorrespondenceMasterComponent } from './correspondence-master/correspondence-master.component';
import { CorrespondenceTableComponent } from './correspondence-table/correspondence-table.component';

@NgModule({
  declarations: [
    CorrespondenceDialogComponent,
    CorrespondenceMasterComponent,
    CorrespondenceTableComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatAutocompleteModule,
    MatDialogModule,
    MatMenuModule,
    MatPaginatorModule,
  ],
  exports: [
    CorrespondenceTableComponent
  ]
})
export class CorrespondenceManagerModule { }