<div class="container">
  <div class="row">
    <div class="col-12">
      <div>
        <mat-form-field appearance="legacy"
                        style="width: 600px;">
          <mat-label>E-mail Template Name</mat-label>
          <input matInput name="name"
                 [ngModel]="emailName | async"
                 (ngModelChange)="emailName.next($event)"
                 autocomplete="off">
          <button mat-icon-button
                  matSuffix>
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
        <p>Email Template Name '{{emailName | async}}' is {{emailNameAvailable?"":"not "}} available.</p>
      </div>
      <div class="d-flex justify-content-center">
        <button mat-flat-button
                color="warn"
                mat-dialog-close>
          <span>Cancel</span>
        </button>
        <button mat-flat-button
                [color]="emailNameAvailable?'primary':'warn'"
                [disabled]="!emailName && emailNameAvailable"
                [mat-dialog-close]="emailName | async">
          <span>{{emailNameAvailable?"Save":"Overwrite?"}}</span>
        </button>
      </div>
    </div>
  </div>
</div>