<table class="table table-hover table-sm" style="margin-bottom: 50px;">
  <thead>
    <tr>
      <th scope="col">
        Recipient
        <button mat-icon-button
                color="primary"
                (click)="paymentDialog('edit', person)"
                class="new-table-btn">
          <mat-icon>add_circle</mat-icon>
        </button>
      </th>
      <th scope="col" style="text-align: right;">Amount</th>
      <th scope="col" style="text-align: center;">Date Paid</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let payments of person.payments">
      <td scope="row" class="pointer" (click)="paymentDialog('edit', person, payment)">
        <span class="ellipsis">{{payment.payee}}</span>
      </td>
      <td style="text-align: right">
        <span *ngIf="payment.amount">$</span>
        <span>{{payment.payment_amount | mask: 'separator':','}}</span>
      </td>
      <td style="text-align: center">{{payment.payment_date | date: 'shortDate'}}</td>
      <td>
        <button mat-icon-button style="height: 14px; width: 14px; line-height: 14px;"
                [matMenuTriggerFor]="paymentActionMenu">
          <mat-icon style="font-size: 14px">more_vert</mat-icon>
        </button>
      </td>
      <mat-menu class="compact-menu" #paymentActionMenu="matMenu">
        <button mat-menu-item (click)="deletePayment(payment)">
          <mat-icon>delete</mat-icon>
          <span>Delete Donation</span>
        </button>
        <button mat-menu-item (click)="paymentDialog('edit', person, payment)">
          <mat-icon>edit</mat-icon>
          <span>Edit Donation</span>
        </button>
      </mat-menu>
    </tr>
  </tbody>
</table>