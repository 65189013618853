import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { BehaviorSubject, of } from 'rxjs';
import { startWith, debounceTime, switchMap } from 'rxjs/operators';

import { EmailService } from '../email.service';

@Component({
  selector: 'app-save-email-template-dialog',
  templateUrl: './save-email-template-dialog.component.html',
  styleUrls: ['./save-email-template-dialog.component.scss']
})
export class SaveEmailTemplateDialogComponent implements OnInit {
  emailName: BehaviorSubject<string> = new BehaviorSubject<string>("");
  emailNameAvailable: boolean = true;

  constructor(@Inject(MAT_DIALOG_DATA) private entry: any,
              private emailSvc: EmailService) { }

  ngOnInit(): void {
    this.emailName.pipe(
      startWith(''),
      debounceTime(300),
      switchMap(value => {
        if (value && typeof value === "string") {
          return this.emailSvc.checkEmailTemplateName(value.trim());
        } else {
          return of(false);
        }
      })
    ).subscribe((response: any) => this.emailNameAvailable = response.available);
    this.emailName.next(this.entry.name);
  }
}