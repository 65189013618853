import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl, FormBuilder, FormGroup, Validators } from '@angular/forms';

import { BehaviorSubject, of } from 'rxjs';
import { debounceTime, map, startWith, switchMap, filter } from 'rxjs/operators';

import { PeopleService } from '@modules/person-profiles/people.service';

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss']
})
export class SearchBarComponent implements OnInit {
  searchForm: FormGroup;
  searchResults: BehaviorSubject<any[]> = new BehaviorSubject([]);
  searchFieldOptions: any[] = [
    {label: "Name", value: "name"},
    {label: "Name & Notes", value: "nameAndNotes"}
  ];
  searchFields: any = this.searchFieldOptions[0];
  
  constructor( public peopleSvc: PeopleService,
               public fb: FormBuilder,
               public router: Router
              ) { }

  ngOnInit(): void {
    this.searchForm = this.fb.group({
      searchRequest: ['']
    });
    this.searchForm.controls["searchRequest"].valueChanges.pipe(
      startWith(''),
      debounceTime(300),
      switchMap(value => {
        if (value && typeof value === "string") {
          if (this.searchFields.value === "nameAndNotes") return this.peopleSvc.searchNameAndNotes(value.trim());
          return this.peopleSvc.searchAll(value.trim());
        } else {
          return of({result: [null]});
        }
      })
    ).subscribe((x: any) => {
      if (x.result[0]) {
        if (x.result[0].length > 0) {
          this.searchResults.next(x.result[0]);
        } else {
          this.searchResults.next([{first_name: "No Results", last_name: "Found"}]);
        }
      } else {
        this.searchResults.next([]);
      }
    });
  }
  selectItem = (ev: any) => {
    console.log(ev);
    this.searchForm.reset();
    this.router.navigateByUrl(`/activists/${ev.option.value.person_id}`);
  }
  displayFn = (person?: any) => {
    return person ? person.first_name + " " + person.last_name : undefined;
  }
}