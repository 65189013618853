<ng-container *ngIf="person$ | async as person">
  <app-person-filter-list fxFlex="15"></app-person-filter-list>
  <div fxFlex="33" fxLayout="column">
    <div fxLayout="row" fxLayoutGap="5px">
      <button [color]="person.verified===1?'primary':'warn'" mat-flat-button
              [disabled]="!person.person_id || !authSvc.getCurrentUserName()"
              [title]="getVerificationTitle(person)"
              (click)="toggleVerificationStatus(person.person_id, person)">
        <mat-icon style="font-size: 20px;">{{person.verified===1?"verified_user":"remove_moderator"}}</mat-icon>
        <span>{{person.verified===1?"VERIFIED":"UNVERIFIED"}}</span>
      </button>
      <button mat-flat-button [color]="person.mute_email?'warn':'primary'"
              (click)="toggleEmailMute(person)"
              [disabled]="!person.person_id || !authSvc.getCurrentUserName()"
              [title]="person.person_id?'Click here to ' + (person.mute_email?'include ':'temporarily remove ') + peopleSvc.getPersonName(person) + (person.mute_email?' in':' from') + ' all future emails.':''">
        <mat-icon style="font-size: 20px;">{{person.mute_email?'block':'email'}}</mat-icon>
        <span>{{person.mute_email?'Email Muted':'Email Active'}}</span>
      </button>
      <div fxFlex></div>
      <button *ngIf="displayMode === 'view'" mat-icon-button [matMenuTriggerFor]="personMenu">
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #personMenu="matMenu">
        <button mat-menu-item (click)="toggleDisplayMode(displayMode, 'edit', person)">
          <mat-icon>edit</mat-icon><span>Edit</span>
        </button>
        <button mat-menu-item (click)="deletePerson(person)">
          <mat-icon>delete</mat-icon><span>Delete</span>
        </button>
      </mat-menu>
    </div>
    <div id="phone-tabs">
      <div class="pane" [ngClass]="{'pane-selected': activePane === 'contact'}"
           (click)="activePane = 'contact';">
        <span>Contact</span>
      </div>
      <div class="pane" [ngClass]="{'pane-selected': activePane === pane}"
           *ngFor="let pane of activePanes" (click)="activePane = pane;">
        <span>{{pane}}</span>
      </div>
    </div>
    <app-person-view fxFlex *ngIf="displayMode === 'view'" [person]="person$"
                     (person-updated)="person$.next($event)"></app-person-view>
    <app-person-form *ngIf="displayMode === 'edit'" [person]="person$"
                     (person-updated)="person$.next($event); displayMode = 'view';"
                     (cancel)="displayMode = 'view'"></app-person-form>
  </div>
  <div fxFlex fxLayout="column" class="height-100 overflow-auto">
    <div fxLayout="row">
      <div fxFlex class="pane" *ngFor="let pane of activePanes"
           [ngClass]="{'pane-selected': activePane === pane}"
           (click)="activePane = pane;">
        <span>{{pane}}</span>
      </div>
    </div>
    <div fxFlex class="overflow-auto">
      <div *ngIf="activePane === 'General'">
        <app-individual-notes-table [person]="person"></app-individual-notes-table>
      </div>
      <div *ngIf="activePane === 'Notes'" class="overflow-auto">
        <div *ngIf="editNotes">
          <button mat-flat-button color="primary" [disabled]="!person.person_id"
                  (click)="saveHtmlNotes(htmlNotes.value, person)">
            <span>Save Notes</span>
          </button>
          <button mat-flat-button color="warn" (click)="htmlNotes.reset(person.htmlNotes); editNotes = false;">
            <span>Cancel</span>
          </button>
          <angular-editor [formControl]="htmlNotes"
                          [config]="editorConfig"></angular-editor>
        </div>
        <div *ngIf="!editNotes" fxLayout="column" class="height-100 overflow-auto">
          <div>
            <button mat-flat-button color="primary" (click)="editNotes = true">
              <span>Open Notes Editor</span>
            </button>
          </div>
          <div *ngIf="person.htmlNotes" fxFlex="1 1 100%" class="overflow-auto"
               [innerHtml]="person.htmlNotes | safeHtml"></div>
        </div>
      </div>
      <div *ngIf="activePane === 'Donations'" fxLayout="column" class="overflow-auto">
        <app-donations-table [person]="person"></app-donations-table>
      </div>
      <app-contact-log-table *ngIf="activePane === 'Contact Log'" [person]="person$"></app-contact-log-table>
    </div>
  </div>
</ng-container>