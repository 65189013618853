import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { BehaviorSubject, Subject } from 'rxjs';
import { switchMap, filter } from 'rxjs/operators';

import * as moment from 'moment';

import { DonationsService } from '../donations.service';
import { FilesService } from '@modules/file-importer/files.service';
import { RegionService } from '@modules/utility-components/region.service';

@Component({
  selector: 'app-master-donor-table',
  templateUrl: './master-donor-table.component.html',
  styleUrls: ['./master-donor-table.component.scss']
})
export class MasterDonorTableComponent implements OnInit {
  columns$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([
    {label: "First Name", value: "first_name", align: "left"},
    {label: "Last Name", value: "last_name", align: "left"},
    {label: "E-mail", value: "email_address", align: "left"},
    {label: "Total Pledges", value: "total_pledged", align: "right"},
    {label: "Total Paid", value: "total_paid", align: "right"}
  ]);
  data$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  donorColumns: any[] = [
    {label: "Prefix", value: "prefix", align: "left"},
    {label: "First Name", value: "first_name", align: "left"},
    {label: "Middle Name", value: "middle_name", align: "left"},
    {label: "Last Name", value: "last_name", align: "left"},
    {label: "Suffix", value: "suffix", align: "left"},
    {label: "E-mail", value: "email_address", align: "left"},
    {label: "Total Pledges", value: "total_pledged", align: "right"},
    {label: "Total Paid", value: "total_paid", align: "right"}
  ];
  donationColumms: any[] = [];
  filterForm: FormGroup = this.fb.group({
    name: [],
    start: [],
    end: [],
    from: [],
    to: []
  });
  showStateFilter: boolean = false;
  stateFilter$: BehaviorSubject<any[]> = new BehaviorSubject<any>([]);
  filter$: Subject<any> = new Subject<any>();

  constructor(private fb: FormBuilder,
              private donationSvc: DonationsService,
              private fileSvc: FilesService,
              public regionSvc: RegionService) { }

  ngOnInit(): void {
    this.filter$.pipe(
      switchMap((filter: any) => this.donationSvc.getDonors(filter))
    ).subscribe((response: any[]) => {
      this.data$.next(response);
    });
    this.filterForm.valueChanges.subscribe((filter) => {
      let stateFilter = this.stateFilter$.getValue();
      
      this.filter$.next(this.buildFilter(filter, stateFilter));
    });
    this.stateFilter$.subscribe((stateFilter: string[]) => {
      this.filter$.next(this.buildFilter(this.filterForm.value, stateFilter));
    });
  }
  
  buildFilter = (filter: any, stateFilter: string[]) => {
    let output: any = {
                        ...filter,
                        stateFilter: stateFilter
                      }
    console.log("FILTER OUTPUT: ", output);
    return output;
  }
  
  toggleState = (state: any, stateFilter: any[]) => {
    if (stateFilter.includes(state.abbreviation)) {
      stateFilter = stateFilter.filter(sta => sta !== state.abbreviation);
    } else {
      stateFilter.push(state.abbreviation);
      stateFilter.sort((a,b) => a > b?1:-1);
    }
    this.stateFilter$.next(stateFilter);
  }
  
  downloadReport = () => {
    let reportFilter = this.buildFilter(this.filterForm.value, this.stateFilter$.getValue());
    
    this.donationSvc.downloadReport(reportFilter).subscribe((result: Blob) => {
      this.fileSvc.downloadFile("Donor Report - " + moment().format("YYYY-MM-DD HH:mm:ss") + ".xlsx", result);
    })
  }
}