<mat-paginator *ngIf="pagLoc === 'top'"
               [length]="tableData.length"
               [pageSize]="pageSize"
               [pageSizeOptions]="pageSizeOptions">
</mat-paginator>
<table>
  <thead>
    <tr>
      <th *ngFor="let col of columns"><span>{{col}}</span></th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let row of tableData">
      <td *ngFor="let col of columns"><span>{{row[col]}}</span></td>
    </tr>
  </tbody>
</table>
<mat-paginator *ngIf="pagLoc === 'bottom'"
               [length]="tableData.length"
               [pageSize]="pageSize"
               [pageSizeOptions]="pageSizeOptions">
</mat-paginator>