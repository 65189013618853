import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatProgressBarModule } from'@angular/material/progress-bar';
import { MatPaginatorModule } from '@angular/material/paginator';
import { DragDropModule } from '@angular/cdk/drag-drop';

import { DonorManagementModule } from '@modules/donor-management/donor-management.module';

import { DeduplicationDesktopComponent } from './deduplication-desktop/deduplication-desktop.component';
import { DuplicateListComponent } from './duplicate-list/duplicate-list.component';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { ImportDuplicateDisplayComponent } from './import-duplicate-display/import-duplicate-display.component';
import { ImportFilesComponent } from './import-files/import-files.component';
import { ScanTableCellComponent } from './scan-table-cell/scan-table-cell.component';
import { TemporaryTableDialogComponent } from './temporary-table-dialog/temporary-table-dialog.component';
import { ValidTableCellComponent } from './valid-table-cell/valid-table-cell.component';

@NgModule({
  declarations: [
    DeduplicationDesktopComponent,
    DuplicateListComponent,
    FileUploadComponent,
    ImportFilesComponent,
    ImportDuplicateDisplayComponent,
    ScanTableCellComponent,
    TemporaryTableDialogComponent,
    ValidTableCellComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatSelectModule,
    MatCheckboxModule,
    MatProgressBarModule,
    MatPaginatorModule,
    DragDropModule,
    DonorManagementModule,
  ]
})
export class FileImporterModule { }