<div class="container h-100 d-flex flex-column overflow-auto" *ngIf="filter$ | async as filter">
  <div class="row">
    <div class="col-3">
      <button mat-flat-button color="primary" (click)="createDonation()">New Donation</button>
    </div>
    <div class="col-3">
      <span [ngClass]="{'selected-filter': filter.indexOf('pledge') > -1}" class="pointer filter-selector" (click)="toggleFilter('pledge')">Pledges</span>
    </div>
    <div class="col-3">
      <span [ngClass]="{'selected-filter': filter.indexOf('payment') > -1}" class="pointer filter-selector" (click)="toggleFilter('payment')">Payments</span>
    </div>
    <div class="col-3">
      
    </div>
  </div>
  <div class="row flex-grow-1 flex-shrink-1 overflow-auto">
    <table class="table table-hover table-sm sticky-header">
      <thead>
        <tr>
          <th>Donor</th>
          <th>Recipient</th>
          <th>Type</th>
          <th>Amount</th>
          <th>Date</th>
          <th><mat-icon>assignment_turned_in</mat-icon></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let row of tableData$ | async" class="pointer">
          <td (click)="viewDonation(row)">{{utilitySvc.getPersonName(row)}}</td>
          <td>{{row.recipient}}</td>
          <td>{{row.type}}</td>
          <td>{{row.amount | currency}}</td>
          <td>{{row.date_pledged | date: 'shortDate'}}</td>
          <td>
            <button mat-icon-button [matMenuTriggerFor]="donationAction">
              <mat-icon>more_vert</mat-icon>
            </button>
          </td>
          <mat-menu #donationAction="matMenu">
            <button mat-menu-item><span>Edit Donation</span></button>
            <button mat-menu-item><span>Delete Donation</span></button>
          </mat-menu>
        </tr>
      </tbody>
    </table>      
  </div>
</div>