<div class="container shell h-100 overflow-auto p-3 d-flex flex-column gap-3" 
     *ngIf="duplicateRow | async as row">
  <ng-container *ngIf="selectedPerson$ | async as person">
    <div class="row" (wheel)="scrollEvent.emit($event)">
      <div class="col-4">
        <button mat-flat-button color="primary" [disabled]="row.sling_duplicate === 1"
                (click)="markAsDuplicate(row)">
          <span>Mark as Duplicate</span>
        </button>
      </div>
      <div class="col-4" style="font-weight: bold; color: red; font-size: 40px;">
        <span *ngIf="row.sling_duplicate">DUPLICATE</span>
      </div>
      <div class="col-4">

      </div>
    </div>
    <div class="row flex-shrink-1 overflow-auto">
      <div class="col-12">
        <div class="row" *ngFor="let tbl of tableArray">
          <div class="col-12 table-row-header">
            <div class="row">
              <div class="col-4">
                <span>{{fileSvc.displayText(tbl)}}</span>
              </div>
              <div class="col-4 text-center"></div>
              <div class="col-4">

              </div>
            </div>
          </div>
          <!-- <div class="row" *ngFor="let seq of importSequence | async; let seqIndex = index">
            <div class="col-12"> -->        
          <ng-container [ngSwitch]="tbl">
            <div class="row" *ngSwitchCase="'phones'">
              <div class="col-4">
                <div class="row" *ngFor="let mapping of getTableFieldMappings(tbl)">
                  <div class="col-12 d-flex flex-row">
                    <span style="font-weight: bold;">{{fileSvc.displayText(mapping.from.name)}}:&nbsp;</span>
                    <span class="ellipsis" [title]="row[mapping.from.name]"
                          [ngClass]="{'highlight': !checkValueAgainstList(person.phones, 'phone_number', row[mapping.from.name])}">
                      {{row[mapping.from.name]}}
                    </span>
                    <div class="flex-grow-1"></div>
                    <button (click)="addPhone({phone_number: row[mapping.from.name]}, person.person_id)"
                            mat-icon-button class="sm-icon-btn">
                      <mat-icon>arrow_circle_right</mat-icon>
                    </button>
                  </div>
                </div>
              </div>
              <div class="col-8">
                <div *ngFor="let phone of person.phones">
                  <span>{{phone.phone_number | mask: '(000) 000-0000'}}</span>
                  <span *ngIf="phone.label">&nbsp;&nbsp;({{phone.label}}<span *ngIf="phone.primary_phone"> - Primary</span>)</span>
                </div>
              </div>
            </div>
            <div class="row" *ngSwitchCase="'email_addresses'">
              <div class="col-4">
                <div class="row" *ngFor="let mapping of getTableFieldMappings(tbl)">
                  <div class="col-12 d-flex flex-row">
                    <span style="font-weight: bold;">{{fileSvc.displayText(mapping.from.name)}}:&nbsp;</span>
                    <span class="ellipsis" [title]="row[mapping.from.name]"
                          [ngClass]="{'highlight': !checkValueAgainstList(person.email_addresses, 'email', row[mapping.from.name])}">
                      {{row[mapping.from.name]}}
                    </span>
                    <div class="flex-grow-1"></div>
                    <button (click)="addEmailAddress({email: row[mapping.from.name]})"
                            mat-icon-button class="sm-icon-btn">
                      <mat-icon>arrow_circle_right</mat-icon>
                    </button>
                  </div>
                </div>
              </div>
              <div class="col-8">
                <div *ngFor="let eaddress of person.email_addresses">
                  <a [href]="'mailto:' + eaddress.email">{{eaddress.email}}</a>
                  <span *ngIf="eaddress.label">&nbsp;({{eaddress.label}}<span *ngIf="eaddress.primary_email"> - Primary</span>)</span>
                </div>
                </div>
            </div>
            <div class="row" *ngSwitchCase="'addresses'">
              <div class="col-12">
                <div class="row" *ngFor="let sequence of getTableImportSequences(tbl)">
                  <div class="col-4">
                    <button mat-icon-button (click)="addAddress(sequence, row, person.person_id)">
                      <mat-icon>arrow_circle_right</mat-icon>
                    </button>
                    <div class="row" *ngFor="let mapping of sequence">
                      <div class="col-12 d-flex flex-row" 
                           *ngIf="mapping.to.COLUMN_NAME !== 'destination_table_primary_key'">
                        <span style="font-weight: bold;">{{fileSvc.displayText(mapping.from.name)}}:&nbsp;</span>
                        <span class="ellipsis"
                              [ngClass]="{'highlight': !checkValueAgainstList(person.addresses, mapping.to.COLUMN_NAME, row[mapping.from.name])}"
                              [title]="row[mapping.from.name]">{{row[mapping.from.name]}}</span>
                        <div class="flex-grow-1"></div>
                        <button class="sm-icon-btn" mat-icon-button [matMenuTriggerFor]="addressFieldUpdateMenu"><mat-icon>arrow_circle_right</mat-icon></button>
                        <mat-menu #addressFieldUpdateMenu>
                          <button mat-menu-item
                                  (click)="updateAddress(personAddress.address_id, createObject(mapping.to.COLUMN_NAME, row[mapping.from.name]))"
                                  [title]="'Replace ' + fileSvc.displayText(mapping.to.COLUMN_NAME) + ' ' + personAddress[mapping.to.COLUMN_NAME] + ' for ' + personAddress.address + ' with ' + row[mapping.from.name]"
                                  *ngFor="let personAddress of person.addresses">
                            Replace {{fileSvc.displayText(mapping.to.COLUMN_NAME)}} "{{personAddress[mapping.to.COLUMN_NAME]}}" with "{{row[mapping.from.name]}}" for {{personAddress.address}}
                          </button>
                        </mat-menu>
                      </div>
                    </div>
                  </div>
                  <div class="col-8" style="padding-top: 40px;">
                    <div *ngFor="let address of person.addresses">
                      <div *ngIf="address.label">
                        <b><u>{{address.label}}<span *ngIf="address.primary_address"> - Primary</span></u></b>
                        &nbsp;<a target="_blank" [href]="'https://www.google.com/maps/place/' + getFullAddress(address).replace(' ', '+')"><mat-icon style="font-size: 14px; height: 14px; width: 14px; line-height: 14px;">map</mat-icon></a>
                      </div>
                      <div *ngIf="address.address">{{address.address}}</div>
                      <div *ngIf="address.address">{{address.address2}}</div>
                      <div *ngIf="address.city || address.state || address.zip">
                        <span>{{address.city}}</span>
                        <span>{{(address.city && address.state)?", ":""}}</span>
                        <span>{{address.state}}</span>
                        <span>{{((address.city + address.state).length?" ":"") + address.zip}}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row" *ngSwitchCase="'donations'">
              <div class="col-4">
                <div class="row"
                     *ngFor="let sequence of getTableImportSequences(tbl); let seqIndex = index;"
                     style="border-bottom: solid 1px lightgray;">
                  <div class="col">
                    <div class="row" *ngFor="let mapping of sequence">
                      <div class="col" *ngIf="mapping.from.name !== 'destination_table_primary_key'">
                        <span style="font-weight: bold;">{{fileSvc.displayText(mapping.from.name)}}:&nbsp;</span>
                        <span [ngClass]="{'highlight': !checkDonations(person.donations, sequence, row)}">{{displayValue(mapping, row[mapping.from.name])}}</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-auto">
                     <button mat-icon-button (click)="addDonation(sequence)" class="sm-icon-btn">
                       <mat-icon>arrow_circle_right</mat-icon>
                    </button>
                  </div>
                </div>
              </div>
              <div class="col-8 overflow-auto">
                <app-donations-table [person]="person"></app-donations-table>
              </div>
            </div>
            <ng-container *ngSwitchDefault>
              <div class="row" *ngFor="let mapping of getTableFieldMappings(tbl)">
                <div class="col-4">
                  <div class="row">
                    <div class="col-12 d-flex flex-row">
                      <div class="flex-grow-1 flex-shrink-1">
                        <span style="font-weight: bold;">{{fileSvc.displayText(mapping.from.name)}}:&nbsp;</span>
                        <span [ngClass]="{'highlight': row[mapping.from.name] !== person[mapping.to.COLUMN_NAME]}">{{displayValue(mapping, row[mapping.from.name])}}</span>
                      </div>
                      <button mat-icon-button class="sm-icon-btn text-end"
                              (click)="updatePersonField(mapping.to.COLUMN_NAME, row[mapping.from.name])">
                        <mat-icon>arrow_circle_right</mat-icon>
                      </button>
                    </div>
                    <div class="col-auto" *ngIf="mapping.to.COLUMN_NAME === 'notes'" >
                      <button mat-icon-button class="sm-icon-btn" [matMenuTriggerFor]="notesMenu">
                        <mat-icon>arrow_circle_right</mat-icon>
                      </button>
                      <mat-menu #notesMenu="matMenu">
                        <button mat-menu-item (click)="addToNotes(row[mapping.from.name])">
                          <span>Add to Notes</span>
                        </button>
                        <button mat-menu-item (click)="replaceNotes(row[mapping.from.name])">
                          <span>Replace Notes</span>
                        </button>
                      </mat-menu>
                    </div>
                  </div>
                </div>
                <div class="col-8" [ngSwitch]="mapping.to.TABLE_NAME">
                  <span style="font-weight: bold;">{{fileSvc.displayText(mapping.to.COLUMN_NAME)}}:&nbsp;</span>
                  <span [ngClass]="{'highlight': row[mapping.from.name] !== person[mapping.to.COLUMN_NAME]}">{{person[mapping.to.COLUMN_NAME]}}</span>
                </div>
              </div>
            </ng-container>
          </ng-container>
        </div>
        <div class="row">
          <div class="col-4">
            <div *ngFor="let importRow of row._import_rows">
              <hr>
              <div *ngFor="let key of getObjectKeys(importRow)">
                <span style="font-weight: bold">{{key}}: </span><span>{{importRow[key]}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>