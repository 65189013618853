<div class="col-auto" *ngIf="authSvc.currentUser$ | async as user">
  <h1 style="margin: 0;">User Information</h1>
  <form [formGroup]="userForm">
    <div>
      <mat-form-field style="margin-right: 10px; width: 80px;">
        <mat-label>First Name</mat-label>
        <input matInput type="text" formControlName="first_name">
      </mat-form-field>
      <mat-form-field style="width: 80px;">
        <mat-label>Last Name</mat-label>
        <input matInput type="text" formControlName="last_name">
      </mat-form-field>
    </div>
    <div class="d-flex form-buttons">
      <button mat-flat-button color="warn"
              [disabled]="userForm.pristine" (click)="cancel()">
        <span>Cancel</span>
      </button>
      <button mat-flat-button color="primary"
              [disabled]="userForm.pristine" (click)="saveUser(user.user_id, userForm.value)">
        <span>Save</span>
      </button>
    </div>
  </form>
  <h1 style="margin: 20px 0 0;">Change Password</h1>
  <form [formGroup]="changePasswordForm">
    <div>
      <mat-form-field>
        <mat-label>Current Password</mat-label>
        <input matInput type="password" formControlName="currentPassword">
      </mat-form-field>
    </div>
    <div>
      <mat-form-field>
        <mat-label>New Password</mat-label>
        <input matInput type="password" formControlName="newPassword">
      </mat-form-field>
    </div>
    <div>
      <mat-form-field>
        <mat-label>Confirm New Password</mat-label>
        <input matInput type="password" formControlName="confirmNewPassword">
      </mat-form-field>
    </div>
    <div class="d-flex form-buttons">
      <button mat-flat-button color="primary" [disabled]="changePasswordForm.pristine"
              (click)="changePassword(user.user_id, changePasswordForm.value)">
        <span>Change Password</span>
      </button>
    </div>
  </form>
</div>