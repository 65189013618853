import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ContactLogsService {
  url: string = "https://crm.american-af.org:3000/contact-logs";

  constructor(private http: HttpClient) { }
  
  getContactLogs = (filter: any) => {
    return this.http.get(this.url, {params: filter});
  }
  createContactLog = (contactLog: any, recipients: number[], filter: any) => {
    let payload: any = {
      contactLog: contactLog,
      recipients: recipients,
      filter: filter
    }
    
    return this.http.post(this.url, payload);
  }
  deleteContactLogEntry = (id: number, filter: any) => {
    return this.http.post(this.url + "/delete", filter, {params: {id: id}});
  }
  update = (contactLog: any, filter: any) => {
    let payload: any = {
      contactLog: contactLog,
      filter: filter
    }
    
    return this.http.put(this.url, payload);
  }
  
  removeContactFromContactLog = (joinId: number, logId: number, filter: any) => {
    return this.http.post(this.url + "/remove-contact", filter, {params: {joinId: joinId, logId: logId}});
  }
}