import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { PeopleService } from '@modules/person-profiles/people.service';
import { DatetimefxService } from '@modules/utility-components/datetimefx.service';

@Component({
  selector: 'app-action-dialog',
  templateUrl: './action-dialog.component.html',
  styleUrls: ['./action-dialog.component.scss']
})
export class ActionDialogComponent implements OnInit {
  person: any = {};
  action: any = {};
  actionForm: FormGroup;
  viewMode: string = "view";
  statusOptions: any[] = ["Pending", "Assigned", "Complete"];

  constructor(@Inject(MAT_DIALOG_DATA) public entry: any,
              private fb: FormBuilder,
              private peopleSvc: PeopleService,
              public dialogRef: MatDialogRef<ActionDialogComponent>,
              private dtf: DatetimefxService) { }

  ngOnInit(): void {
    this.actionForm = this.fb.group({
      action_id: [],
      name: [''],
      status: [],
      action_lead: [],
      date_completed: [],
      notes: ['']
    });
    if (this.entry.viewMode) this.viewMode = this.entry.viewMode;
    if (typeof this.entry.person !== "undefined") this.person = this.entry.person;
    if (this.entry.action !== null) {
      if (Object.keys(this.entry.action).length > 0) {
        if (typeof this.entry.action.action_id !== "undefined") {
          this.actionForm.patchValue(this.entry.action);
          this.action = this.entry.action;
        }
      }
    }
  }
  
  createAction = (actionObj: any) => {
    actionObj.person_link = this.person.person_id;
    if (actionObj.date_completed) actionObj.date_completed = this.dtf.local_datetime(actionObj.date_completed);
    
    if (actionObj.action_id) {
      this.peopleSvc.updateAction(actionObj).subscribe((response: any) => this.dialogRef.close(response.result));
    } else {
      this.peopleSvc.createAction(actionObj).subscribe((response: any) => this.dialogRef.close(response.result));
    }
  }
}