<div fxFlexFill fxLayout="column" class="overflow-auto" *ngIf="filter$ | async as filter">
  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
    <div>
      <div>
        <span class="bold">Total Contact Log Items: </span>
        <span>{{totalRecords$ | async | number: '1.0'}}</span>
      </div>
      <div>
        <span class="bold">Contact Log Items Per Page: </span>
        <span [matMenuTriggerFor]="pageSizeMenu" class="pointer">{{filter.pageSize}}</span>
      </div>
      <mat-menu #pageSizeMenu="matMenu">
        <button mat-menu-item *ngFor="let pageSizeOption of pageSizeOptions"
                (click)="updatePageSize(pageSizeOption, filter)">
          <span>{{pageSizeOption}}</span>
        </button>
      </mat-menu>
    </div>
    <div fxFlex>
      
    </div>
    <div *ngIf="totalPages > 0" fxLayout="row" fxLayoutAlign="start center">
      <div>
        <span>Displaying Items {{filter.pageIndex * filter.pageSize + 1}} - </span>
        <span>{{(filter.pageIndex + 1) * filter.pageSize}}</span>
      </div>
      <div fxLayout="row" fxLayoutAlign="start center">
        <button mat-icon-button (click)="pageIncrement(filter, -1)"
                [disabled]="filter.pageIndex === 0">
          <mat-icon>chevron_left</mat-icon>
        </button>
        <span>Page {{filter.pageIndex + 1}} of {{totalPages}}</span>
        <button mat-icon-button (click)="pageIncrement(filter, 1)"
                [disabled]="filter.pageIndex >= (totalPages - 1)">
          <mat-icon>chevron_right</mat-icon>
        </button>
      </div>
    </div>
  </div>
  <table class="table table-hover table-sm" style="margin-bottom: 50px;"
         *ngIf="person$ | async as person">
    <thead>
      <tr>
        <th scope="col">
          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
            <span>Contact Log</span>
            <button mat-icon-button color="primary" class="sm-icon-btn"
                    [disabled]="!person.person_id"
                    (click)="showForm = !showForm">
              <mat-icon>{{showForm?'cancel':'add_circle'}}</mat-icon>
            </button>
          </div>
        </th>
        <th scope="col" class="text-center"><span>Date</span></th>
        <th scope="col" class="text-center"><span>Format</span></th>
        <th scope="col" class="text-center"><span>By</span></th>
        <th scope="col">
          <mat-icon title="Contact Log Actions" 
                    style="font-size: 16px; cursor: default;">assignment</mat-icon>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let clog of items$ | async">
        <td scope="row" class="pointer"
            (click)="contactLogDialog('view', person, clog)">
          <span>{{clog.title}}</span>
        </td>
        <td class="text-center"><span>{{clog.contact_datetime | date: 'shortDate'}}</span></td>
        <td class="text-center"><span>{{clog.contact_type}}</span></td>
        <td class="text-center"><span>{{clog.contacted_by}}</span></td>
        <td>
          <button mat-icon-button class="sm-icon-btn pointer"
                  [matMenuTriggerFor]="contactLogActionMenu">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu class="compact-menu" #contactLogActionMenu="matMenu">
            <button mat-menu-item (click)="edit(clog, person)">
              <span>Edit Log Entry</span>
            </button>
            <button mat-menu-item (click)="removeContactFromContactLog(person, clog.id, clog.contact_log_id, filter)">
              <span>Remove {{peopleSvc.getPersonName(person)}}</span>
            </button>
            <button mat-menu-item (click)="delete(clog, filter)">
              <span color="warn">Delete Log Entry</span>
            </button>
          </mat-menu>
        </td>
      </tr>
    </tbody>
  </table>
  <app-contact-log-form #contactLogForm [ngClass]="{'hide': !showForm}" [person]="person$" [filter]="filter$"
                        (updated-contact-log)="items$.next($event.items); totalRecords$.next($event.count)"
                        (close-form)="showForm = false;">
  </app-contact-log-form>
</div>