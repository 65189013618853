import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

import { BehaviorSubject, combineLatest, of } from 'rxjs';
import { filter, switchMap, tap } from 'rxjs/operators';

import * as moment from "moment";

import { RegionService } from '@modules/utility-components/region.service';
import { ListService } from '@modules/list-management/list.service';
import { FilterService } from '@modules/filter/filter.service';
import { UtilityService } from '@modules/utility-components/utility.service';
import { ConfigService } from '@modules/utility-components/config.service';
import { PeopleFilter, PeopleService } from '../people.service';

export class Filter {
  list = {};
  states = [];
  verified = null;
  email_muted = null;
}

@Component({
  selector: 'app-person-filter-list',
  templateUrl: './person-filter-list.component.html',
  styleUrls: ['./person-filter-list.component.scss']
})
export class PersonFilterListComponent implements OnInit {
  initialFilter: PeopleFilter = {
    lists: [],
    states: [],
    pageIndex: 0,
    pageSize: 40,
    orderBy: "last_name, first_name"
  }
  totalRecords: number = 0;
  
  filter$: BehaviorSubject<PeopleFilter> = new BehaviorSubject<PeopleFilter>(this.initialFilter);
  displayList$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  listItems$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  @Output('person-selected') personSelected: EventEmitter<any> = new EventEmitter<any>();

  constructor(private peopleSvc: PeopleService,
              public regionSvc: RegionService,
              public listSvc: ListService,
              private filterSvc: FilterService,
              private utilitySvc: UtilityService,
              public configSvc: ConfigService,
              public router: Router) { }

  ngOnInit(): void {
    this.listItems$.subscribe((listItems: any[]) => {
      listItems.sort((a,b) => {
        if (a.last_name === b.last_name) return a.first_name < b.first_name ? -1 : 1;
        return a.last_name < b.last_name ? -1 : 1;
      });
      
      this.displayList$.next(listItems);
    });
    
    this.filter$.pipe(
      switchMap((filter: any) => this.peopleSvc.getPeople(filter))
    ).subscribe((response: any) => {
      this.listItems$.next(response.people);
      this.totalRecords = response.count;
    });
    /*
    combineLatest(this.filter$, this.filterSvc.personListUpdate$).pipe(
      tap((result) => console.log("Start: ", result)),
      filter(([filter, personListUpdate]) => {
        return personListUpdate &&
        personListUpdate.person.addresses.map(address => address.state).filter(state => filter.states.includes(state)) &&
        personListUpdate.list.list_id === filter.list.list_id
      }))
      .subscribe((result) => {
      if (result[1].action === "add") {
        this.utilitySvc.addItemToArray(this.listItems$, result[1].person);
      } else if (result[1].action === "remove") {
        this.utilitySvc.removeItemFromArray(this.listItems$, result[1].person.person_id, "person_id");
      }
    });
    */
  }
  
  // convert formulaes to respond to filter$
  // create formula to filter lists here
  
  filterContainsList = (list: any, filter: PeopleFilter) => {
    return filter.lists.some((lst: any) => lst.list_id === list.list_id)
  }
  
  toggleState = (state: string, filter: PeopleFilter) => {
    let index = filter.states.indexOf(state);
    
    if (index === -1) {
      filter.states.push(state);
    } else {
      filter.states.splice(index, 1);
    }
    
    this.filter$.next(filter);
  }
  showAll = (filter: PeopleFilter) => {
    filter.lists = [];
    this.filter$.next(filter);
  }
  toggleList = (list: any, filter: PeopleFilter) => {
    if (this.filterContainsList(list, filter)) {
      filter.lists = filter.lists.filter((lst: any) => lst.list_id !== list.list_id);
    } else {
      filter.lists.push(list);
    }
    this.filter$.next(filter);
  }
  displayLists = (filter: PeopleFilter, delimiter?: string) => {
    delimiter = delimiter || ", ";
    return filter.lists.map((list: any) => list.list).join(delimiter);
  }
  
  clearFilter = (filter: PeopleFilter) => {
    filter.states = [];
    filter.lists = [];
    this.filter$.next(filter);
  }
  stateFilterDisplay = (stateFilter) => {
    return stateFilter.sort((a,b) => a>b?1:-1).join(", ");
  } 
  
  getPersonName = (personObj) => {
    if (personObj.first_name && personObj.last_name) return personObj.first_name + " " + personObj.last_name;
    if (personObj.first_name) return personObj.first_name;
    return personObj.last_name;
  }
  
  downloadCallList = () => {
    this.listSvc.downloadCallList().subscribe((blob: Blob) => {
      const a = document.createElement('a');
      const objectUrl = URL.createObjectURL(blob);
      a.href = objectUrl;
      a.download = "Call List - " + moment().format("YYYY-MM-DD") + ".xlsx";
      a.click();
      URL.revokeObjectURL(objectUrl);
    });
  }
  downloadEmailList = () => {
    this.listSvc.downloadEmailList().subscribe((blob: Blob) => {
      const a = document.createElement('a');
      const objectUrl = URL.createObjectURL(blob);
      a.href = objectUrl;
      a.download = "Email Notes List - " + moment().format("YYYY-MM-DD") + ".xlsx";
      a.click();
      URL.revokeObjectURL(objectUrl);
    });
  }
  downloadFilterList = (filter: PeopleFilter) => {
    this.listSvc.downloadFilterList(filter).subscribe((blob: Blob) => {
      let listName = this.displayLists(filter, "-");
      const a = document.createElement('a')
      const objectUrl = URL.createObjectURL(blob)
      a.href = objectUrl
      a.download = listName + " - " + moment().format("YYYY-MM-DD hh-mm-ss") + ".xlsx";
      a.click();
      URL.revokeObjectURL(objectUrl);
    });
  }
  downloadDonorList = (params?: any) => {
    let filename: string = (params.year?params.year + " ":"") + "AAF " + (params.donorsOnly?"Contributing":"All") + " Donor List - " + moment().format("YYYY-MM-DD") + ".xlsx";
    
    this.listSvc.downloadDonorList(params).subscribe((blob: Blob) => {
      const a = document.createElement('a');
      const objectUrl = URL.createObjectURL(blob);
      a.href = objectUrl;
      a.download = filename;
      a.click();
      URL.revokeObjectURL(objectUrl);
    });
  }
  
  changePage = (filter: PeopleFilter, increment: number) => {
    filter.pageIndex = filter.pageIndex + increment;
    this.filter$.next(filter);
  }
  changePageSize = (filter: PeopleFilter, pageSize: number) => {
    filter.pageSize = pageSize;
    this.filter$.next(filter);
  }
}