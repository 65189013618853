import { Component, OnInit, Input } from '@angular/core';

import { DialogService } from '@modules/dialogs/dialog.service';
import { DonationsService } from '../donations.service';

@Component({
  selector: 'app-donor-payment-table',
  templateUrl: './donor-payment-table.component.html',
  styleUrls: ['./donor-payment-table.component.scss']
})
export class DonorPaymentTableComponent implements OnInit {
  @Input('person') person: any = {};
  payment: any;

  constructor(private dialogSvc: DialogService,
              public donationSvc: DonationsService) { }

  ngOnInit(): void {
    
  }
  
  paymentDialog = (viewMode: string, person: any, payment?: any) => {
    this.dialogSvc.paymentDialog(viewMode, person, payment).subscribe((x: any) => {
      console.log(x);
    });
  }
  deletePayment = (paymentId: number) => {
    this.donationSvc.deletePayment(paymentId).subscribe((result: any) => {
      if (result.response === "OK") this.person.payments = this.person.payments.filter(el => el.payment_id !== paymentId);
    })
  }

}