import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { BehaviorSubject, combineLatest } from 'rxjs';
import { filter } from 'rxjs/operators';

import { FilesService } from '../files.service';
import { PeopleService } from '@modules/person-profiles/people.service';
import { Person } from '@modules/person-profiles/models/person.model';

@Component({
  selector: 'app-duplicate-list',
  templateUrl: './duplicate-list.component.html',
  styleUrls: ['./duplicate-list.component.scss']
})
export class DuplicateListComponent implements OnInit {
  @Input('import-sequence') importSequence$: BehaviorSubject<any> = new BehaviorSubject<any>({});
  @Input('selected-duplicate-row') selectedDuplicateRow$: BehaviorSubject<any> = new BehaviorSubject<any>({});
  @Input('selected-person') selectedPerson$: BehaviorSubject<any> = new BehaviorSubject<any>(new Person());
  @Input('duplicate-rows') duplicateRows$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  @Input('first-last-duplicates') firstLastDuplicates$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  @Input('last-duplicates') lastDuplicates$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  @Output('update-selected-person') updateSelectedPerson: EventEmitter<Person> = new EventEmitter<Person>();
  duplicateRowDisplay$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  selectedDuplicateIndex: number;
  hideDuplicates$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private fileSvc: FilesService,
              private peopleSvc: PeopleService) { }

  ngOnInit(): void {
    this.selectedDuplicateRow$.pipe(
      filter(dupRow => typeof dupRow !== "undefined")
    ).subscribe((dupRow: Person) => {
      console.log(dupRow);
      if (dupRow._duplicates.firstLast.length) {
        this.getPerson(dupRow._duplicates.firstLast[0].person_id);
      } else if (dupRow._duplicates.last.length) {
        this.getPerson(dupRow._duplicates.last[0].person_id);
      } else {
        this.updateSelectedPerson.emit(new Person());
      }
    });
    combineLatest(this.duplicateRows$, this.hideDuplicates$).subscribe(([rows, hide]) => {
      let data = rows.filter((row: any) => hide?row.sling_duplicate === 0:true);
      
      this.duplicateRowDisplay$.next(data);
      this.selectedDuplicateRow$.next(data[this.selectedDuplicateIndex]);
    });
    this.selectedPerson$.subscribe((person: any) => {
      
    })
  }
  
  getFromFieldName = (fieldMap: any[], toTableName: string, toFieldName: string) => {
    let map = fieldMap.map(arr => arr.fieldMappings).flat(1).find(fld => fld.to.TABLE_NAME === toTableName && fld.to.COLUMN_NAME === toFieldName);
    return map.from.name;
  }
  
  selectDuplicatePair = (dup: any, index: number) => {
    let importSeq: any[] = this.importSequence$.getValue(),
        first_name = this.fileSvc.getFromFieldName(importSeq, 'people', 'first_name'),
        last_name = this.fileSvc.getFromFieldName(importSeq, 'people', 'last_name');
    
    this.selectedDuplicateRow$.next(dup);
    this.selectedDuplicateIndex = index;
    this.firstLastDuplicates$.next(dup._duplicates.firstLast);
    this.lastDuplicates$.next(dup._duplicates.last);
    if (dup._duplicates.firstLast.length) {
      this.getPerson(dup._duplicates.firstLast[0].person_id);
    } else if (dup._duplicates.last.length) {
      this.getPerson(dup._duplicates.last[0].person_id);
    } else {
      this.updateSelectedPerson.emit(new Person());
    }
  }
  displayDuplicatePairs = (dup: any) => {
    let importSeq: any[] = this.importSequence$.getValue(),
        first_name = this.getFromFieldName(importSeq, 'people', 'first_name'),
        last_name = this.getFromFieldName(importSeq, 'people', 'last_name');
    return dup[first_name] + " " + dup[last_name];
  }
  
  getPerson = (personId: number) => {
    this.peopleSvc.getPerson(personId).subscribe((person: any) => {
      this.updateSelectedPerson.emit(person.result);
    });
  }
}