<table class="table table-hover table-sm">
  <thead>
    <tr>
      <th scope="col">User</th>
      <th scope="col">E-mail</th>
      <th scope="col">Group</th>
      <th scope="col">Approval Status</th>
      <th scope="col">Last Login</th>
      <th scope="col">Action</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let user of users$ | async">
      <td scope="row">{{user.first_name + ' ' + user.last_name}}</td>
      <td>{{user.email}}</td>
      <td [matMenuTriggerFor]="groupMenu" class="pointer"><span>{{user.user_group}}</span></td>
      <td>{{user.approved === 1? "Approved":"Unapproved"}}</td>
      <td>{{user.lastLogin}}</td>
      <td><mat-icon style="cursor: pointer; font-size: 18px;" [matMenuTriggerFor]="actionMenu">more_vert</mat-icon></td>
      <mat-menu class="compact-menu" #actionMenu="matMenu">
        <button mat-menu-item (click)="deleteUser(user.user_id)">
          <span>Delete User</span>
        </button>
        <button *ngIf="user.approved !== 1" mat-menu-item (click)="activateUser(user.user_id, 1)">
          <span>Activate User</span>
        </button>
        <button *ngIf="user.approved === 1" mat-menu-item (click)="activateUser(user.user_id, 0)">
          <span>Deactivate User</span>
        </button>
      </mat-menu>
      <mat-menu class="compact-menu" #groupMenu="matMenu">
        <div mat-menu-item>
          <span style="font-weight: bold; text-decoration: underline;">Set Group for {{user.first_name + ' ' + user.last_name}}</span>
        </div>
        <button *ngIf="userGroups.length === 0"
                mat-menu-item>
          <span>Loading User Groups...</span>
        </button>
        <button mat-menu-item *ngFor="let group of userGroups"
                (click)="updateUserGroup(user, group)">
          <span>{{group}}</span>
        </button>
      </mat-menu>
    </tr>
  </tbody>
</table>