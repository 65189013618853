<mat-paginator [length]="person.donations?.length"
               [pageSize]="tablePageSize"
               [pageSizeOptions]="[5]"
               (page)="pageEvent($event)">
</mat-paginator>
<table class="table table-hover table-sm overflow-auto w-100" style="max-width: 100%;">
  <thead>
    <tr>
      <th scope="col" style="text-align: center;"><span>Date</span></th>
      <th scope="col">
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
          <span>Donation Recipient</span>
          <button color="primary" mat-icon-button class="sm-icon-btn"
                  [disabled]="!person.person_id" (click)="donationDialog('edit', person)">
            <mat-icon>add_circle</mat-icon>
          </button>
        </div>
      </th>
      <th scope="col" class="text-end"><span>Amount Pledged</span></th>
      <th scope="col" class="text-end"><span>Amount Paid</span></th>
      <th><mat-icon>assignment</mat-icon></th>
    </tr>
  </thead>
  <tbody class="overflow-auto">
    <tr *ngFor="let donation of person.donations | slice: tablePageRowStart:tablePageRowEnd">
      <td style="text-align: center"><span>{{donation.date | date: 'shortDate'}}</span></td>
      <td scope="row" class="pointer" (click)="donationDialog('edit', person, donation)"><span class="ellipsis">{{donation.recipient}}</span></td>
      <td class="text-end"><span *ngIf="donation.amount_pledged">$</span><span>{{donation.amount_pledged | mask: 'separator':','}}</span></td>
      <td class="text-end"><span *ngIf="donation.amount_paid">$</span><span>{{donation.amount_paid | mask: 'separator':','}}</span></td>
      <td>
        <button mat-icon-button class="sm-icon-btn" [matMenuTriggerFor]="donationActionMenu">
          <mat-icon>more_vert</mat-icon>
        </button>
      </td>
      <mat-menu class="compact-menu" #donationActionMenu="matMenu">
        <button mat-menu-item (click)="deleteDonation(donation)">
          <mat-icon>delete</mat-icon><span>Delete Donation</span>
        </button>
        <button mat-menu-item (click)="donationDialog('edit', person, donation)">
          <mat-icon>edit</mat-icon><span>Edit Donation</span>
        </button>
      </mat-menu>
    </tr>
    <tr style="border-top: solid 2px black;">
      <td></td>
      <td class="text-end"><span style="font-weight: bold;">Donor Totals:</span></td>
      <td class="text-end"><span>${{sumArrayValues(person.donations, "amount_pledged") | mask: 'separator':','}}</span></td>
      <td class="text-end"><span>${{sumArrayValues(person.donations, "amount_paid") | mask: 'separator':','}}</span></td>
      <td class="text-end"><span>${{sumArrayValues(person.donations, "amount_pledged") - sumArrayValues(person.donations, "amount_paid") | mask: 'separator':','}}</span></td>    </tr>
  </tbody>
</table>