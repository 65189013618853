import { Component, OnInit } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

import { AuthService } from '@modules/auth/auth.service';
import { ConfigService } from '@modules/utility-components/config.service';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {
  users$: BehaviorSubject<any[]> = new BehaviorSubject([]);
  userGroups: any[];

  constructor(private authSvc: AuthService,
              public configSvc: ConfigService) { }
  
  deleteUser = (userId: number) => {
    this.authSvc.deleteUser(userId)
    .subscribe((resp: any) => this.users$.next(resp.users));
  }
  activateUser = (userId: number, status: string) => {
    this.authSvc.activateUser(userId, status)
    .subscribe(x => this.users$.next(x[0]));
  }
  
  updateUser = (user: any) => {
    this.authSvc.saveUser(user.user_id, user).subscribe((response: any) => {
      console.log(response);
    })
  }
  updateUserGroup = (user: any, group: string) => {
    user.user_group = group;
    this.updateUser(user);
  }

  ngOnInit(): void {
    this.authSvc.getUsers().subscribe((x: any) => this.users$.next(x.users));
    this.configSvc.settings.subscribe((settings: any) => this.userGroups = settings.userGroups);
  }

}