import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { PeopleService } from '@modules/person-profiles/people.service';
import { DialogService } from '@modules/dialogs/dialog.service';
import { UtilityService } from '@modules/utility-components/utility.service';

@Component({
  selector: 'app-action-table',
  templateUrl: './action-table.component.html',
  styleUrls: ['./action-table.component.scss']
})
export class ActionTableComponent implements OnInit {
  @Input() person: any = {};
  @Output() updatePerson: EventEmitter<any> = new EventEmitter<any>();
  tablePageSize: number = 5;
  tablePageIndex: number = 0;
  tablePageRowStart: number = this.tablePageIndex * this.tablePageSize;
  tablePageRowEnd: number = this.tablePageRowStart + this.tablePageSize;

  constructor(public peopleSvc: PeopleService,
              private dialogSvc: DialogService,
              private utilitySvc: UtilityService) { }

  ngOnInit(): void {
    
  }
  
  actionDialog = (viewMode, person?, action?) => {
    this.dialogSvc.actionDialog(viewMode, person, action)
    .subscribe(x => {
      if (x.action_id) {
        let index = this.person.actions.findIndex(act => act.action_id === x.action_id);
        if (index > -1) {
          this.person.actions[index] = x;
        } else {
          this.person.actions.push(x);
        }
        this.updatePerson.emit(person);
      }
    });
  }
  updateActionStatus = (updateActionObj) => {
    this.peopleSvc.updateAction(updateActionObj)
    .subscribe((x: any) => {
      if (x.result.action_id) {
        this.utilitySvc.addItem(this.person.actions, x.result, "action_id");
        this.person.actions = this.person.actions.sort((a,b) => (new Date(a.date_completed) > new Date(b.date_completed)?-1:1));
        this.updatePerson.emit(this.person);
      }
    });
  }
  pageEvent = (event) => {
    console.log(event);
    this.tablePageIndex = event.pageIndex;
    this.tablePageRowStart = this.tablePageIndex * this.tablePageSize;
    this.tablePageRowEnd = this.tablePageRowStart + this.tablePageSize;
  }
}