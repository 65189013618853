<input type="file" class="file-input" (change)="onFileSelected($event)" #fileUpload>

<div class="file-upload">
  <span>{{fileName || "No file uploaded yet."}}</span>
  <button mat-flat-button color="primary" class="upload-btn" (click)="fileUpload.click()">
    <span>Upload File</span>
  </button>
</div>
<div class="progress">
  <mat-progress-bar class="progress-bar" mode="determinate" [value]="uploadProgress"
                    *ngIf="uploadProgress" style="width: 200px"></mat-progress-bar>
  <mat-icon class="cancel-upload" (click)="cancelUpload()" *ngIf="uploadProgress">delete_forever</mat-icon>
</div>