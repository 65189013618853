import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { HttpClient, HttpEvent, HttpResponse } from '@angular/common/http';
import { FormGroup, FormBuilder } from '@angular/forms';
import { COMMA, ENTER, SEMICOLON } from '@angular/cdk/keycodes';
import { MatDialog } from '@angular/material/dialog';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition, } from '@angular/material/snack-bar';

import { Observable, BehaviorSubject, of } from 'rxjs';
import { startWith, debounceTime, switchMap, mergeMap, tap, map } from 'rxjs/operators';
import { AngularEditorConfig, AngularEditorComponent } from '@kolkov/angular-editor';

import { EmailService } from '../email.service';
import { EmailPreviewDialogComponent } from '../email-preview-dialog/email-preview-dialog.component';
import { SaveEmailTemplateDialogComponent } from '../save-email-template-dialog/save-email-template-dialog.component';
import { OpenEmailDialogComponent } from '../open-email-dialog/open-email-dialog.component';

export interface UploadResponse {
    imageUrl: string;
}

@Component({
  selector: 'app-email-editor',
  templateUrl: './email-editor.component.html',
  styleUrls: ['./email-editor.component.scss']
})
export class EmailEditorComponent implements OnInit {
  @ViewChild("recipientInput") recInput: ElementRef;
  @ViewChild("editor") editor: AngularEditorComponent;
  emailForm: FormGroup;
  toSearch$: BehaviorSubject<string> = new BehaviorSubject("");
  fromOptions: any[] = [
    {name: '"Russ Vought, President"', email: "info@americarenewing.com"},
    {name: '"Wade Miller, Executive Director"', email: "info@americarenewing.com"},
    {name: '"Andrew Heim, Development"', email: "info@citizensamericarenewing.com"},
    {name: '"Ashlea Frazier, Chief Operating Officer"', email: "info@americarenewing.com"},
    {name: '"Rachel Semmel, Communications Director"', email: "info@americarenewing.com"},
  ];
  replyToOptions: any[] = [
    {name: "Wade Miller", email: "wade@citizensrenewingamerica.com"},
    {name: "Ashlea Frazier", email: "ashlea@renewingamerica.com"},
    {name: "Andrew Heim", email: "andrew@renewingamerica.com"},
    {name: "Rachel Semmel", email: "rachel@renewingamerica.com"},
    {name: "Edie Heiple", email: "edie@renewingamerica.com"},
  ]
  searchResults$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  separatorKeysCodes: number[] = [ENTER, COMMA, SEMICOLON];
  recipientInput$: BehaviorSubject<string> = new BehaviorSubject<string>("");
  recentEmailTemplates$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  organization: string = "Citizens";

  // HTML VARIABLES ******************************************************************************************
  topSet: string = `<table style="border-collapse:collapse;height:100%;margin:0;padding:0;width:100%" width="100%" height="100%" cellspacing="0" cellpadding="0" border="0" align="center"><tbody>`;
  bottomSet: string = `</tbody></table>`;
  header: string = `<tr>
    <td style="background:#ffffff none no-repeat 50% 50%/cover;background-color:#ffffff;background-image:none;background-repeat:no-repeat;background-position:50% 50%;background-size:cover;border-top:0;border-bottom:0;padding-top:3px;padding-bottom:3px" valign="top" align="center">
      <img alt="" style="max-width:869px;padding-bottom:0;display:inline!important;vertical-align:bottom;border:0;height:auto;outline:none;text-decoration:none" src="assets/email/cra_logo.png" width="180.48" align="middle" class="CToWUd a6T" tabindex="0">
      <br>
    </td></tr>`;
  bodyTop: string = `<tr><td style="background:#ffffff none no-repeat center/cover;background-color:#ffffff;background-image:none;background-repeat:no-repeat;background-position:center;background-size:cover;border-top:0;border-bottom:0;padding-top:0px;padding-bottom:0px" valign="top" align="center"><table style="border-collapse:collapse;" width="100%" cellspacing="0" cellpadding="0" border="0" align="center"><tbody><tr><td style="padding:9px 18px 9px;color:#000000;font-style:normal;font-weight:normal;word-break:break-word;font-family:Helvetica;font-size:16px;line-height:150%;text-align:left" valign="top">`;
  body: string = ``;
  bodyBottom: string = `</td></tr></tbody></table></td></tr>`;
  footer: string = `<tr>
        <td style="background:#ffffff none no-repeat center/cover;background-color:#ffffff;background-image:none;background-repeat:no-repeat;background-position:center;background-size:cover;border-top:0;border-bottom:0;padding-top:0px;padding-bottom:0px" valign="top" align="center">
        <table style="border-collapse:collapse;max-width:450px!important" width="100%" cellspacing="0" cellpadding="0" border="0" align="center">
          <tbody><tr>
            <td width="24" valign="middle" align="center" style="padding-top: 20px;"><a rel="noreferrer nofollow noopener" href="https://www.facebook.com/amrenewcenter" target="_blank"><img alt="Facebook" style="display:block;border:0;height:auto;outline:none;text-decoration:none" src="assets/email/facebook.png" width="24" height="24" class="CToWUd"></a><br></td>
            <td width="24" valign="middle" align="center" style="padding-top: 20px;"><a rel="noreferrer nofollow noopener" href="http://www.twitter.com/" target="_blank"><img alt="Twitter" style="display:block;border:0;height:auto;outline:none;text-decoration:none" src="assets/email/twitter.png" width="24" height="24" class="CToWUd"></a><br></td>
            <td width="24" valign="middle" align="center" style="padding-top: 20px;"><a rel="noreferrer nofollow noopener" href="https://americarenewing.com/" target="_blank" ><img alt="Website" style="display:block;border:0;height:auto;outline:none;text-decoration:none" src="assets/email/link.png" width="24" height="24" class="CToWUd"></a><br></td>
          </tr>
          <tr>
            <td style="padding-top:9px" valign="top" colspan="4">
              <table style="max-width:100%;min-width:100%;border-collapse:collapse" width="100%" cellspacing="0" cellpadding="0" border="0" align="left">
                <tbody><tr>
                  <td style="padding-top:0;padding-right:18px;padding-bottom:9px;padding-left:18px;word-break:break-word;color:black;font-family:Helvetica;font-size:12px;line-height:150%;text-align:center" valign="top"><div><i>Copyright © 2022 ${this.organization} for Renewing America, All rights reserved.</i>
                    <br></div><div>
                    <br></div><div><b>Our mailing address is:</b>
                    <br></div><div>300 Independence Ave. SE
                    <br></div><div>Washington,&nbsp;DC&nbsp;20003
                    <br></div><div><br></div><div>Want to change how you receive these emails?
                    <br></div><div>You can <a rel="noreferrer nofollow noopener" href="https://americanrestorationcenter.us1.list-manage.com/profile?u=10a708d6740341bfdc2a52a46&amp;id=340a9f3c7d&amp;e=__test_email__&amp;c=f66e7b1901" style="color:black;font-weight:normal;text-decoration:underline" target="_blank" data-saferedirecturl="https://www.google.com/url?q=https://americanrestorationcenter.us1.list-manage.com/profile?u%3D10a708d6740341bfdc2a52a46%26id%3D340a9f3c7d%26e%3D__test_email__%26c%3Df66e7b1901&amp;source=gmail&amp;ust=1627074728352000&amp;usg=AFQjCNFUe-RbEZi9Dm1aRvt8S2bgQqoKrQ">update your preferences</a> or <a rel="noreferrer nofollow noopener" href="https://americanrestorationcenter.us1.list-manage.com/unsubscribe?u=10a708d6740341bfdc2a52a46&amp;id=340a9f3c7d&amp;e=__test_email__&amp;c=f66e7b1901" style="color:black;font-weight:normal;text-decoration:underline" target="_blank" data-saferedirecturl="https://www.google.com/url?q=https://americanrestorationcenter.us1.list-manage.com/unsubscribe?u%3D10a708d6740341bfdc2a52a46%26id%3D340a9f3c7d%26e%3D__test_email__%26c%3Df66e7b1901&amp;source=gmail&amp;ust=1627074728352000&amp;usg=AFQjCNE1U87u_Vlvqs1bpHHD5sI2PlrgNA">unsubscribe from this list</a>.
                    <br></div><div> &nbsp;<br></div>
                  </td>
                </tr></tbody>
              </table>
            </td>
          </tr></tbody>
        </table>
      </td>
    </tr>`;
  htmlContent: string = this.topSet + this.header + this.bodyTop + this.body + this.bodyBottom + this.footer + this.bottomSet;
  recipients: any[] = [];

  constructor(private fb: FormBuilder,
              private emailSvc: EmailService,
              public dialog: MatDialog,
              private _snackBar: MatSnackBar,
              private http: HttpClient) { }

  ngOnInit(): void {
   // this.emailSvc.getRecentEmailTemplates().subscribe((response: any[]) => this.emailSvc.emailTemplates$.next(response));
    this.emailForm = this.fb.group({
      email_id: [],
      email_version_id: [],
      version: [0],
      name: [''],
      sender: [''],
      replyTo: ['info@renewingamerica.com'],
      to: [''],
      subject: [''],
      html_header: [this.topSet + this.header + this.bodyTop],
      html_content: [this.body],
      html_footer: [this.bodyBottom + this.footer + this.bottomSet]
    });
    this.recipientInput$.pipe(
      startWith(''),
      debounceTime(300),
      switchMap(value => {
        if (value && typeof value === "string") {
          return this.emailSvc.searchEmailRecipients(value.trim());
        } else {
          return of([]);
        }
      }))
    .subscribe((results: any[]) => this.searchResults$.next(results));
  }
  
  fileSelected = (event) => {
    let file = event.target.files[0],
        fileName = file.name;

    const formData = new FormData();
    formData.append("file", file, file.name);
    this.http.post<UploadResponse>("http://slingofdavid.com:3000/upload-email-image", formData, {observe: "events", reportProgress: true, withCredentials: false}).subscribe(response => {
      console.log(response);
   //   if (response.type === 4) this.editor.executeCommand('insertImage', response.body.imageUrl);
    })
  }
  consoleLog = (obj) => {
    console.log(obj);
  }
  
  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '100%',
    minHeight: '0',
    maxHeight: '100%',
    width: 'auto',
    minWidth: '0',
    translate: 'no',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter text here...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
      {class: 'arial', name: 'Arial'},
      {class: 'times-new-roman', name: 'Times New Roman'},
      {class: 'calibri', name: 'Calibri'},
      {class: 'comic-sans-ms', name: 'Comic Sans MS'}
    ],
    customClasses: [],
    uploadUrl: 'http://slingofdavid.com:3000/upload-email-image',
    // upload: this.onFileSelected,
    uploadWithCredentials: false,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      [],
      ["imageUpload"]
    ]
  };
  
  // RECIPIENT FUNCTIONS **************************************************************************************
  validateEmailAddress = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }
  recipientKeyup = (ev) => {
    if (this.validateEmailAddress(ev.target.value)) {
      console.log("VALID EMAIL: ", ev.target.value);
    } else {
      console.log("INVALID EMAIL: ", ev.target.value);
    }
  }
  recipientOptionSelected = (ev) => {
    this.emailForm.controls['to'].value.push(ev.option.value);
    this.recInput.nativeElement.value = "";
  }
  displayResult = (result: any) => {
    if (result.type === "person") return result.first_name + " " + result.last_name + " <" + result.email + ">";
    if (result.type === "list") return result.first_name;
  }
  addRecipientFromInput = (event: any) => {
    let person = event.option.value;
    
    console.log(event.option.value);
    console.log(this.recInput.nativeElement.value);
    if (event.option.value) {
      this.recipients.push(person);
      this.recInput.nativeElement.value = "";
    }
  }
  removeRecipient = (index: number) => {
    this.recipients.splice(index, 1);
  }
  
  // EMAIL FUNCTIONS ******************************************************************************************
  getRecentEmailTemplates = () => {
    
  }
  launchEmailPreviewDialog = (emailObj) => {
    emailObj.html = this.topSet + this.header + this.bodyTop + this.emailForm.controls['html'].value + this.bodyBottom + this.footer + this.bottomSet;
    let dialogRef = this.dialog.open(EmailPreviewDialogComponent, {data: emailObj});
  }
  
  // SAVE EMAIL FUNCTIONS *************************************************************************************
  saveEmail = (emailObj) => {
    console.log("Email Obj: ", emailObj);
  }
  saveEmailAs = (emailObj) => {
    console.log("Email Save As Obj: ", emailObj);
  }
  saveEmailTemplate = (emailObj) => {
    let defaultEmailTemplateName = (emailObj.subject + " Email").trim() + (emailObj.from?" from " + emailObj.from.split("<")[0].trim():"") + " - " + (new Date()).toLocaleString();

    const dialogRef = this.dialog.open(SaveEmailTemplateDialogComponent, {data: {name: defaultEmailTemplateName}});
    
    dialogRef.afterClosed().pipe((
      mergeMap((dialogResult) => {
        console.log(emailObj);
        emailObj.name = dialogResult;
        console.log(emailObj);
        return this.emailSvc.saveEmailTemplate(emailObj)
      })
    )).subscribe(result => {
      console.log("Dialog result: ", result);
    });
  }
  openEmailTemplateDialog = () => {
    const dialogRef = this.dialog.open(OpenEmailDialogComponent);
    
    dialogRef.afterClosed().subscribe((emailTemplateObj: any) => {
      if (emailTemplateObj) this.emailForm.patchValue(emailTemplateObj);
    });
  }
  logEmailObject = (emailObj) => {
    console.log("Email Object:", emailObj);
  }
  sendEmail = (emailObj) => {
    emailObj.recipients = this.recipients;
    emailObj.html = this.topSet + this.header + this.bodyTop + emailObj.html_content + this.bodyBottom + this.footer + this.bottomSet;
    this.emailSvc.sendEmail(emailObj).subscribe((response: any) => {
      let snackBarRef = this._snackBar.open(`Email sent to ${response.recipients} recipient${response.recipeints > 1?'s':''}!`, "OK", {
        horizontalPosition: 'center',
        verticalPosition: 'top',
        duration: 3000
      });
      snackBarRef.afterDismissed().subscribe(() => {
        this.emailForm.reset();
        this.recipients = [];
      });
    });
  }
}