import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';

import { Subject, of } from 'rxjs';
import { startWith, debounceTime, switchMap, map } from 'rxjs/operators';

import { CorrespondenceService } from '@modules/person-profiles/correspondence.service';
import { PeopleService } from '@modules/person-profiles/people.service';
import { Person } from '@modules/person-profiles/models/person.model';

@Component({
  selector: 'app-correspondence-dialog',
  templateUrl: './correspondence-dialog.component.html',
  styleUrls: ['./correspondence-dialog.component.scss']
})
export class CorrespondenceDialogComponent implements OnInit {
  searchQuery: string = "";
  searchQueryChanges: Subject<string> = new Subject<string>();
  recipients: any[] = [];
  newRecipientForm: FormGroup;
  searchResults: Person[] = [];
  correspondenceForm: FormGroup;

  constructor(private fb: FormBuilder,
              private correspondenceSvc: CorrespondenceService,
              public peopleSvc: PeopleService) { }

  ngOnInit(): void {
    this.newRecipientForm = this.fb.group({
      recipientSearch: ['']
    });
    this.correspondenceForm = this.fb.group({
      correspondence_id: [],
      title: [''],
      date_sent: [],
      envelope_title: [],
      address: [''],
      address2: [''],
      city: [''],
      state: [''],
      zip: [''],
      content: [''],
      note: [''],
      date_created: [],
      user_created: [''],
      recipients: []
    });
    this.peopleSvc.searchControl(this.newRecipientForm.controls['recipientSearch'])
    .subscribe((x: any) => this.searchResults = x.result[0]);
    this.searchQueryChanges.pipe(
      startWith(''),
      debounceTime(300),
      switchMap(value => {
        if (value && typeof value === "string") {
          return this.peopleSvc.searchAll(value.trim());
        } else {
          return of({result: []});
        }
      }),
      map((result: any) => result.result[0]))
      .subscribe((results: Person[]) => this.searchResults = results);
  }
  
  saveCorrespondence = (correspondenceObj) => {
    let linkObj = this.recipients.map(re => re.person_id);
    
    // this.correspondenceSvc.createCorrespondence(correspondenceObj, linkObj)
    // .subscribe((x: any) => console.log(x));
    
    console.log(correspondenceObj);
  }

  // RECIPIENT FUNCTIONS *********************************************************
  addRecipient = (event) => {
    this.recipients.push(event.option.value);
    this.searchQuery = "";
    console.log(this.searchQuery);
  }
  removeRecipient = (index) => {
    this.recipients.splice(index, 1);
  }
  
  displayNull = () => {
    return null;
  }
  onInputChange = (text: string) => {
    this.searchQueryChanges.next(text);
  }

}