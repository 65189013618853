import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { FormGroup, FormBuilder } from '@angular/forms';

import { BehaviorSubject } from 'rxjs';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';

import { PeopleService } from '@modules/person-profiles/people.service';
import { RegionService } from '@modules/utility-components/region.service';
import { UtilityService } from '@modules/utility-components/utility.service';
import { ListService } from '@modules/list-management/list.service';

@Component({
  selector: 'app-activists',
  templateUrl: './activists.component.html',
  styleUrls: ['./activists.component.scss']
})
export class ActivistsComponent implements OnInit {
  selActivist$: BehaviorSubject<any> = new BehaviorSubject<any>({});
  filterVerify: boolean = false;
  filterCallList: boolean = false;
  filterDonor: boolean = false;
  stateListFilter: String[] = [];
  filter: any = {};
  
  // LIST VARIABLES *********************************************************
  personList: BehaviorSubject<any[]> = new BehaviorSubject([]);
  listSearchText: string = "";
  listDropdown: BehaviorSubject<any[]> = new BehaviorSubject([]);
  selectedList: BehaviorSubject<any> = new BehaviorSubject({});
  selectListView: boolean = true;
  personForm: FormGroup;
  personDropdown: BehaviorSubject<any[]>  = new BehaviorSubject([]);
  
  // NGIF VARIABLES *********************************************************
  showXsList: boolean = false;
  showFullList: boolean = false;

  constructor(public peopleSvc: PeopleService,
              public regionSvc: RegionService,
              private _snackBar: MatSnackBar,
              private route: ActivatedRoute,
              private router: Router,
              public utilitySvc: UtilityService,
              public listSvc: ListService,
              private fb: FormBuilder,
              ) { }

  getPerson = (personId) => {
    this.peopleSvc.getPerson(personId)
    .subscribe((person: any) => {
      this.selActivist$.next(person.result);
      this.showXsList = false;
    });
  }
  selectPerson = (personId) => {
    this.router.navigate([`/activists/${personId}`]);
  }
  newPerson = () => {
    this.router.navigate([`/activists/new`]);
  }
  
  getPersonName = (personObj) => {
    let result = "";
    
    if (personObj) {
      if (personObj.first_name) result = personObj.first_name;
      if (personObj.last_name) result += " " + personObj.last_name;
    }
    return result.trim();
  }
  toggleStateListFilter = (stateAbbrev) => {
    if (this.stateListFilter.indexOf(stateAbbrev) === -1) {
      this.stateListFilter.push(stateAbbrev);
    } else {
      this.stateListFilter.splice(this.stateListFilter.indexOf(stateAbbrev),1);
    }
  }
  runFilter = () => {
    
  }
  clearStateFilter = () => {
    this.stateListFilter = [];
  }
  
  // LIST FUNCTIONS *********************************************************
  selectList = (ev) => {
    this.selectedList.next(ev.option.value);
    this.selectListView = false;
  }
  filterLists = () => {
    this.listDropdown.next(this.listSvc.lists.getValue().filter(lst => {
      return (this.listSearchText.length?lst.list.toLowerCase().indexOf(this.listSearchText.toLowerCase()) > -1:true);
    }));
  }
  displayListName = (list) => {
    return list.list;
  }
  
  ngOnInit(): void {
    this.route.data.subscribe(data => this.selActivist$.next(data.person));
    this.listSvc.lists.subscribe(lsts => this.listDropdown.next(lsts));
    this.personForm = this.fb.group({
      personNameSearch: ['']
    });
    this.peopleSvc.searchControl(this.personForm.controls["personNameSearch"]).subscribe((results: any) => this.personDropdown.next(results.result[0]));
  }

}