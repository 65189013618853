<div class="container"  *ngIf="stateFilter$ | async as stateFilter">
  <div class="row">
    <div class="col-12 d-flex flex-row align-content-center justify-content-center"
         [formGroup]="filterForm">
      <mat-form-field appearance="fill">
        <mat-label>Name Search</mat-label>
        <input matInput type="text" formControlName="name">
        <button matSuffix mat-icon-button (click)="filterForm.get('name').reset()">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>Set Donation Range</mat-label>
        <mat-date-range-input [rangePicker]="picker">
          <input matStartDate formControlName="start" placeholder="Start date">
          <input matEndDate formControlName="end" placeholder="End date">
        </mat-date-range-input>
        <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
        <mat-error *ngIf="filterForm.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
        <mat-error *ngIf="filterForm.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
      </mat-form-field>
      <mat-form-field class="nudge" appearance="fill">
        <mat-label>Min Donation</mat-label>
        <input matInput #from type="number" formControlName="from" [max]="to.value">
      </mat-form-field>
      <mat-form-field class="nudge" appearance="fill">
        <mat-label>Max Donation</mat-label>
        <input matInput #to type="number" formControlName="to" [min]="from.value">
      </mat-form-field>
      <button mat-icon-button color="primary" title="Show State Filter"
              (click)="showStateFilter = !showStateFilter">
        <mat-icon>public</mat-icon>
      </button>
      <button mat-icon-button color="warn" title="Clear All Filters"
              (click)="stateFilter$.next([]); filterForm.reset();">
        <mat-icon>close</mat-icon>
      </button>
      <button mat-icon-button color="primary" (click)="downloadReport()">
        <mat-icon>download</mat-icon>
      </button>
    </div>
  </div>
  <div class="row">
    <div class="col-6 d-flex justify-content-end align-items-center">
      <div class="state-filter-list">
        <span>{{stateFilter.join(", ")}}</span>
      </div>
      <button *ngIf="stateFilter.length > 0"
              mat-icon-button color="warn" title="Clear State Filter"
              (click)="stateFilter$.next([])">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <div class="d-flex flex-row flex-wrap state-filter" *ngIf="showStateFilter">
        <div class="tag" (click)="toggleState(state, stateFilter)"
             [ngClass]="{'selected': stateFilter.includes(state.abbreviation)}"
             *ngFor="let state of regionSvc.states">
          <span>{{state.abbreviation}}</span>
        </div>
      </div>
    </div>
  </div>
  <table class="table table-hover table-sm">
    <thead>
      <tr>
        <th *ngFor="let col of columns$ | async">
          <span>{{col.label}}</span>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let row of data$ | async" class="pointer"
          [routerLink]="['/activists/' + row.person_id]">
        <td *ngFor="let col of columns$ | async"
            [style.text-align]="col.align">
          <span>{{row[col.value]}}</span>
        </td>
      </tr>
    </tbody>
  </table>
</div>