import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { UtilityService } from '@modules/utility-components/utility.service';
import { DonationsService } from '@modules/donor-management/donations.service';
import { DatetimefxService } from '@modules/utility-components/datetimefx.service';
import { PeopleService } from '@modules/person-profiles/people.service';

@Component({
  selector: 'app-donation-dialog',
  templateUrl: './donation-dialog.component.html',
  styleUrls: ['./donation-dialog.component.scss']
})
export class DonationDialogComponent implements OnInit {
  donation: any = {};
  donationForm: FormGroup;
  defaultRecipient: string;
  viewMode: string = "edit";
  donorSearchText: string = "";
  searchResults = [];
  
  constructor(@Inject(MAT_DIALOG_DATA) public entry: any,
              private fb: FormBuilder,
              public donationSvc: DonationsService,
              private dtf: DatetimefxService,
              public dialogRef: MatDialogRef<DonationDialogComponent>,
              public utilitySvc: UtilityService,
              private peopleSvc: PeopleService
              ) { }
  
  autoDisplay = (personObj) => {
    if (typeof this.entry.donation !== "undefined" && this.entry.donation !== null) {
      if (personObj === this.entry.donation.person_id) return this.utilitySvc.getPersonName(this.entry.donation);
    }
    return this.utilitySvc.getPersonName(personObj);
  }
  saveDonation = (donationObj) => {
    if (donationObj.amount_pledged === "") donationObj.amount_pledged = null;
    if (donationObj.amount_paid === "") donationObj.amount_paid = null;
    donationObj.date = this.dtf.mysql_date(donationObj.date);
    if (typeof donationObj.contact_link === "object" && donationObj.contact_link.person_id > 0) donationObj.contact_link = donationObj.contact_link.person_id;
    this.donationSvc.saveDonation(donationObj).subscribe(resp => this.dialogRef.close(resp));
  }

  ngOnInit(): void {
    this.donationForm = this.fb.group({
      donation_id: [],
      recipient: [this.donationSvc.defaultRecipient],
      amount_pledged: [],
      amount_paid: [],
      date: [new Date()],
      notes: [],
      contact_link: [this.entry.person]
    });
    this.peopleSvc.searchControl(this.donationForm.controls["contact_link"])
    .subscribe(x => this.searchResults = x.result[0]);
    if (this.entry.viewMode) this.viewMode = this.entry.viewMode;
    if (typeof this.entry.person !== "undefined") {
      if (this.entry.person !== null) this.entry.donation.contact_link = this.entry.person;
    }
    if (this.entry.donation !== null) {
      if (Object.keys(this.entry.donation).length > 0) {
        if (typeof this.entry.donation.donation_id !== "undefined") {
          this.donationForm.patchValue(this.entry.donation);
          this.donation = this.entry.donation;
        }
      }
    }
  }
}