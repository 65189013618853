import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Subject } from 'rxjs';
import { filter } from 'rxjs/operators';

import { ConfigService } from '@modules/utility-components/config.service';

@Injectable({
  providedIn: 'root'
})
export class FilterService {
  url: string;
  personListUpdate$: Subject<any> = new Subject<any>();

  constructor(private http: HttpClient,
              private configSvc: ConfigService) {
    this.configSvc.settings.pipe(
      filter((settings) => settings.apiUrl)
    ).subscribe((settings: any) => this.url = settings.apiUrl);
  }
  
  getFilteredPeople = (filterObj: any) => {
    return this.http.post(this.url + "people-filter", filterObj);
  }
}
