import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BehaviorSubject } from 'rxjs';

import { Email } from '@modules/email-editor/models/email.model';

@Injectable({
  providedIn: 'root'
})
export class EmailService {
  domain: string = "http://slingofdavid.com";
  port: string = "3000";
  url: string = this.domain + ":" + this.port + "/";
  emails: Email[] = [];
  emailTemplates$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);

  constructor(private http: HttpClient) { }
  
  getEmailTemplate = (emailTemplateId: number) => {
    return this.http.get(this.url + "email-template/" + emailTemplateId);
  }
  getRecentEmailTemplates = (limit?: number) => {
    limit = limit || 10;
    return this.http.get(this.url + "recently-saved-email-templates");
  }
  checkEmailTemplateName = (name: string) => {
    return this.http.get<any>(this.url + "check-email-template-name/" + encodeURIComponent(name));
  }
  searchEmailRecipients = (text: string) => {
    return this.http.get(this.url + "search-recipients/" + encodeURIComponent(text));
  }
  
  saveEmailTemplate = (emailTemplateObj: any) => {
    return this.http.post(this.url + "email-template", {emailTemplateObj: emailTemplateObj});
  }
  
  sendEmail = (emailObj) => {
    return this.http.post(this.url + "send-bulk-email", emailObj);
  }
  
}