import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-relationship-map',
  templateUrl: './relationship-map.component.html',
  styleUrls: ['./relationship-map.component.scss']
})
export class RelationshipMapComponent implements OnInit {
  @Input('relationships') relationships: any[] = [];

  constructor() { }

  ngOnInit(): void {
  }

}