import { Component, OnInit } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-donor-frame',
  templateUrl: './donor-frame.component.html',
  styleUrls: ['./donor-frame.component.scss']
})
export class DonorFrameComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    
  }
  
  
}