import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { Payment } from '../models/payment.model';
import { DonationsService } from '../donations.service';
import { DatetimefxService } from '@modules/utility-components/datetimefx.service';

@Component({
  selector: 'app-payment-dialog',
  templateUrl: './payment-dialog.component.html',
  styleUrls: ['./payment-dialog.component.scss']
})
export class PaymentDialogComponent implements OnInit {
  paymentForm: FormGroup;

  constructor(@Inject(MAT_DIALOG_DATA) public entry: any,
              private fb: FormBuilder,
              public donationSvc: DonationsService,
              private dtf: DatetimefxService,
              public dialogRef: MatDialogRef<PaymentDialogComponent>
              ) { }

  ngOnInit(): void {
    this.paymentForm = this.fb.group({
      payment_id: [],
      payee: ['Center for Renewing America'],
      person_link: [],
      payment_date: [],
      payment_amount: [],
      date_created: [],
      user_created: []
    });
  }
  
  savePayment = (paymentObj) => {
    if (paymentObj.amount_pledged === "") paymentObj.amount_pledged = null;
    if (paymentObj.amount_paid === "") paymentObj.amount_paid = null;
    if (paymentObj.paymentId) {
      this.donationSvc.updatePayment(paymentObj).subscribe((response: Payment) => this.dialogRef.close(response));
    } else {
      this.donationSvc.createPayment(paymentObj).subscribe((response: Payment) => this.dialogRef.close(response));
    }
  }

}