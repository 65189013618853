<div fxFlexFill fxLayout="column" class="overflow-auto">
  <div fxLayout="row" fxLayoutAlign="start center" class="navbar" *ngIf="showNavBar">
    <button *ngIf="authSvc.isLoggedIn()" mat-icon-button color="accent" (click)="drawer.toggle()"><mat-icon>menu</mat-icon></button>
    <div fxFlex></div>
    <app-search-bar *ngIf="authSvc.isLoggedIn()"></app-search-bar>
    <button mat-icon-button class="account-btn-icon">
      <mat-icon (click)="userProfileDialog()">account_circle</mat-icon>
    </button>
  </div>
  <mat-drawer-container fxFlex [hasBackdrop]="true" class="overflow-auto width-100">
    <mat-drawer #drawer class="slg-sidebar-wrapper h-100" mode="over"
                style="width: 200px;" (click)="drawer.toggle()">
      <div class="list-group list-group-flush width-100">
        <a *ngFor="let menuItem of menu" [routerLink]="menuItem.path" class="list-group-item list-group-item-action">{{menuItem.label}}</a>
      </div>
    </mat-drawer>
    <mat-drawer-content fxFlexFill class="overflow-auto" style="padding: 10px 0;">
      <router-outlet (activate)="onActivate($event)"></router-outlet>
    </mat-drawer-content>
  </mat-drawer-container>
</div>