import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ActivistsComponent } from '@modules/person-profiles/activists/activists.component';
import { CorrespondenceMasterComponent } from '@modules/correspondence-manager/correspondence-master/correspondence-master.component';
import { DonorsComponent } from '@modules/donor-management/donors/donors.component';
import { DonorFrameComponent } from '@modules/donor-management/donor-frame/donor-frame.component';
import { EmailEditorComponent } from '@modules/email-editor/email-editor/email-editor.component';
import { ImportFilesComponent } from '@modules/file-importer/import-files/import-files.component';
import { LoginComponent } from '@modules/auth/login/login.component';
import { PersonComponent } from '@modules/person-profiles/person/person.component';
import { PersonFormComponent } from '@modules/person-profiles/person-form/person-form.component';
import { UsersComponent } from '@modules/auth/users/users.component';
import { UserEmailPreferencesComponent } from '@modules/email-editor/user-email-preferences/user-email-preferences.component';
import { UserProfileComponent } from '@modules/auth/user-profile/user-profile.component';

import { DonationsComponent } from '@modules/donor-management/donations/donations.component';
import { ListManagerComponent } from '@modules/list-management/list-manager/list-manager.component';

import { PersonResolverService } from '@resolvers/person-resolver.service';
import { EmailUnsubscribeResolver } from '@modules/email-editor/email-unsubscribe.resolver';
import { ActivistsService } from '@resolvers/activists/activists.service';
import { AuthGuardService } from '@guards/auth-guard.service';
import { IsAdministratorGuard } from '@guards/is-administrator.guard';

const routes: Routes = [
  { path: '', redirectTo: '/activists/new', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: 'signup', component: PersonFormComponent },
  { path: 'activists/:id', component: ActivistsComponent, resolve: {person: PersonResolverService}, canActivate: [AuthGuardService] },
  { path: 'donors', component: DonorFrameComponent, canActivate: [AuthGuardService] },
  { path: 'correspondence', component: CorrespondenceMasterComponent, canActivate: [AuthGuardService] },
  { path: 'donors', component: DonorsComponent, canActivate: [AuthGuardService] },
  { path: 'list-manager', component: ListManagerComponent, canActivate: [AuthGuardService]},
  { path: 'users', component: UsersComponent, canActivate: [AuthGuardService, IsAdministratorGuard] },
  { path: 'user-profile', component: UserProfileComponent, canActivate: [AuthGuardService] },
  { path: 'import-files', component: ImportFilesComponent, canActivate: [AuthGuardService, IsAdministratorGuard] },
  { path: 'activists', component: ActivistsComponent, canActivate: [AuthGuardService] },
  { path: 'email-editor', component: EmailEditorComponent, canActivate: [AuthGuardService]  },
  { path: 'unsubscribe/:id', component: UserEmailPreferencesComponent, resolve: {unsub: UserEmailPreferencesComponent} }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }