import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

import { VersatileTableComponent } from './versatile-table/versatile-table.component';
import { PaginatorComponent } from './paginator/paginator.component';

@NgModule({
  declarations: [
    VersatileTableComponent,
    PaginatorComponent
  ],
  imports: [
    CommonModule,
    MatMenuModule,
    MatButtonModule,
    MatIconModule,
  ],
  exports: [
    PaginatorComponent
  ]
})
export class UtilityComponentsModule { }
