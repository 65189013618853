<div class="container frame d-flex flex-column">
  <div class="row">
    <div class="col-12">
      <mat-form-field appearance="legacy" class="w-100">
        <mat-label>Search Templates</mat-label>
        <input matInput type="text">
        <button mat-icon-button matSuffix><mat-icon>close</mat-icon></button>
      </mat-form-field>
    </div>
  </div>
  <div class="row header-tabs">
    <div class="col-4"
         [ngClass]="{'selected': viewMode === 'my'}"
         (click)="viewMode = 'my';">My Templates</div>
    <div class="col-4"
         [ngClass]="{'selected': viewMode === 'team'}"
         (click)="viewMode = 'team';">Team Templates</div>
    <div class="col-4"
         [ngClass]="{'selected': viewMode === 'search'}"
         (click)="viewMode = 'search';">Search Results</div>
  </div>
  <div class="row flex-grow-1 flex-shrink-1">
    <div class="col-12">
      <ul>
        <li *ngFor="let template of emailTemplates[viewMode] | async">
          <span [mat-dialog-close]="template">{{template.name}}</span>
        </li>
      </ul>
    </div>
  </div>
  <div class="row">
    <div class="col-12 justify-content-center">
      <button mat-flat-button
              mat-dialog-close
              color="warn">
        <span>Cancel</span>
      </button>
    </div>
  </div>
</div>