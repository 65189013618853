export class Donation {
  donation_id: number;
  recipient: string;
  amount_pledged: number;
  amount_paid: number;
  date: string;
  notes: string;
  contact_link: number;
  date_created: Date = new Date();
  user_created: string;
}