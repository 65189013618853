import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-temporary-table-dialog',
  templateUrl: './temporary-table-dialog.component.html',
  styleUrls: ['./temporary-table-dialog.component.scss']
})
export class TemporaryTableDialogComponent implements OnInit {
  rowKeys: any[] = [];
  formGroupObject: any = {};
  data: any;
  cellDataForm: FormGroup;
  removeFields: string[] = ["_unsaved", "_rowValidation", "_selected", "_saving", "sling_duplicate"]
  
  constructor(@Inject(MAT_DIALOG_DATA) public entry: any,
              private fb: FormBuilder,
              public dialogRef: MatDialogRef<TemporaryTableDialogComponent>) { }

  ngOnInit(): void {
    this.data = this.entry.recordObj;
    this.rowKeys = Object.keys(this.data).filter(key => this.removeFields.indexOf(key) === -1);
    this.rowKeys.forEach(key => {
      this.formGroupObject[key] = [this.data[key]]
    });
    this.cellDataForm = this.fb.group(this.formGroupObject);
  }
  saveChanges = (data) => {
    if (!this.cellDataForm.pristine) data._unsaved = true;
    this.dialogRef.close(data)
  }
}